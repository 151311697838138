import { GET_EMAIL_LOGS } from "store/actions/emailLogs";

const initialState = {
  data: {},
  loader:false,
  totalItemsCount:0,
  itemsCountPerPage:0,
};

// set email logs
const getEmailLogs = (state, payload) => {
  const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        data: data.body.data,
        itemsCountPerPage: data.body.per_page,
        totalItemsCount: data.body.total_count
    };
  return stateObj;
}

const EmailLog = (state = initialState, { type, payload = null }) => {
    switch (type) {
        
        case GET_EMAIL_LOGS:
          return getEmailLogs(state, payload);

        default:
        return state;
    };
}
export default EmailLog;