const inputFields = [
  {
    name: 'package_name',
    type: 'text',
    label: 'Package Name*',
    placeholder: 'Package Name',
  },
  {
    name: 'package_type',
    type: 'select',
    label: 'Package Type*',
    placeholder: 'Package Type',
    options: [
      {
        label: 'Monthly',
        value: 'month'
      },
      {
        label: 'Quarterly',
        value: 'quarter'
      },
      {
        label: 'Yearly',
        value: 'year'
      }
    ]
  },
  {
    name: 'package_price',
    type: 'number',
    label: 'Package Price*',
    placeholder: 'Package Price',
  },
  {
    name: 'stripe_product_detail',
    type: 'text',
    label: 'Stripe Product Detail*',
    placeholder: 'Stripe Product Detail',
  },
  {
    name: 'free_trial',
    type: 'select',
    label: 'Free Trial*',
    placeholder: 'Free Trial',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    name: 'free_trial_duration',
    type: 'number',
    label: 'Free Trial Duration (in days)*',
    placeholder: 'Free Trial Duration (in days)',
  },
  {
    name: 'is_nta_package',
    type: 'select',
    label: 'NTA Member Package*',
    placeholder: 'NTA Member Package',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    name: 'package_desc',
    type: 'textarea',
    label: 'Package Description*',
    placeholder: 'Package Description',
  },
  {
    name: 'package_included',
    type: 'ckeditor',
    label: 'Package Included*',
    placeholder: 'Package Included',
  },
  {
    name: "is_multiple_login_allowed",
    type: "select",
    label: "Login Permission*",
    placeholder: 'Select',
    options: [
      {
        label: "Single",
        value: "no",
      },
      {
        label: "Multi",
        value: "yes"
      }
    ],
  },
  {
    name: 'status',
    type: 'radio',
    label: 'Status*',
    options: [
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Inactive',
        value: 'inactive'
      },
    ]
  },
];

export default inputFields;