import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, CATEGORY_LIST,CATEGORY_ADD, CATEGORY_UPDATE, CATEGORY_VIEW, CATEGORY_DELETE  } from './apiPath'
import { getCategory, addCategory, viewCategory, updateCategory, deleteCategory} from 'store/actions/category';

//get
export function getCategoryList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${CATEGORY_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getCategory(data, callback));
      return data;
    });
}

//add
export function addCategoryData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CATEGORY_ADD}`, value)
    .then((data) => {
      dispatch(addCategory(data, callback));
      return data;
    });
}

//view
export function viewCategoryData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${CATEGORY_VIEW}${value}`)
    .then((data) => {
      dispatch(viewCategory(data, callback));
      return data;
    });
}


//edit
export function updateCategoryData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${CATEGORY_UPDATE}`, value)
    .then((data) => {
      dispatch(updateCategory(data, callback));
      return data;
    });
}


//delete
export function deleteCategoryData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${CATEGORY_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteCategory(data));
      return data;
    });
}
