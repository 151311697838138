//action types
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';

//get profile
export function getProfile(payload) {
  return {
    type: PROFILE,
    payload,
  };
}
//update profile
export function updateProfile(payload) {
  return {
    type: UPDATE_PROFILE,
    payload
  };
}
  
export function getDashboardInfo(payload) {
  return {
    type: GET_DASHBOARD_INFO,
    payload
  };
}