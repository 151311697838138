import * as Yup from 'yup';

const Validation = Yup.object({
    title: Yup.string().required("Required").max(100, "Title should be within 100 characters"),
    slug: Yup.string().required("Required").max(50, "Slug should be within 50 characters"),
    content: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
});

export default Validation;
