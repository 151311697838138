const inputFields = [
    {
      name: 'email',
      type: 'email',
      label: 'Email*',
      placeholder: '',
    },
    {
      name: 'password',
      type: 'password',
      label: 'Password*',
      placeholder: '',
    },
];

export default inputFields;