import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import * as Path from "routes/paths";
import * as actions from "store/actions";
// import { useModules } from 'co/modules';
import { EMAIL_TEMPLATE } from "common/viewContent";
import { Button, Col, Row } from "react-bootstrap";
import {
  templeteApi
} from "store/services/templateServicce";
const EmailTemp = ({ dispatch, templete, data, }) => {

  async function getApiData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(templeteApi());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getApiData();

  }, []);

  return (
    <>
      <Helmet title={EMAIL_TEMPLATE.CURRENT_MODULE} />
      {templete &&
        <section className="section">
          <div className="section-header">
            <h1>{EMAIL_TEMPLATE.CURRENT_MODULE}</h1>
            <div className="section-header-breadcrumb">
              <div className="breadcrumb-item">Email Template</div>
            </div>
          </div>
          <Row className="emailTemp">
            <Col lg="6" className="my-3">
              <div className="bg-white px-4 py-3 card-box rounded h-100">
                <div className="top py-2 border-bottom d-flex align-items-center justify-content-between gap-10">
                  <h4 className="m-0 py-2 fw-bold text-dark">Practitioner</h4>
                </div>
                {
                  templete.practitioner ? templete.practitioner.map((item) =>
                    <>
                      < div className="contentBody py-2">
                        <Row className="py-2 my-2 border-bottom">
                          <Col lg="4" md="5">
                            <Link className="" to={`${Path.email_template__edit}/${item?.id}`}>
                              <h6 className="form-label text-dark fw-sbold m-0">
                                {item?.title}
                                {/* Subscription Purchase : */}
                              </h6>
                            </Link>
                          </Col>
                          <Col lg="8" md="7">
                            <p className="m-0">
                              {" "}
                              {item?.description}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>

                  )
                    : ""
                }

              </div>
            </Col>
            <Col lg="6" className="my-3">
              <div className="bg-white px-4 py-3 card-box rounded h-100">
                <div className="top py-2 border-bottom d-flex align-items-center justify-content-between gap-10">
                  <h4 className="m-0 py-2 fw-bold text-dark">Clients</h4>
                </div>
                {
                  templete.client ? templete.client.map((item) =>
                    <>
                      < div className="contentBody py-2">
                        <Row className="py-2 my-2 border-bottom">
                          <Col lg="4" md="5">
                            <Link className=""to={`${Path.email_template__edit}/${item.id}`}>
                              <h6 className="form-label text-dark fw-sbold m-0">
                                {item.title}
                                {/* Subscription Purchase : */}
                              </h6>
                            </Link>
                          </Col>
                          <Col lg="8" md="7">
                            <p className="m-0">
                              {" "}
                              {item.description}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>

                  )
                    : ""
                }

              </div>
            </Col>
            <Col lg="6" className="my-3">
              <div className="bg-white px-4 py-3 card-box rounded h-100">
                <div className="top py-2 border-bottom d-flex align-items-center justify-content-between gap-10">
                  <h4 className="m-0 py-2 fw-bold text-dark">Admin</h4>
                </div>
                {
                  templete.admin ? templete.admin.map((item) =>
                    <>
                      < div className="contentBody py-2">
                        <Row className="py-2 my-2 border-bottom">
                          <Col lg="4" md="5">
                            <Link className=""to={`${Path.email_template__edit}/${item.id}`}>
                              <h6 className="form-label text-dark fw-sbold m-0">
                                {item.title}
                                {/* Subscription Purchase : */}
                              </h6>
                            </Link>
                          </Col>
                          <Col lg="8" md="7">
                            <p className="m-0">
                              {" "}
                              {item.description}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>

                  )
                    : ""
                }
              </div>
            </Col>
          </Row>
        </section>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log(state.Templete, '===state.Templete,')
  return {
    templete: state.Templete.templete,
    isAuthenticated: state.Templete.isAuthenticated,
    loader: state.Templete.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemp);
