import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import {
  getSubCategoryList,
  deleteSubCategoryData,
} from "store/services/subcategoryService";
//imported
import { SUBCATEGORIES, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import { DUMMY_IMG } from "utils/constants/constant";
import ReactPagination from "components/shared/ReactPagination";

const List = ({
  dispatch,
  subcategoryList,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  const [activePage, setActivePage] = useState(1);
  //get subcategory detail
  async function getData() {
    try {
      let query = {
        page: activePage,
        limit: PAGE_LIMIT,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getSubCategoryList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [activePage]);

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteSubCategoryData(id)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={SUBCATEGORIES.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{SUBCATEGORIES.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.subcategory_add} className="btn btn-primary">
              Add New
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">
              {SUBCATEGORIES.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{SUBCATEGORIES.CURRENT_MODULE}</h2>
          <p className="section-lead">
            Within this section, you can access a list of sub-categories that are linked to the main categories in the Resources section of the Practitioner Panel. This provides you with the capability to efficiently manage these sub-categories, enabling actions like addition, viewing, editing, and deletion based on your requirements.
          </p>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{SUBCATEGORIES.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>Name</th>
                        {/* <th>Image</th> */}
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {subcategoryList.length == 0 ? "" : subcategoryList.length > 0 &&
                        subcategoryList.map((item, i) => {
                          return (
                            <tr key={i}>
                               <td>{i + 1}</td>
                              <td>{FORMAT_TEXT(item?.category_name)}</td>
                              <td>{FORMAT_TEXT(item?.name)}</td>
                              {/* <td>
                                <img
                                  src={item.image ? item.image : DUMMY_IMG}
                                  className="img_list_round"
                                />
                              </td> */}
                              <td>{DATE_FORMAT(item?.created_at)}</td>
                              <td>
                                <div
                                  className={`badge text-white badge-${item?.status == "active"
                                    ? "primary"
                                    : "danger"
                                    }`}
                                >
                                  {item.status}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-10 tableBtn">
                                  <Link
                                    to={`${Path.subcategoryview}/${item?.id}`}
                                    title="View"
                                  >
                                    <i className="fa fa-lg fa-eye"></i>
                                  </Link>
                                  <Link
                                    to={`${Path.subcategoryedit}/${item?.id}`}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link>

                                  <span
                                    className="text-danger"
                                    onClick={() => deleteItem_(item?.id)}
                                  >
                                    <i
                                      className="fa fa-lg fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  {subcategoryList.length == 0 ? "" : subcategoryList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subcategoryList: state.Subcategory.subcategories,
    itemsCountPerPage: state.Subcategory.itemsCountPerPage,
    totalItemsCount: state.Subcategory.totalItemsCount,
    loader: state.Subcategory.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
