import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as actions from "store/actions";
import { updateClientData, viewClientData } from "store/services/clientService";

const validationSchema = Yup.object().shape({
	first_name: Yup.string()
		.required('First Name is required'),
	last_name: Yup.string()
		.required('Last Name is required'),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
	birth_date: Yup.date()
		.max(new Date(), "Date of Birth can not be in the future")
		.required('Date of Birth is required'),
});


export default function EditUser({ showEditUser, setShowEditUser, editkey, clientDetail }) {
	const toggleEditUserPopup = () => setShowEditUser(!showEditUser);
	// const [sending, setSending] = useState(false);

	const dispatch = useDispatch();


	const handleUpdate = async (values, { setSubmitting }) => {
		try {
			let data = {
				clientId: editkey,
				values: {
					first_name: values.first_name,
					last_name: values.last_name,
					email: values.email,
					birth_date: values.birth_date,
				}
			}

			setSubmitting(true)
			dispatch(actions.persist_store({ loader: true }));
			await updateClientData(data);
			await dispatch(viewClientData(editkey));
			setSubmitting(false)
			dispatch(actions.persist_store({ loader: false }));
			toggleEditUserPopup();
		} catch (error) {
			console.log(error)
			dispatch(actions.persist_store({ loader: false }));
		}
	}
	return (
		<>
			<Modal
				show={showEditUser}
				onHide={toggleEditUserPopup}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							first_name: clientDetail ? clientDetail.first_name : "",
							last_name: clientDetail ? clientDetail.last_name : "",
							email: clientDetail ? clientDetail.email : "",
							birth_date: clientDetail ? clientDetail.birth_date : "",
						}}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={handleUpdate}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form onSubmit={handleSubmit} autoComplete="off">
								<Row>
									<Col lg="12" className="my-2">
										<label htmlFor="" className="form-label m-0 fw-sbold">
											First Name*
										</label>
										<input
											type="text"
											className={`form-control ${errors.first_name && "is-invalid"}`}
											onChange={handleChange}
											onBlur={handleBlur}
											name="first_name"
											value={values.first_name}
										/>
										{errors.first_name && touched.first_name && (
											<p className="invalid-feedback">{errors.first_name}</p>
										)}
									</Col>
									<Col lg="12" className="my-2">
										<label htmlFor="" className="form-label m-0 fw-sbold">
											Last Name
										</label>
										<input
											type="text"
											className={`form-control ${errors.last_name && "is-invalid"}`}
											onChange={handleChange}
											onBlur={handleBlur}
											name="last_name"
											value={values.last_name}
										/>
										{errors.last_name && touched.last_name && (
											<p className="invalid-feedback">{errors.last_name}</p>
										)}
									</Col>
									<Col lg="12" className="my-2">
										<label htmlFor="" className="form-label m-0 fw-sbold">
											Email*
										</label>
										<input
											type="text"
											className={`form-control ${errors.email && "is-invalid"}`}
											onChange={handleChange}
											onBlur={handleBlur}
											name="email"
											value={values.email}
										/>
										{errors.email && touched.email && (
											<p className="invalid-feedback">{errors.email}</p>
										)}
									</Col>
									{/* {console.log("errors", errors, touched)} */}
									<Col lg="12" className="my-2">
										<label htmlFor="" className="form-label m-0 fw-sbold">
											Date Of Birth*
										</label>
										<input
											type="date"
											className={`form-control ${errors.birth_date && "is-invalid"}`}
											onChange={handleChange}
											onBlur={handleBlur}
											name="birth_date"
											value={values.birth_date}
										/>
										{errors.birth_date && touched.birth_date && (
											<p className="invalid-feedback">{errors.birth_date}</p>
										)}
									</Col>
									{/* <div className="form-group button-submit">
										<LoadingButton
											type="submit"
											className={`btn btn-primary btn-lg`}
											// loading={loading}
											disabled={isSubmitting}
										>
											{isSubmitting ? 'Wait...' : 'Save Changes'}
										</LoadingButton>
									</div> */}
									<Col lg="12">
										<Modal.Footer>
											<Button variant="secondary"
												onClick={toggleEditUserPopup}
											>
												Close
											</Button>
											<Button
												variant="primary"
												type="submit"
												disabled={isSubmitting ? true : false}
											>
												{isSubmitting ? 'Wait...' : 'Save'}
											</Button>
										</Modal.Footer>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				</Modal>
		</>
	);
}