import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

//imported
import { CONTENT_PAGES } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { addContentPageData, viewContentPageData, updateContentPageData } from 'store/services/contentPageService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const AddEdit = ({ submitting, dispatch, match, loader }) => {
    const history = useHistory();
    let editkey = match.params.id;
    const [url, setUrl] = useState()
    const [pageDetail, setPageDetail] = useState({})

    const initialValues = {
        slug: pageDetail ? pageDetail.slug : "",
        title: pageDetail ? pageDetail.title : "",
        description: pageDetail ? pageDetail.description : "",
        status: pageDetail ? pageDetail.status : "active",
    };

    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(viewContentPageData(editkey))
            setPageDetail(res?.body)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        if (editkey) {
            getData();
        }
    }, []);

    // add edit
    const updateProfile_ = async (values) => {
        try {
            const callback = (response) => {
                const { data } = response;
                if (data.success == true) {
                    history.push(Path.content_pages)
                }
            }

            if (editkey) {
                values.id = editkey;
            }
            values.status = values.status ? values.status : 'active'
            values = JSON.stringify(values)
            dispatch(actions.persist_store({ loader: true }));
            if (editkey) {
                await dispatch(updateContentPageData(values, callback));
            } else {
                await dispatch(addContentPageData(values, callback));
            }
            dispatch(actions.persist_store({ loader: false }));

        } catch (err) {
            console.log(err);
            dispatch(actions.persist_store({ loader: false }));
        }
    }

    return (
        <>
            <Helmet title={CONTENT_PAGES.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{CONTENT_PAGES.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                       
                        <div className="breadcrumb-item"><Link to={Path.content_pages}>Content</Link></div>
                        <div className="breadcrumb-item">Add Content</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">Add New Content</h2>
                    <p className="section-lead">
                        On this page you can create a new content and fill in all fields.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">

                                    {editkey ? "" :
                                        <>
                                            <label>URL</label>
                                            <input className="form-control" disabled value={url} />
                                        </>
                                    }

                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={updateProfile_}
                                        loading={loader}
                                        inputFields={InputFields}
                                        submitting={submitting}
                                        buttonText="Save Changes"
                                        is_block={false}
                                        col={12}
                                        selectDisable={false}
                                        onFieldChange={(type, value) => {
                                            if (type == "slug") {
                                                setUrl(value.url)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        pageDetail: state.ContentPage.page,
        loader: state.ContentPage.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);