import { GET_DASHBOARD_INFO, PROFILE, UPDATE_PROFILE } from 'store/actions/profile';

const initialState = {
    isAuthenticated: false,
    user: null,
    dashboardInfo: {},
    loader:false,
};

//get profile
const getProfile = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update profile
const updateProfile = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

const getDashboardInfo = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    dashboardInfo: body,
  };
  return stateObj;
}

const Profile = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROFILE:
          return getProfile(state, payload);
        case UPDATE_PROFILE:
          return updateProfile(state, payload);
        case GET_DASHBOARD_INFO:
          return getDashboardInfo(state, payload);
        default:
        return state;
    };
}
export default Profile;