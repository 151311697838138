import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import LoadingButton from 'components/shared/LoadingButton';
import {
    UploadMediaFile,
    IS_BASE64,
    validateInputes,
} from 'helpers/common';
import { SUBCATEGORIES } from 'common/viewContent';
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import {
    addSubCategoryData,
    viewSubCategoryData,
    updateSubCategoryData,
} from 'store/services/subcategoryService';
import { getCategoryList } from 'store/services/categoryService';
import Validation from './Validation';

function AddEdit({
    submitting,
    dispatch,
    match,
    subcategoryDetail,
    categoryList,
    loader,
}) {
    const history = useHistory();
    const editKey = match.params.id;

    const [sending, setSending] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(true)
    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        name: '',
        status: 'active',
        cat_id: '',
    });

    useEffect(() => {
        if (subcategoryDetail) {
            setFields({
                name: subcategoryDetail.name || '',
                status: subcategoryDetail.status || 'active',
                cat_id: subcategoryDetail.p_id || '',
            });
        }
    }, [subcategoryDetail]);

    useEffect(() => {
        if (categoryList.length === 0) {
            fetchCategory();
        }
        if (editKey) {
            getData();
        } else {
            setFields({
                name: '',
                status: 'active',
                cat_id: '',
            });
        }
    }, []);

    async function fetchCategory() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(getCategoryList());
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(viewSubCategoryData(editKey));
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    const handleSubmit_ = async (event) => {
        try {
            event.preventDefault();
            const validation = await validateInputes(Validation, fields);

            if (validation.isValid) {
                setLoading(true)
                const callback = (response) => {
                    const { data } = response;
                    if (data.success === true) {
                        history.push(Path.subcategory);
                    }
                };

                let data = {
                    category_id: fields.cat_id,
                    name: fields.name,
                    status: fields.status,
                };

                if (editKey) {
                    data.id = editKey;
                }
                data = JSON.stringify(data);
                setSending(true)
                dispatch(actions.persist_store({ loader: true }));
                if (editKey) {
                    await dispatch(updateSubCategoryData(data, callback));
                } else {
                    await dispatch(addSubCategoryData(data, callback));
                }
                setSending(false)
                dispatch(actions.persist_store({ loader: false }));
                setLoading(false)
                setIsSubmit(true)
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            setLoading(false)
            setIsSubmit(false)
            setSending(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFields({ ...fields, [name]: value });
    //     setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    //     setIsSubmit(false)
    // };

    const handleChange = useCallback(
        name => evt => {
        setFields(prevState => ({
            ...prevState,
            [name]: evt.target.value,
          }))
        setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        setIsSubmit(false)
        },
        []
      )
    return (
        <>
            <Helmet title={SUBCATEGORIES.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{SUBCATEGORIES.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item">
                            <Link to={Path.subcategory}>{SUBCATEGORIES.CURRENT_MODULE}</Link>
                        </div>
                        <div className="breadcrumb-item">
                            {editKey ? 'Edit' : 'Add'} Subcategory
                        </div>
                    </div>
                </div>
                <div className="section-body">
                    <h2 className="section-title">
                        {editKey ? 'Edit' : 'Add'} New Subcategory
                    </h2>
                    <p className="section-lead">
                        On this page you can create a new subcategory and fill in all fields.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>Catgeory*</label>
                                                <select
                                                    className="form-control"
                                                    value={fields.cat_id}
                                                    name="cat_id"
                                                    onChange={handleChange("cat_id")}
                                                >
                                                    <option>Select option</option>
                                                    {categoryList &&
                                                        categoryList?.map((e) => (
                                                            <option key={e.id} value={e.id}>
                                                                {e.name}
                                                            </option>
                                                        ))}
                                                </select>
                                                {validationErrors.cat_id && (
                                                    <p className="invalid-feedback">
                                                        {validationErrors.cat_id}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Name*</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    onChange={handleChange("name")}
                                                    placeholder="Name"
                                                    value={fields?.name}
                                                    required
                                                />
                                                {validationErrors.name && (
                                                    <p className="invalid-feedback">
                                                        {validationErrors.name}
                                                    </p>
                                                )}
                                            </div>
                                            {/* <div className="form-group col-md-12">
                                                <label>Status</label>
                                                <select
                                                    className="form-control"
                                                    value={fields?.status}
                                                    name="status"
                                                    onChange={handleChange}
                                                >
                                                    <option>Selete Statue</option>
                                                    <option selected value="active">
                                                        Active
                                                    </option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                                {validationErrors.status && (
                                                    <p className="invalid-feedback">
                                                        {validationErrors.status}
                                                    </p>
                                                )}
                                            </div> */}
                                            <div className="form-group col-md-6">
                                                <label>Status</label>
                                                <div className="row">
                                                    <div className="form-group col-md-6">
                                                    <input
                                                        type="radio"
                                                        className="form-radio"
                                                        name="status"
                                                        onChange={handleChange('status')}
                                                        value="active"
                                                        defaultChecked={fields.status === 'active' ? true : false}
                                                    /> 
                                                    <label>&nbsp;Active</label>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                    <input
                                                        type="radio"
                                                        className="form-radio"
                                                        name="status"
                                                        onChange={handleChange('status')}
                                                        value="inactive"
                                                        defaultChecked={fields.status === 'inactive' ? true : false}
                                                    /> 
                                                    <label>&nbsp;Inactive</label>
                                                    </div>
                                                </div>
                                                {validationErrors.status && (
                                                    <p className="invalid-feedback">
                                                        {validationErrors.status}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="form-group button-submit">
                                                {/* <LoadingButton
                                                    type="submit"
                                                    className="btn btn-primary btn-lg"
                                                    loading={sending}
                                                    isSubmit={isSubmit ? true : true}
                                                    onClick={handleSubmit_}
                                                >
                                                    {sending ? 'Wait...' : 'Submit'}
                                                </LoadingButton> */}
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-lg"
                                                    disabled={sending ?  true : false}
                                                    onClick={handleSubmit_}
                                                >
                                                    {loading ? 'Wait...' : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        subcategoryDetail: state.Subcategory.subcategory,
        categoryList: state.Category.categories,
        loader: state.Subcategory.loader,
    };
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);
