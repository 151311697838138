import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { DUMMY_IMG } from "utils/constants/constant";
import { toast } from "react-toastify";

function DropZone(props) {
  const [imageData, setImageData] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.file) {
      setImageData(props.file.url);
      setFileExtension(props.file.extension);
    }
  }, [props.file]);

  const handleFiles = (files) => {
    const file = files[0];

    if (file.size >= 10e6) {
      toast.error("Max file size: 5MB");
      return;
    }

    const allowedTypes = props.allowType;
    // const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
    if (!allowedTypes.includes(file.type)) {
      setErrorMessage(`Invalid file type. Allowed types: ${props.allowTypeError}`);
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setImageData(base64Data);

      if (props.onChange) {
        const extension = file.name.split(".").pop().toLowerCase(); // Get file extension
        const file_type = file.type.split("/");
        setFileExtension(extension);
        setFileType(file_type[0]);
        props.onChange(base64Data, file, extension, file_type[0], file.name);
      }
    };

    reader.readAsDataURL(file);
    setErrorMessage("");
  };
  const handleClear = () => {
    setImageData(null);
    setFileExtension("");
    setFileType("");
    setErrorMessage("");
    if (props.onChange) {
      props.onChange(null, null, null);
    }
  };
  return (
    <>
    <Dropzone onDrop={handleFiles}>
      {({ getRootProps, getInputProps }) => (
        <div className="dropzone-single-image avatar-xl" {...getRootProps()}>
          <input {...getInputProps()} id="formrow-profile-image-Input" multiple={false} />

          {props.children ? (
            props.children
            ) : (imageData && (imageData.includes("data:image") || imageData.includes("data:application/pdf"))) || (props.extension_ === 'pdf') ? (
              <div>
                {(imageData && (imageData.includes("data:application/pdf") || props.extension_ === 'pdf')) ? (
                  <div>
                    <object
                      data={imageData}
                      type="application/pdf"
                      width={"70%"}
                      height={150}
                    >
                      PDF Viewer
                    </object>
                    <a href={imageData} download="document.pdf">Download PDF</a>
                  </div>
                ) : (
                  <>
                    {imageData ? (
                      <img className="rounded avatar-xl profile" alt="" src={imageData} />
                    ) : (
                      <img className="rounded avatar-xl profile" alt="" src={DUMMY_IMG} />
                    )}
                    <div className="edit">
                      <i className="bx bx-pencil"></i>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                {imageData ? (
                  <img className="rounded avatar-xl profile" alt="" src={imageData} />
                ) : (
                  <img className="rounded avatar-xl profile" alt="" src={DUMMY_IMG} />
                )}
                <div className="edit">
                  <i className="bx bx-pencil"></i>
                </div>
              </>
          )}


          {fileExtension && (
            <div className="file-extension">
              File Extension: {fileExtension}
            </div>
          )}
        </div>
      )}
    </Dropzone>
    <br />
    {!props.imageRemoveButton || imageData && (
      <button className="btn btn-danger btn-sm" onClick={handleClear}>Remove</button>
    )}
    <br />
    {errorMessage && (
      <div className="error-message">
        {errorMessage}
      </div>
    )}
    </>
  );
}

DropZone.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string,
    extension: PropTypes.string,
    file_type: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default memo(DropZone);
