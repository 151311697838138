import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';

//imported
import { UploadMediaFile }  from 'helpers/UploadFile';
import { IS_BASE64 }  from 'helpers/common';
import { CATEGORIES } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { getClientFormListing, getClientFormCategoryList, addClientFormCategoryData, updateClientFormCategoryData } from 'store/services/clientCategoryFormService';
import FormikForm from './Form';
import Validation from './Validation'
// import InputFields from './inputFields'

const AddEdit = ({submitting, dispatch, clientFormListing, clientCategory, categoryDetail, editKey, setEditKey, selectedCheckboxes, setSelectedCheckboxes, setEditViewButton, editViewButton, isView, setIsView}) => {
    const history = useHistory();

    const initialValues = {
        category_name: categoryDetail ? categoryDetail?.category_name :  "",
    };
    //get category detail
    async function getData() {
        try{
           dispatch(actions.persist_store({ loader:true}));
           await dispatch(getClientFormCategoryList())
           setEditViewButton(false)
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
   }
    //get detail
    async function getDataDefaultForm() {
        try{
            dispatch(actions.persist_store({ loader:true}));
            await dispatch(getClientFormListing())
            dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
            dispatch(actions.persist_store({ loader:false}));
            console.log(err);
        }
    }
    useEffect(() => {
        if(clientCategory){
            getData();
        }
        if(editKey){
            let arry = categoryDetail?.form_id;
            setSelectedCheckboxes(arry)
        }else{
            setEditKey(null)
        }
        getData();
        getDataDefaultForm()
    }, [clientCategory]);

    useEffect(() => {
        if(editViewButton){
            getData();
        }
    }, [editViewButton])

    const handleCheckboxChange = (event, checkboxId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
          setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxId]);
        } else {
          setSelectedCheckboxes((prevSelected) =>
            prevSelected.filter((id) => id !== checkboxId)
          );
        }
    };
    // add edit
    const handleSubmit_ = async (values, { resetForm, setSubmitting }) => {
        try{
            let data = {
                category_name : values.category_name,
                form_id:selectedCheckboxes
            }
            if (editKey) {
                data.edit_value = editKey;
            }
            data = JSON.stringify(data)
            setSubmitting(true)
            dispatch(actions.persist_store({ loader: true }));
            if (editKey) {
                await dispatch(updateClientFormCategoryData(data));
              } else {
                await dispatch(addClientFormCategoryData(data));
            }
            resetForm();
            setSelectedCheckboxes([])
            getData()
            dispatch(actions.persist_store({ loader: false }));
            setSubmitting(false)
        }catch(err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    }
    
    return(
        <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <FormikForm
                        initialValues={initialValues}
                        updateData_={handleSubmit_}
                        submitting={submitting}
                        clientFormListing={clientFormListing}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedCheckboxes={selectedCheckboxes}
                        isView={isView}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state, props) => {
    return{
        categoryList : state.ClientCategoryForm?.categories?.data_set,
        clientFormListing : state.ClientCategoryForm.client_form,
        clientFormListing : state.ClientCategoryForm.client_form,
        loader:state.Category.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);