import { GET_CLIENT_CATEGORY_FORM_FORM, ADD_CLIENT_CATEGORY_FORM, VIEW_CLIENT_CATEGORY_FORM, UPDATE_CLIENT_CATEGORY_FORM, DELETE_CLIENT_CATEGORY_FORM, GET_CLIENT_FORM_LIST } from 'store/actions/clientCategoryForm';

const initialState = {
    isAuthenticated: false,
    categories: [],
    category: {},
    client_form :[],
    clientform :null,
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getClientFormCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        categories:data.body,
        clientform:null,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addClientFormCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      categories:data.body,
      clientform:null
    };
    return stateObj;
  };

  //update 
const viewClientFormCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      clientform:data.body,
    };
    return stateObj;
};

  //update 
const updateClientFormCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      categories:[],
      category:{},
      clientform:null
    };
    return stateObj;
};

//delete 
const deleteClientFormCategory = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subscriptions:[],
  };
  return stateObj;
};

//form listing 
const getClientFormList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
      client_form:data.body,
  };
return stateObj;
};
const ClientCategoryForm = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_CLIENT_CATEGORY_FORM_FORM:
          return getClientFormCategory(state, payload);
        case ADD_CLIENT_CATEGORY_FORM:
            return addClientFormCategory(state, payload);
        case VIEW_CLIENT_CATEGORY_FORM:
          return viewClientFormCategory(state, payload);
        case UPDATE_CLIENT_CATEGORY_FORM:
          return updateClientFormCategory(state, payload);
        case DELETE_CLIENT_CATEGORY_FORM:
          return deleteClientFormCategory(state, payload);
        case GET_CLIENT_FORM_LIST:
          return getClientFormList(state, payload);
        default:
        return state;
    };
}
export default ClientCategoryForm;