import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, SYSTEM_ACCESS_LIST,SYSTEM_ACCESS_ADD, SYSTEM_ACCESS_UPDATE, SYSTEM_ACCESS_VIEW, SYSTEM_ACCESS_DELETE  } from './apiPath'
import { getSystemAccess, addSystemAccess, viewSystemAccess, updateSystemAccess, deleteSystemAccess} from 'store/actions/systemAccess';

//get
export function getSystemAccessList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${SYSTEM_ACCESS_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getSystemAccess(data, callback));
      return data;
    });
}

//add
export function addSystemAccessData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${SYSTEM_ACCESS_ADD}`, value)
    .then((data) => {
      dispatch(addSystemAccess(data, callback));
      return data;
    });
}

//view
export function viewSystemAccessData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${SYSTEM_ACCESS_VIEW}${value}`)
    .then((data) => {
      dispatch(viewSystemAccess(data, callback));
      return data;
    });
}


//edit
export function updateSystemAccessData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${SYSTEM_ACCESS_UPDATE}`, value)
    .then((data) => {
      dispatch(updateSystemAccess(data, callback));
      return data;
    });
}


//delete
export function deleteSystemAccessData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${SYSTEM_ACCESS_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteSystemAccess(data));
      return data;
    });
}

