import React, { useState } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//imported
import * as Path from 'routes/paths';
import { FORGOT_PASSWORD } from "common/viewContent";
import { forgotPassword } from 'store/services/authService';
import * as actions from 'store/actions';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'
import moment from "moment";

const ForgotPassword = ({submitting, dispatch}) => {
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
      //values
    const initialValues = {
        email: "",
    };

    //submit data
    const handleSubmit_ = async (values, {resetForm, setSubmitting}) => {
        try{
            values  = JSON.stringify(values)
            dispatch(actions.persist_store({ loader:true }));
            setSending(true)
            setSubmitting(true)
            await dispatch(forgotPassword(values));
            dispatch(actions.persist_store({ loader:false }));
            setSending(false)
            setSubmitting(false)
            resetForm();
        }catch(err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader:false }));
            setSending(false)
        }
    }

    return(
        <>
            <Helmet title={FORGOT_PASSWORD.CURRENT_MODULE} />
            <div className="container mt-5" style={{overflow:'hidden'}}>
                <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    
                    <div className="login-brand">
                    </div>
                    <div className="card card-primary">
                        <div className="card-header"><h4>{FORGOT_PASSWORD.CURRENT_MODULE}</h4></div>
                            <div className="card-body">
                                <p className="text-muted">{FORGOT_PASSWORD.MAIN_CONTENT}</p>
                                <FormikForm  
                                    initialValues={initialValues} 
                                    validationSchema={Validation} 
                                    onSubmit={handleSubmit_}
                                    loading={sending}
                                    inputFields={InputFields}
                                    submitting={submitting}
                                    buttonText={FORGOT_PASSWORD.BUTTON}
                                    is_block={true}
                                    col={12}
                                    is_passwordHide={false}
                                />
                                <div className="float-right">
                                    <Link to={Path.login} class="text-small">
                                        {FORGOT_PASSWORD.BACK}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    <div className="simple-footer">
                        &copy; {moment().get("years")} {process.env.REACT_APP_APPNAME_FOOTER}, INC. All Rights Reserved.
                    </div>
                </div>
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);