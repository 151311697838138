import {postMediaData} from 'helpers/apiHelper'
import { MAIN_PATH, UPLOAD_IMAGE_PATH, DELETE_IMAGE_PATH} from './apiPath'
import { uploadImage, deleteUploadImage,} from 'store/actions/file';
//upload image
export function uploadImage_(value, callback) {
    return (dispatch) => postMediaData(`${MAIN_PATH}${UPLOAD_IMAGE_PATH}`, value)
      .then((data) => {
        dispatch(uploadImage(data, callback));
        return data;
      });
  }
  
  //delete image
  export function deleteUploadImage_(value, callback) {
    return (dispatch) => postMediaData(`${MAIN_PATH}${DELETE_IMAGE_PATH}`, value)
      .then((data) => {
        dispatch(deleteUploadImage(data, callback));
        return data;
      });
  }
