import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, RESOURCE_LIST, RESOURCE_ADD, RESOURCE_UPDATE, RESOURCE_VIEW, RESOURCE_DELETE  } from './apiPath'
import { getResource, addResource, viewResource, updateResource, deleteResource} from 'store/actions/resource';

//get
export function getResourceList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${RESOURCE_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getResource(data, callback));
      return data;
    });
}

//add
export function addResourceData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${RESOURCE_ADD}`, value)
    .then((data) => {
      dispatch(addResource(data, callback));
      return data;
    });
}

//view
export function viewResourceData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${RESOURCE_VIEW}${value}`)
    .then((data) => {
      dispatch(viewResource(data, callback));
      return data;
    });
}


//edit
export function updateResourceData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${RESOURCE_UPDATE}`, value)
    .then((data) => {
      dispatch(updateResource(data, callback));
      return data;
    });
}


//delete
export function deleteResourceData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${RESOURCE_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteResource(data));
      return data;
    });
}

