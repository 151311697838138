import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({start, end, handleSelect}) => {
  const [startDate, setStartDate] = useState(start || null);
  const [endDate, setEndDate] = useState(end || null);

  useEffect(() => {
    setStartDate(start);
    setEndDate(end);
  }, [start, end]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleSelect(start, end);
  };
  
  return (
    <DatePicker
      placeholderText="Click to select a date range"
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
    />
  );
}

export default DateRangePicker;