import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useHistory, Link, useLocation } from "react-router-dom";

//imported
import { UploadMediaFile } from "helpers/UploadFile";
import { IS_BASE64 } from "helpers/common";
import { RESOURCES } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import {
  addResourceData,
  viewResourceData,
  updateResourceData,
} from "store/services/resourceService";
import { getCategoryList } from "store/services/categoryService";
import {
  getSubcategoryCategoryList,
  getSubCategoryList,
} from "store/services/subcategoryService";
import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";

const AddEdit = ({
  submitting,
  dispatch,
  match,
  categoryList,
  loader,
  subCategoryData,
}) => {
  const location = useLocation();
  const [resourceDetail, setResourceDetail] = useState({});
  const catgeoryId = location?.state
    ? location?.state?.paramValue
    : resourceDetail.cat_id;

  const [selectedCatId, setSelectedCategory] = useState(catgeoryId);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  //fields
  const inputFields = [
    {
      name: "cat_id",
      type: "dynamic_select",
      label: "Category*",
      placeholder: "Categories",
      options: categoryOptions,
      disableNavigate: true,
    },
    {
      name: "sub_cat_id",
      type: "dynamic_select",
      label: "Subcategory",
      placeholder: "Subcategories",
      options: subCategoryOptions,
      disableNavigate: true,
    },
    {
      name: "resource_name",
      type: "text",
      label: "Document Name*",
      placeholder: "Document name",
    },
    {
      name: "status",
      type: "radio",
      label: "Status*",
      options: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    },
    {
      name: "resource_media",
      type: "image",
      label: "Media (PDF)*",
      placeholder: "",
      allowType: ["application/pdf"],
      allowTypeError: "PDF",
      extension: "pdf",
    },
  ];

  const history = useHistory();
  let editkey = match.params.id;

  const initialValues = {
    cat_id: resourceDetail ? resourceDetail.cat_id : "",
    sub_cat_id: resourceDetail ? resourceDetail.sub_cat_id : "",
    resource_media: resourceDetail.resource_media
      ? resourceDetail.resource_media
      : "",
    resource_name: resourceDetail.resource_name || "",
    status: resourceDetail ? resourceDetail.status : "active",
  };
  //fetch categories
  async function fetchCategory() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getCategoryList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //fetch subcategories
  async function fetchSubCategory(catgeoryId) {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getSubcategoryCategoryList(catgeoryId));
      dispatch(actions.persist_store({ loader: false }));
      // setSubid(catgeoryId)
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //get detail
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(viewResourceData(editkey));
      setResourceDetail(res?.body);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey) {
      getData();
    }
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    const options = categoryList.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setCategoryOptions(options);
  }, [categoryList]);

  useEffect(() => {
    if (selectedCatId) {
      fetchSubCategory(selectedCatId);
    }
    if (catgeoryId) {
      fetchSubCategory(catgeoryId);
    }
  }, [selectedCatId, catgeoryId]);

  useEffect(() => {
    if (subCategoryData) {
      const subCategoryData_ = subCategoryData.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setSubCategoryOptions(subCategoryData_);
    }
  }, [subCategoryData]);

  // add edit
  const handleSubmit_ = async (values, { resetForm, setSubmitting }) => {
    try {
      let imgRes;
      console.log(values.resource_media, values.extension, values.file_type);
      if (await IS_BASE64(values.resource_media)) {
        imgRes = await UploadMediaFile(
          dispatch,
          values.resource_media,
          values.extension,
          values.file_type,
          "subcategory"
        );
        console.log("imgRes", imgRes);
      }

      const callback = (response) => {
        const { data } = response;
        if (data.success == true) {
          history.push(Path.resource);
        }
      };
      let data = {
        category_id: values.cat_id,
        subcategory_id: values.sub_cat_id,
        status: values.status ? values.status : "active",
        resource_name: values.resource_name || "New document",
      };
      if (values.resource_media) {
        data.media = imgRes?.body?.url;
        data.media_type = values.extension;
      }
      if (editkey) {
        data.id = editkey;
      }

      data = JSON.stringify(data);
      setSubmitting(true);
      dispatch(actions.persist_store({ loader: true }));
      if (editkey) {
        await dispatch(updateResourceData(data, callback));
        resetForm();
      } else {
        await dispatch(addResourceData(data, callback));
        resetForm();
      }
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  return (
    <>
      <Helmet title={RESOURCES.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{RESOURCES.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">
              <Link to={Path.resource}>{RESOURCES.CURRENT_MODULE}</Link>
            </div>
            <div className="breadcrumb-item">
              {editkey ? "Edit" : "Add"} Resource
            </div>
          </div>
        </div>
        {/* {!props.loader && */}
        <div className="section-body">
          <h2 className="section-title">
            {editkey ? "Edit" : "Add"} New Resource
          </h2>
          <p className="section-lead">
            On this page you can create a new resource and fill in all fields.
          </p>
          <div className="row mt-sm-4">
            <div className="col-8 col-md-8 col-lg-8">
              <div className="card">
                <div className="card-body">
                  <FormikForm
                    initialValues={initialValues}
                    validationSchema={Validation}
                    onSubmit={handleSubmit_}
                    loading={loader}
                    inputFields={inputFields}
                    submitting={submitting}
                    buttonText="Save Changes"
                    is_block={false}
                    col={12}
                    imageRemoveButton={true}
                    selectDisable={false}
                    PathUrl={Path.resource_add}
                    onFieldChange={(type, value) => {
                      if (type == "cat_id") {
                        setSelectedCategory(value);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    resourceDetail: state.Resource.resource,
    categoryList: state.Category.categories,
    loader: state.Subcategory.loader,
    subCategoryData: state.Subcategory.sub_catgeory_detail,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);
