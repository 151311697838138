import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//imported
import * as Path from 'routes/paths';
import { RESET_PASSWORD } from "common/viewContent";
import { resetPassword, resetPasswordForm } from 'store/services/authService';
import * as actions from 'store/actions';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'
import moment from "moment";

const ResetPassword = ({submitting, dispatch, match}) => {
    const [sending, setSending] = useState(false);
    //values
    const initialValues = {
        new_password: "",
        confirm_password:"",
        token: match ? match.params.token : ''
    };

    useEffect(() => {
        let isMounted = true;
        async function getData() {
            try{    
                let token = match ? match.params.token : '';
                dispatch(actions.persist_store({ loader:true }));
                await dispatch(resetPasswordForm(token)).then((res) => {
                    if(isMounted){
                        dispatch(actions.persist_store({ loader:false }));
                    }
                });
            }catch(err) {
                console.log(err);
                dispatch(actions.persist_store({ loader:false }));
             
            }  
        }
        getData();
        return () => {
            isMounted = false;
        };
    }, []);

    //submit data
    const handleSubmit_ = async (values, {resetForm, setSubmitting}) => {
        try{
            values  = JSON.stringify(values)
            dispatch(actions.persist_store({ loader:true }));
            setSubmitting(true)
            setSending(true)
            await dispatch(resetPassword(values));
            resetForm();
            dispatch(actions.persist_store({ loader:false }));
            setSending(false)
            setSubmitting(false)
        }catch(err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader:false }));
            setSending(false)
        }
    }

    return(
        <>
            <Helmet title={RESET_PASSWORD.CURRENT_MODULE} />
            <div className="container mt-5" style={{overflow:'hidden'}}>
                <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    
                    <div className="login-brand">
                    </div>
                    <div className="card card-primary">
                        <div className="card-header"><h4>{RESET_PASSWORD.CURRENT_MODULE}</h4></div>
                            <div className="card-body">
                                <p className="text-muted">{RESET_PASSWORD.MAIN_CONTENT}</p>
                                <FormikForm  
                                    initialValues={initialValues} 
                                    validationSchema={Validation} 
                                    onSubmit={handleSubmit_}
                                    loading={sending}
                                    inputFields={InputFields}
                                    submitting={submitting}
                                    buttonText={RESET_PASSWORD.BUTTON}
                                    is_block={true}
                                    col={12}
                                    is_passwordHide={false}
                                />
                                <div className="float-right">
                                    <Link to={Path.login} class="text-small">
                                        {RESET_PASSWORD.BACK}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    <div className="simple-footer">
                        Copyright &copy; {moment().get("years")} Zenaura <div className="bullet"></div> Design  & Develop by Suffescom
                    </div>
                </div>
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);