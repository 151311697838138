import * as Yup from 'yup';

const Validation = Yup.object({
  new_password: Yup.string().required("Please enter your password").min(6, "Password must be at most 6 characters long").notOneOf([Yup.ref("old_password")], "New password must be different from the old password"),
  confirm_password: Yup.string()
  .required("Please confirm your password")
  .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

export default Validation;
