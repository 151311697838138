import { GET_HELP_PAGES } from "store/actions/helpPage";

const initialState = {
    isAuthenticated: false,
    pages: [],
    page: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getHelpPages = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        pages:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

const HelpPage = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_HELP_PAGES:
          return getHelpPages(state, payload);
        default:
        return state;
    };
}
export default HelpPage;