import { UC_FIRST } from "helpers/common";
import { Link } from "react-router-dom";
import * as Path from "routes/paths";

const PractitionerListing = ({ practitioners }) => {
  return (
    <table className="table table-striped">
      <tbody>
        <tr>
          <th>#</th>
          <th>Display Name</th>
          <th>Subscription Plan</th>
          {/* <th>Created At</th> */}
          <th>Status</th>
          <th>Action</th>
        </tr>
        {practitioners && practitioners.length > 0 &&
          practitioners.map((pract, i) => {
            return (
              <tr>
                <td>{ i + 1 }</td>
                <td>{ pract.display_name }</td>
                <td>
                  <div>{ pract.package_name }</div>
                </td>
                {/* <td>Dec 15, 2023</td> */}
                <td>
                  <div className={`badge text-white badge-${pract.nta_status == "approve" ? "success" : "danger"} px-3`}>
                    { UC_FIRST(pract.nta_status) }
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-10 tableBtn">
                    <Link title="View" to={`${Path.practitionerview}/${pract.id}`}>
                      <i className="fa fa-lg fa-eye" />
                    </Link>
                    <Link title="Edit" to={`${Path.practitioneredit}/${pract.id}`}>
                      {" "}
                      <i className="fa fa-lg fa-edit" />
                    </Link>
                  </div>
                </td>
              </tr>
            )
          })
        }

        {(!practitioners || practitioners.length == 0) &&
          <tr>
            <td colSpan={5} className="text-center">
              Data not found
            </td>
          </tr>
        }
        
      </tbody>
    </table>
  )
}

export default PractitionerListing;