import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';

//imported
import { SYSTEM_ACCESS } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { addSystemAccessData, viewSystemAccessData, updateSystemAccessData } from 'store/services/systemAccessService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const AddEdit = ({ submitting, dispatch, match, loader }) => {
    const history = useHistory();
    const [systemAccessDetail, setSystemAccessDetail] = useState({})
    let editkey = match.params.id;

    const initialValues = {
        first_name: systemAccessDetail ? systemAccessDetail.first_name : "",
        last_name: systemAccessDetail ? systemAccessDetail.last_name : "",
        password: systemAccessDetail ? systemAccessDetail.password : "",
        email: systemAccessDetail ? systemAccessDetail.email : "",
        status: systemAccessDetail ? systemAccessDetail.status : "active",
    };
    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(viewSystemAccessData(editkey))
            setSystemAccessDetail(res?.body)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        if (editkey) {
            getData();
        }
    }, []);

    // add edit
    const handleSubmit_ = async (values, {setSubmitting}) => {
        try {
            const callback = (response) => {
                const { data } = response;
                if (data.success == true) {
                    history.push(Path.system_access)
                }
            }
            if (editkey) {
                values.id = editkey;
            }
            values.status = values.status ? values.status : 'active'
            // delete values.password
            values = JSON.stringify(values)
            dispatch(actions.persist_store({ loader: true }));
            setSubmitting(true)
            if (editkey) {
                await dispatch(updateSystemAccessData(values, callback));
            } else {
                await dispatch(addSystemAccessData(values, callback));
            }
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    }


    return (
        <>
            <Helmet title={SYSTEM_ACCESS.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{SYSTEM_ACCESS.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        
                        <div className="breadcrumb-item"><Link to={Path.system_access}>{SYSTEM_ACCESS.CURRENT_MODULE}</Link></div>
                        <div className="breadcrumb-item">{editkey ? 'Edit' : 'Add'} System Access</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">{editkey ? 'Edit' : 'Add'} New System Access</h2>
                    <p className="section-lead">
                        On this page you can create a new system access and fill in all fields.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={handleSubmit_}
                                        loading={loader}
                                        inputFields={InputFields}
                                        submitting={submitting}
                                        buttonText="Save Changes"
                                        is_block={false}
                                        col={12}
                                        imageRemoveButton={true}
                                        selectDisable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        systemAccessDetail: state.SystemAccess.system_access,
        loader: state.SystemAccess.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);