//action types
export const GET_FORM_LISTING = 'GET_FORM_LISTING';
export const GET_CLIENT_CATEGORIES = 'GET_CLIENT_CATEGORIES';

export function getForms(data, callback) {
  return {
    type: GET_FORM_LISTING,
    payload : {data, callback},
  };
}

export function getClientCat(data, callback) {
  return {
    type: GET_CLIENT_CATEGORIES,
    payload : {data, callback},
  };
}