import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
//imported
import {
  getCategoryList,
  deleteCategoryData,
} from "store/services/categoryService";
import { CATEGORIES, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";

const List = ({
  dispatch,
  categoryList,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  //get category detail
  async function getData() {
    try {
      let query = {
        page: activePage,
        limit: PAGE_LIMIT,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getCategoryList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [activePage]);

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteCategoryData(id)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={CATEGORIES.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CATEGORIES.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.category_add} className="btn btn-primary">
              Add New
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">{CATEGORIES.CURRENT_MODULE}</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{CATEGORIES.CURRENT_MODULE}</h2>
          <p className="section-lead">
            In this section, you can access a list of categories associated with the Resources section within the Practitioner Panel. You have the ability to manage these categories efficiently, allowing you to perform actions such as adding, viewing, editing, and deleting them as needed.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{CATEGORIES.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {categoryList.length > 0 &&
                        categoryList.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{FORMAT_TEXT(item.name)}</td>
                              <td>{DATE_FORMAT(item.created_at)}</td>
                              <td>
                                <div
                                  className={`badge text-white badge-${item.status == "active"
                                    ? "primary"
                                    : "danger"
                                    }`}
                                >
                                  {item.status}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-10 tableBtn">
                                  <Link
                                    to={`${Path.categoryview}/${item.id}`}
                                    title="View"
                                  >
                                    <i className="fa fa-lg fa-eye"></i>
                                  </Link>
                                  <Link
                                    to={`${Path.categoryedit}/${item.id}`}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link>
                                  <span
                                    className="text-danger"
                                    onClick={() => deleteItem_(item.id)}
                                  >
                                    <i
                                      className="fa fa-lg fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  {categoryList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.Category.categories,
    itemsCountPerPage: state.Category.itemsCountPerPage,
    totalItemsCount: state.Category.totalItemsCount,
    loader: state.Category.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
