import { UC_FIRST, formatText } from "helpers/common";
import React from "react";
import ReactApexChart from "react-apexcharts";

const SubscriptionPieChart = ({ data }) => {
  if(!data) {
    return "";
  }

  const series = data ? data.map(row => row.count) : [];
  const labels = data ? data.map(row => formatText(row.status)) : [];

  const chartData = {
    series: series,
    options: {
      toolbar: {
        show: false,
      },
      labels: labels,
      chart: {
        type: "donut",
      },
      colors: [
        "#fc544b",
        "#c3ed84",
        "#a9ba12",
        "#e58cf7",
        "#606eff",
        "#ba1a18",
        "#f2ba71",
        "#66bcff",
        "#c7e4fc",
        "#5afce4"
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  }
  
  const legendData = data.map((row, i) => ({
    label: formatText(row.status),
    value: row.count,
    color: chartData.options.colors[i],
  }));

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
        />
      </div>
      <div style={{ textAlign: "center" }}>
        {legendData.map((item) => (
          <span key={item.label} style={{ marginRight: "10px" }}>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: item.color,
                marginRight: "5px",
                borderRadius: "50%",
              }}
            />
            {item.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPieChart;
