import React, { useState } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { reduxForm} from 'redux-form';
import { Link } from 'react-router-dom';

//imported
import { CHANGE_PASSWORD } from "common/viewContent";
import { changePassword } from 'store/services/authService';
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const ChangePassword = ({submitting, dispatch}) => {
    const [sending, setSending] = useState(false);

    //values
    const initialValues = {
        old_password: "",
        new_password: "",
        confirm_password: "",
    }

    //submit data
    const handleSubmit_ = async (values, {resetForm, setSubmitting}) => {
        try{
            setSending(true);
            dispatch(actions.persist_store({ loader:true }));
            const callback = (response) => {
                const { success } = response;
                if(success == true){
                    setSending(false);
                    resetForm();
                }
            }
            let res = {
                old_password : values.old_password,
                new_password : values.new_password
            }
            res  = JSON.stringify(res)
            setSubmitting(true)
            await dispatch(changePassword(res, callback))
            setSubmitting(false)
        }catch(err) {
            dispatch(actions.persist_store({ loader:false }));
            setSubmitting(false)
            console.log(err);
            setSending(false);
        }
    }

    return(
        <>
            <Helmet title={CHANGE_PASSWORD.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{CHANGE_PASSWORD.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                       
                        <div className="breadcrumb-item"><Link to={Path.profile}>Profile</Link></div>
                        <div className="breadcrumb-item">Change password</div>
                    </div>
                </div>
                <div className="section-body">
                    <h2 className="section-title">{CHANGE_PASSWORD.CURRENT_MODULE}</h2>
                    <p className="section-lead">
                    You can manage your password from here.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm  
                                        initialValues={initialValues} 
                                        validationSchema={Validation} 
                                        onSubmit={handleSubmit_}
                                        loading={sending}
                                        inputFields={InputFields}
                                        submitting={submitting}
                                        buttonText="Save Changes"
                                        is_block={false}
                                        col={12}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);