import { getData, putData } from 'helpers/apiHelper'
import { MAIN_PATH, TEMPLETE_LIST, VIEW_TEMPLETE, UPDATE_TEMPLETE} from './apiPath'
import { getTemplete, updateTemplete, viewTemplete} from 'store/actions/templete';
//get profile
export function templeteApi() {
  return (dispatch) => getData(`${MAIN_PATH}${TEMPLETE_LIST}`)
    .then((data) => {
      dispatch(getTemplete(data));
      return data;
    });
}

//view
export function viewTemplete_(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${VIEW_TEMPLETE}${value}`)
    .then((data) => {
      dispatch(viewTemplete(data, callback));
      return data;
    });
}

//edit
export function updateTemplete_(id, value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${UPDATE_TEMPLETE}${id}`, value)
    .then((data) => {
      dispatch(updateTemplete(data, callback));
      return data;
    });
}
