import React, {useState} from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

//imported
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

const Layout = ({children, loader}) => {   
    const override = css`
    display: block;
    margin: 0 auto;
    `;
    let [color, setColor] = useState("#114862");

    return(
        <>
            <div className="main-wrapper">
                <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APPNAME}`} /> 
                <Header /> 
                <Sidebar /> 
                    {loader === true &&
                        <div className="loading-overlay">
                            <div className="main-loader">
                                <FadeLoader color={color} loading={loader} css={override} size={1000} />
                            </div>
                        </div>
                    }
                    <div className="main-content" style={{minHeight: '705px'}}>
                        {React.cloneElement(children)}
                    </div>
                <Footer />
            </div>
        </>

    )
}
const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

export default connect(
  mapStateToPros,
)(Layout);




