import swal from 'sweetalert';
import { toast } from 'react-toastify';

export function SUCCESS_MESSAGE(message){
  toast.success(message);
}
export function ERROR_MESSAGE(message){
  toast.error(message);
}

export function  DELETE_ALERT() {
    return new Promise((resolve) => {
      swal({
        title: "Are you sure?",
        text: "Are you sure to Delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };

  export function  DELETE_ALERT_() {
    return new Promise((resolve) => {
      swal({
        title: "Are you sure?",
        text: "Please select atleast one id",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };

  export function  CORRECT_ANSWER() {
    return new Promise((resolve) => {
      swal({
        title: "Please select correct answer",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };

  export function  SELECT_CORRECT_FILE() {
    return new Promise((resolve) => {
      swal({
        text: "Selected file not allowed to upload",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };
  export function  FILE_TOO_LARGE() {
    return new Promise((resolve) => {
      swal({
        text: "Selected video size too long",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };
  export function  CSV_TEXT() {
    return new Promise((resolve) => {
      swal({
        text: "Import successfully",
        icon: "success",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };

  export function CONFIRM_ALERT(msg) {
    return new Promise((resolve) => {
      swal({
        title: "Are you sure?",
        text: msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };