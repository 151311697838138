import { postData, getData } from 'helpers/apiHelper'
import { MAIN_PATH, LOGIN, APP_PATH, LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD, RESET_PASSWORD_FORM, CHANGE_PASSWORD, ACCOUNT_ACTIVATION } from './apiPath'
import { authLogin, authLogout, updatePassword } from 'store/actions/auth';
import { getProfile } from 'store/actions/profile';

//login
export function login(credentials, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${LOGIN}`, credentials)
    .then((data) => {
      dispatch(authLogin(data, callback));
      dispatch(getProfile(data));
      return data;
    });
}

//logout
export function logout(credentials) {
  return (dispatch) => getData(`${MAIN_PATH}${LOGOUT}`, credentials)
    .then((data) => {
      dispatch(authLogout(data));
      return data;
    });
}

//forgot passord
export function forgotPassword(value) {
  return (dispatch) => postData(`${MAIN_PATH}${FORGOT_PASSWORD}`, value)
    .then((data) => {
      return data;
    });
}


//forgot passord
export function resetPasswordForm(value) {
  return (dispatch) => getData(`${MAIN_PATH}${RESET_PASSWORD_FORM}`, value)
    .then((data) => {
      return data;
    });
}


//forgot passord
export function resetPassword(value) {
  return (dispatch) => postData(`${MAIN_PATH}${RESET_PASSWORD}`, value)
    .then((data) => {
      return data;
    });
}


//account activation
export function activateAccount(value) {
  return (dispatch) => postData(`${APP_PATH}${ACCOUNT_ACTIVATION}`, value)
    .then((data) => {
      return data;
    });
}

//change password
export function changePassword(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CHANGE_PASSWORD}`, value)
    .then((data) => {
      dispatch(updatePassword(data, callback));
      return data;
    });
}
