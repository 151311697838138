import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";
//imported
import validation from "./Validation";
import LoadingButton from "components/shared/LoadingButton";

const Form_ = ({
  initialValues,
  submitting,
  updateData_,
  clientFormListing,
  handleCheckboxChange,
  selectedCheckboxes,
  isView
}) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validation}
        onSubmit={updateData_}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Category*
                </label>
                <input
                  type="category_name"
                  className={`form-control ${errors.category_name && "is-invalid"}`}
                  name="category_name"
                  onChange={handleChange}
                  placeholder="Category"
                  onBlur={handleBlur}
                  value={values.category_name}
                  autoComplete="off"
                />
                {errors.category_name && touched.category_name && (
                  <p className="invalid-feedback">{errors.category_name}</p>
                )}
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Default Forms*
                </label>
                <ul className="list-unstyled p-0 mb-0">
                  {clientFormListing.length > 0 && clientFormListing.map((val, i) => {
                     return(
                      <li className="py-1" key={i}>
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <input
                              type="checkbox"
                              className={`form-check ${errors.forms && "is-invalid"}`}
                              name="forms"
                              checked={selectedCheckboxes?.includes(val.id)} 
                              onChange={(e) => handleCheckboxChange(e, val.id)}
                              disabled={isView ? true : false}
                              value={values.forms}
                            />
                          </div>
                        </div>
                        <p className=" m-0 ">
                          {val.form_name}
                        </p>
                      </div>
                    </li>
                    )
                  })}
                </ul>
              </Col>

              <br />
              {!isView &&
              <div className="form-group button-submit">
                <LoadingButton
                  type="submit"
                  className={`btn btn-primary btn-lg`}
                  // loading={loading}
                  disabled={isSubmitting}
                >
                 {isSubmitting ? 'Wait...' : 'Save Changes' }
                </LoadingButton>
              </div>
              }
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Form_;
