import React from "react";
import { Formik } from 'formik';

//imported
import LoadingButton from 'components/shared/LoadingButton';
import validation from "./Validation";

const Form = ({ initialValues, sending, submitting, updateData_, editkey }) => {

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validation}
                onSubmit={updateData_}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    {/* <div className="form-group col-md-6 col-12">
                                        <div className="card-header">
                                            Stripe Mode Detail
                                        </div>
                                        <label>Mode</label>
                                        <select
                                            className={`form-select ${errors.stripe_mode && 'is-invalid'}`}
                                            name="stripe_mode"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.stripe_mode}
                                        >
                                            <option value="" disabled>select</option>
                                            <option value="live" selected={values.stripe_mode === 'live' ? 'selected' : ''}>Live</option>
                                            <option value="test" selected={values.stripe_mode === 'test' ? 'selected' : ''}>Test</option>
                                        </select>
                                        {(errors.stripe_mode && touched.stripe_mode) &&
                                            <p className="invalid-feedback">{errors.stripe_mode}</p>
                                        }
                                    </div> */}
                                    {/* <div className="form-group col-md-6 col-12">
                                        <div className="card-header">
                                            Full Script Mode Detail
                                        </div>
                                        <label>Mode</label>
                                        <select
                                            className={`form-select ${errors.full_script_mode && 'is-invalid'}`}
                                            name="full_script_mode"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.full_script_mode}
                                        >
                                            <option value="" disabled>select</option>
                                            <option value="live" selected={values.full_script_mode === 'live' ? 'selected' : ''}>Live</option>
                                            <option value="test" selected={values.full_script_mode === 'test' ? 'selected' : ''}>Test</option>
                                        </select>
                                        {(errors.full_script_mode && touched.full_script_mode) &&
                                            <p className="invalid-feedback">{errors.full_script_mode}</p>
                                        }

                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-header">
                                        <h6>Commission Settings</h6>
                                    </div>
                                    <div className="card-body">
                                        {/* <div className="form-group col-md-6 col-12">
                                            <label>Mailgun api key</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.mailgun_api_key && 'is-invalid'}`}
                                                name="mailgun_api_key"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mailgun_api_key}
                                            />
                                            {(errors.mailgun_api_key && touched.mailgun_api_key) &&
                                                <p className="invalid-feedback">{errors.mailgun_api_key}</p>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <label>Mailgun domain</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.mailgun_domain && 'is-invalid'}`}
                                                name="mailgun_domain"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mailgun_domain}
                                            />
                                            {(errors.mailgun_domain && touched.mailgun_domain) &&
                                                <p className="invalid-feedback">{errors.mailgun_domain}</p>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <label>Mailgun from</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.mailgun_from && 'is-invalid'}`}
                                                name="mailgun_from"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mailgun_from}
                                            />
                                            {(errors.mailgun_from && touched.mailgun_from) &&
                                                <p className="invalid-feedback">{errors.mailgun_from}</p>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <label>Mailgun auth</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.mailgun_auth && 'is-invalid'}`}
                                                name="mailgun_auth"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mailgun_auth}
                                            />
                                            {(errors.mailgun_auth && touched.mailgun_auth) &&
                                                <p className="invalid-feedback">{errors.mailgun_auth}</p>
                                            }
                                        </div> */}

                                        <div className="row">
                                            <div className="form-group col-md-6 col-12">
                                                <label>Appointment commission</label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.appointment_commission && 'is-invalid'}`}
                                                    name="appointment_commission"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.appointment_commission}
                                                />
                                                {(errors.appointment_commission && touched.appointment_commission) &&
                                                    <p className="invalid-feedback">{errors.appointment_commission}</p>
                                                }
                                            </div>

                                            <div className="form-group col-md-6 col-12">
                                                <label>Package commission</label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.package_commission && 'is-invalid'}`}
                                                    name="package_commission"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.package_commission}
                                                />
                                                {(errors.package_commission && touched.package_commission) &&
                                                    <p className="invalid-feedback">{errors.package_commission}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-primary"
                                    loading={sending}
                                    disabled={isSubmitting ?  true : false}
                                >
                                {isSubmitting ? 'Wait...' : `${editkey ? 'Update Changes' : 'Save Changes'}` }
                                </LoadingButton>
                            </div>
                        </div>

                    </form>
                )}
            </Formik>
        </>
    )
}

export default Form;