import {
  CLEAR_CLIENT_DETAILS,
  GET_CLIENT,
  // ADD_CLIENT,
  VIEW_CLIENT,
  // UPDATE_CLIENT,
  // DELETE_CLIENT,
} from "store/actions/client";

const initialState = {
  isAuthenticated: false,
  clients: [],
  client: {},
  loader: false,
  totalItemsCount: 0,
  itemsCountPerPage: 0,
};

//get
const getClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    clients: data.body.data,
    itemsCountPerPage: data.body.per_page,
    totalItemsCount: data.body.total_count,
  };
  return stateObj;
};

//add
// const addClient = (state, payload) => {
//   const { data, callback } = payload;
//   if (typeof callback === "function") {
//     callback(payload);
//   }
//   const stateObj = {
//     ...state,
//     isAuthenticated: true,
//     clients: data.body,
//   };
//   return stateObj;
// };

// //update
const viewClient = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    client: data.body?.length > 0 ? data.body[0] : initialState.client,
  };
  return stateObj;
};

// //update
// const updateClient = (state, payload) => {
//   const { data, callback } = payload;
//   if (typeof callback === "function") {
//     callback(payload);
//   }
//   const stateObj = {
//     ...state,
//     isAuthenticated: true,
//     clients: data.body,
//   };
//   return stateObj;
// };

// //delete
// const deleteClient = (state, payload) => {
//   const { data } = payload;
//   const stateObj = {
//     ...state,
//     isAuthenticated: true,
//     clients: [],
//   };
//   return stateObj;
// };

//delete
const clearClientDetails = (state) => {
  const stateObj = {
    ...state,
    isAuthenticated: true,
    client: initialState.client,
  };
  return stateObj;
};

const Client = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_CLIENT:
      return getClient(state, payload);
    // case ADD_CLIENT:
    //   return addClient(state, payload);
    case VIEW_CLIENT:
      return viewClient(state, payload);
    case CLEAR_CLIENT_DETAILS:
      return clearClientDetails(state);
    // case UPDATE_CLIENT:
    //   return updateClient(state, payload);
    // case DELETE_CLIENT:
    //   return deleteClient(state, payload);
    default:
      return state;
  }
};
export default Client;
