import { getData, postData, postData_, putData } from 'helpers/apiHelper'
import { MAIN_PATH, CONFIGURATION_ADD, CONFIGURATION_VIEW, INSTANCE_PERMISSIONS_LIST, INSTANCE_LIST, INSTANCE_PERMISSION_TOGGLE } from './apiPath'
import { addConfiguration, instanceTypesList, viewConfiguration } from 'store/actions/configuration';

//add
export function addConfigurationData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CONFIGURATION_ADD}`, value)
    .then((data) => {
      dispatch(addConfiguration(data, callback));
      return data;
    });
}

//view
export function viewConfigurationData(value, callback) {
  return (dispatch) => postData_(`${MAIN_PATH}${CONFIGURATION_VIEW}${value}`)
    .then((data) => {
      dispatch(viewConfiguration(data, callback));
      return data;
    });
}


// View permisions listing
export function viewPermissionsList(callback) {
  return getData(`${MAIN_PATH}${INSTANCE_PERMISSIONS_LIST}`)
}

// View instance listing
export function viewInstancesList(callback) {
  return (dispatch) => getData(`${MAIN_PATH}${INSTANCE_LIST}`)
    .then((data) => {
      dispatch(instanceTypesList(data, callback));
      return data;
    });
}

// Toggle instance permission
export function toggleInstancesPermission(data, callback) {
  let { permissionId, values } = data;
  return putData(`${MAIN_PATH}${INSTANCE_PERMISSION_TOGGLE}/${permissionId}`, values)
}
