import { UC_FIRST } from "helpers/common";
import moment from "moment";

const inputFields = [
  {
    name: "first_name",
    type: "text",
    label: "First Name",
    placeholder: "First Name",
    view: true,
    edit: true
  },
  {
    name: "last_name",
    type: "text",
    label: "Last Name",
    placeholder: "Last Name",
    view: true,
    edit: true
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Email",
    view: true,
    edit: true
  },
  // {
  //   name: "password",
  //   type: "password",
  //   label: "Password*",
  //   placeholder: "Password",
  //   view :true
  // },
  {
    name: "birth_date",
    type: "date",
    label: "Date of Birth",
    placeholder: "Date of Birth",
    view: true,
    edit: true
  },
  {
    name: "phone",
    type: "phone", // Specify the type as 'phone'
    label: "Phone Number",
    placeholder: "Phone Number",
    view: true,
    edit: true
  },
  {
    name: "is_favorite",
    type: "text",
    label: "Is Favorite",
    placeholder: "Is Favorite",
    view: true,
    edit: true,
    formatter: (clientDetail) => {
      return UC_FIRST(clientDetail?.is_favorite)
    }
  },
  {
    name: "type",
    type: "text",
    label: "Type",
    placeholder: "Type",
    view: true,
    edit: true
  },
  {
    name: "status",
    type: "select",
    label: "Status",
    placeholder: "Select",
    view: true,
    options: [
      {
        label: "Active",
        value: "active",
      },
      {
        label: "Inactive",
        value: "inactive",
      },
    ],
    formatter: (clientDetail) => {
      let status;
      if (clientDetail.is_direct_account === 'yes') {
          status = 'Direct Link';
      } else if (clientDetail.status === 'active' &&  clientDetail.is_direct_account === 'no') {
          status = 'Active';
      } else {
          status = 'Unlinked';
      }

      return status;
    }
  },
  {
    name: "created_at",
    type: "date",
    label: "Created At",
    placeholder: "Created At ",
    view: true,
    edit: true,
    formatter: (clientDetail) => {
      return moment(clientDetail.created_at).format('MMM D, YYYY');
    }
  },
];

export default inputFields;
