import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";

import { useHistory, Link } from "react-router-dom";
import LoadingButton from "components/shared/LoadingButton";
//imported
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { PRACTITIONER } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import {
  addPractitionerData,
  viewPractitionerData,
  updatePractitionerData,
} from "store/services/practitionerService";

const AddEdit = ({
  submitting,
  dispatch,
  match,
  loader,
}) => {

  const [sending, setSending] = useState(false)
  const [practitionerDetail, setPractitionerDetail] = useState({})
  const todayDate = moment().format('YYYY-MM-DD')

  const history = useHistory();
  let editkey = match.params.id;
  const [fields, setfields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    status: "active",
    is_free_account: 'no',
    free_account_expiry: "",
  })
  const dbDate = moment(practitionerDetail?.free_account_expiry).format('YYYY-MM-DD')
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (practitionerDetail) {
      console.log(practitionerDetail, "practitionerDetail")
      setfields(prevState => ({
        ...prevState,
        ...practitionerDetail,
        is_free_account: practitionerDetail.is_free_account === 'yes' ? 'yes' : 'no',
      }))
    }
    if (!practitionerDetail) {
      setfields({})
    }
  }, [practitionerDetail])
  //get detail
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(viewPractitionerData(editkey));
      setPractitionerDetail(res.body)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    if (editkey) {
      getData();
    }
  }, []);

  //handle changes
  const handleChange = useCallback(
    name => evt => {
      // If the input is a checkbox, use checked instead of value
      let value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;

      if(typeof value === "string") value = value.trim();

      setfields(prevState => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );


  const handleSubmit_ = async (evt) => {
    try {
      evt.preventDefault();
      // let imgRes;
      // if (await IS_BASE64(fields.profile_image)) {
      //   imgRes = await UploadMediaFile(
      //     dispatch,
      //     fields.profile_image,
      //     fields.extension,
      //     fields.file_type,
      //     "category"
      //   );
      //   imgRes = imgRes.uploadResult;
      // }
      const callback = (response) => {
        const { data } = response;
        if (data.success == true) {
          history.push(Path.practitioner);
        }
      };
      if (validateForm()) {
        const phoneNumberWithoutPlus = fields.phone
          ? fields.phone.substring(fields?.country_code?.length)
          : null;
        const codeWithoutPlus = fields?.country_code;
        let data = {
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
          // password: fields.password,
          // gender: fields.gender,
          phone: phoneNumberWithoutPlus,
          country_code: fields.country_code
            ? codeWithoutPlus?.startsWith("+")
              ? codeWithoutPlus
              : "+" + codeWithoutPlus
            : null,
          // profile_image: imgRes
          //   ? imgRes
          //   : values.profile_image
          //     ? values.profile_image
          //     : null,
          is_free_account: fields.is_free_account === 'yes' ? 'yes' : 'no',
          status: fields.status,
        };
        if (fields.is_free_account) {
          data.free_account_expiry = fields.free_account_expiry;
        }
        if (data.is_free_account === 'no') {
          delete data.free_account_expiry
        }
        console.log(fields, "**********");
        if (editkey) {
          data.id = editkey;
        }
        data = JSON.stringify(data);
        setSending(true)
        dispatch(actions.persist_store({ loader: true }));
        if (editkey) {
          await dispatch(updatePractitionerData(data, callback));
        } else {
          await dispatch(addPractitionerData(data, callback));
        }
        setfields({})
        setSending(false)
        dispatch(actions.persist_store({ loader: false }));
      }
    } catch (err) {
      console.log(err, "==============================");
      setSending(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!fields.first_name.trim()) {
      errors.first_name = "Required";
    }
    if (!fields.last_name.trim()) {
      errors.last_name = "Required";
    }
    if (!fields.email.trim() || !fields.email.includes("@")) {
      errors.email = "Valid email is required";
    }
    if (fields.is_free_account === 'yes' && !fields.free_account_expiry) {
      errors.free_account_expiry = "Required";
    }
    // Add validation for other fields as needed

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  return (
    <>
      <Helmet title={PRACTITIONER.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{PRACTITIONER.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">
              <Link to={Path.practitioner}>{PRACTITIONER.CURRENT_MODULE}</Link>
            </div>
            <div className="breadcrumb-item">
              {editkey ? "Edit" : "Add"} Practitioner
            </div>
          </div>
        </div>
        {/* {!props.loader && */}
        <div className="section-body">
          <h2 className="section-title">
            {editkey ? "Edit" : "Add"} Practitioner
          </h2>
          <p className="section-lead">
            On this page you can edit practitioner and fill in all fields.
          </p>
          <div className="row mt-sm-4">
            <div className="col-8 col-md-8 col-lg-8">
              <div className="card">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>First Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="first_name"
                          onChange={handleChange("first_name")}
                          placeholder="First Name"
                          value={fields.first_name}
                          autoComplete="off"
                          required
                        />
                        {errors.first_name && <p className="invalid-feedback">{errors.first_name}</p>}

                      </div>
                      <div className="form-group col-md-6">
                        <label>Last Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          onChange={handleChange("last_name")}
                          placeholder="Last Name"
                          value={fields.last_name}
                          autoComplete="off"
                          required
                        />
                        {errors.last_name && <p className="invalid-feedback">{errors.last_name}</p>}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          onChange={handleChange("email")}
                          placeholder="Email"
                          value={fields.email}
                          autoComplete="off"
                          required
                        />
                        {errors.email && <p className="invalid-feedback">{errors.email}</p>}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Phone</label>
                        <PhoneInput
                          enableAreaCodes={true}
                          name="phone"
                          country={'us'} // Set the default country, or the one relevant to your use case
                          value={fields.phone} // Provide the value for the PhoneInput
                          onChange={(value, country) => {
                            // Handle phone value change
                            setfields(prevState => ({
                              ...prevState,
                              phone: value,
                              country_code: country.dialCode,
                            }));
                          }}
                          maxLength={15}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Account Type</label>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input
                              type="radio"
                              className="form-radio"
                              name="is_free_account"
                              onChange={handleChange("is_free_account")}
                              value="yes"
                              checked={fields.is_free_account === 'yes' ? true : false}
                            />
                            <label>&nbsp;Student</label>
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              type="radio"
                              className="form-radio"
                              name="is_free_account"
                              onChange={handleChange("is_free_account")}
                              value="no"
                              checked={fields.is_free_account === 'no' ? true : false}
                            />
                            <label>&nbsp;Practitioner</label>
                          </div>
                        </div>
                        {/* <input
                          type="checkbox"
                          className="form-check"
                          name="is_free_account"
                          onChange={handleChange("is_free_account")}
                          value="yes"
                          checked={fields.is_free_account ? true : false}
                        /> */}
                      </div>
                      {(fields.is_free_account != 'no' || fields.is_free_account === 'yes') &&
                        <div className="form-group col-md-6">
                          <label>Expired Free Account Date*</label>
                          <input
                            type="date"
                            className="form-control"
                            name="free_account_expiry"
                            onChange={handleChange("free_account_expiry")}
                            value={fields.free_account_expiry}
                            min={editkey ? dbDate : todayDate}
                            required={fields.is_free_account ? true : false}
                            autoComplete="off"
                          />
                          {errors.free_account_expiry && <p className="invalid-feedback">{errors.free_account_expiry}</p>}
                        </div>
                      }
                      <div className="form-group col-md-6">
                        <label>Status</label>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input
                              type="radio"
                              className="form-radio"
                              name="status"
                              onChange={handleChange("status")}
                              value="active"
                              checked={fields.status === 'active' ? true : false}
                            />
                            <label>&nbsp;Active</label>
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              type="radio"
                              className="form-radio"
                              name="status"
                              onChange={handleChange("status")}
                              value="inactive"
                              checked={fields.status === 'inactive' ? true : false}
                            />
                            <label>&nbsp;Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group button-submit">
                        <LoadingButton
                          type="submit"
                          className="btn btn-primary btn-lg"
                          loading={sending}
                          onClick={handleSubmit_}
                        >
                          {sending ? 'Wait...' : 'Submit'}
                        </LoadingButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </section>
    </>
  );
};



const mapStateToProps = (state) => {
  return {
    // practitionerDetail: state.Practitioner.practitioner,
    loader: state.Practitioner.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);
