import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import {
  getSubscriptionList,
  deleteSubscriptionData,
} from "store/services/subscriptionService";
//imported
import { SUBSCRIPTION, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";

const List = ({
  dispatch,
  subscriptionList,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  //get
  async function getData() {
    try {
      let query = {
        page: activePage,
        limit: PAGE_LIMIT,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getSubscriptionList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [activePage]);

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteSubscriptionData(id)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={SUBSCRIPTION.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{SUBSCRIPTION.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.subscription_add} className="btn btn-primary">
              Add New
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">Subscription</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{SUBSCRIPTION.CURRENT_MODULE}</h2>
          <p className="section-lead">
            In this section, you can establish various subscription plans for your practitioners/users and carry out actions such as editing and deleting. These changes will be visible in the Practitioner Panel.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{SUBSCRIPTION.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  {/* <div className="float-left">
                                <select className="form-control selectric">
                                    <option>Action For Selected</option>
                                    <option>Move to Draft</option>
                                    <option>Move to Pending</option>
                                    <option>Delete Pemanently</option>
                                </select>
                            </div> */}
                  {/* <div className="float-right"> */}
                  {/* <form>
                                <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search" />
                                <div className="input-group-append">                                            
                                    <button className="btn btn-primary"><i className="fas fa-search"></i></button>
                                </div>
                                </div>
                            </form> */}
                  {/* </div> */}

                  {/* <div className="clearfix mb-3"></div> */}

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        {/* <th className="text-center pt-2">
                                        <div className="custom-checkbox custom-checkbox-table custom-control">
                                        <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad" className="custom-control-input" id="checkbox-all" />
                                        <label for="checkbox-all" className="custom-control-label">&nbsp;</label>
                                        </div>
                                    </th> */}
                        <th>Package Name</th>
                        <th>Package Type</th>
                        <th>Package Price</th>
                        <th>Is NTA Member Package</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                      {subscriptionList.length > 0 &&
                        subscriptionList.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{FORMAT_TEXT(item?.package_name)}</td>
                              <td>{item.package_type}</td>
                              <td>{`${item.package_price}`}</td>
                              <td>
                                <div
                                  className={`badge text-white badge-${item.is_nta_package == "yes"
                                    ? "primary"
                                    : item.is_nta_package == "no"
                                      ? "danger"
                                      : "warning"
                                    }`}
                                >
                                  {item.is_nta_package}
                                </div>
                              </td>
                              <td>
                                <div
                                  className={`badge text-white badge-${item.status == "active"
                                    ? "primary"
                                    : item.status == "inactive"
                                      ? "danger"
                                      : "warning"
                                    }`}
                                >
                                  {item.status}
                                </div>
                              </td>
                              <td>{DATE_FORMAT(item.created_at)}</td>
                              <td>
                                <div className="d-flex align-items-center gap-10 tableBtn">
                                  <Link
                                    to={`${Path.subscriptionview}/${item.id}`}
                                    title="View"
                                  >
                                    <i className="fa fa-lg fa-eye"></i>
                                  </Link>
                                  <Link
                                    to={`${Path.subscriptionedit}/${item.id}`}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link>
                                  <span
                                    className="text-danger"
                                    onClick={() => deleteItem_(item.id)}
                                  >
                                    <i
                                      className="fa fa-lg fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  {subscriptionList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionList: state.Subscription.subscriptions,
    itemsCountPerPage: state.Subscription.itemsCountPerPage,
    totalItemsCount: state.Subscription.totalItemsCount,
    loader: state.Subscription.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
