//action types
export const GET_SYSTEM_ACCESS = 'GET_SYSTEM_ACCESS';
export const ADD_SYSTEM_ACCESS = 'ADD_SYSTEM_ACCESS';
export const VIEW_SYSTEM_ACCESS = 'VIEW_SYSTEM_ACCESS';
export const UPDATE_SYSTEM_ACCESS = 'UPDATE_SYSTEM_ACCESS';
export const DELETE_SYSTEM_ACCESS = 'DELETE_SYSTEM_ACCESS';

//get 
export function getSystemAccess(data, callback) {
  return {
    type: GET_SYSTEM_ACCESS,
    payload : {data, callback},
  };
}
//add 
export function addSystemAccess(data, callback) {
  return {
    type: ADD_SYSTEM_ACCESS,
    payload : {data, callback},
  };
}

//view 
export function viewSystemAccess(data, callback) {
  return {
    type: VIEW_SYSTEM_ACCESS,
    payload : {data, callback},
  };
}
//update 
export function updateSystemAccess(data, callback) {
    return {
      type: UPDATE_SYSTEM_ACCESS,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteSystemAccess(payload) {
  return {
    type: DELETE_SYSTEM_ACCESS,
    payload
  };
}
