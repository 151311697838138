import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { CATEGORIES } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { viewCategoryData } from 'store/services/categoryService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const View = ({submitting, dispatch, match, categoryDetail, loader}) => {
    let editkey = match.params.id;
 
    const initialValues = {
        name: categoryDetail ? categoryDetail.name : "",
        status: categoryDetail ? categoryDetail.status : "",
        image: categoryDetail ? categoryDetail.image : "",
    };
    //get detail
    async function getData() {
        try{
           dispatch(actions.persist_store({ loader:true}));
           await dispatch(viewCategoryData(editkey))
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
   }
    useEffect(() => {
        getData();
    }, []);
 
    
    return(
        <>
            <Helmet title={CATEGORIES.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{CATEGORIES.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        
                        <div className="breadcrumb-item"><Link to={Path.subscriptions}>{CATEGORIES.CURRENT_MODULE}</Link></div>
                        <div className="breadcrumb-item">View Category</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                    <div className="section-body">
                    <h2 className="section-title">View Category</h2>
                    <p className="section-lead">
                        On this page you see category detail.
                    </p>
                        <div className="row mt-sm-4">
                            <div className="col-8 col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <FormikForm  
                                            initialValues={initialValues} 
                                            validationSchema={Validation} 
                                            onSubmit={''}
                                            loading={loader}
                                            inputFields={InputFields}
                                            submitting={submitting}
                                            buttonText={false}
                                            is_block={false}
                                            backPath={Path.category}
                                            ValidationError={false}
                                            col={12}
                                            imageRemoveButton={false}
                                            selectDisable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return{
        categoryDetail : state.Category.category,
        loader:state.Category.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);