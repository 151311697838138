import {
  GET_SUBCATEGORY,
  ADD_SUBCATEGORY,
  VIEW_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  CATEGORY_SUBCATEGORY,
} from "store/actions/subcategory";

const initialState = {
  isAuthenticated: false,
  subcategories: [],
  subcategory: {},
  sub_catgeory_detail: [],
  loader: false,
  totalItemsCount: 0,
  itemsCountPerPage: 0,
};

//get
const getSubCategory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subcategories: data.body.data,
    itemsCountPerPage: data.body.per_page,
    totalItemsCount: data.body.total_count,
  };
  return stateObj;
};

//add
const addSubCategory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subcategories: data.body,
  };
  return stateObj;
};

//update
const viewSubCategory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subcategory: data.body,
  };
  return stateObj;
};

//update
const updateSubCategory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subcategories: data.body,
  };
  return stateObj;
};

//delete
const deleteSubCategory = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subscriptions: [],
  };
  return stateObj;
};

//get
const getSubcategoryCategory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    sub_catgeory_detail: data.body,
  };
  return stateObj;
};

const Subcategory = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_SUBCATEGORY:
      return getSubCategory(state, payload);
    case ADD_SUBCATEGORY:
      return addSubCategory(state, payload);
    case VIEW_SUBCATEGORY:
      return viewSubCategory(state, payload);
    case UPDATE_SUBCATEGORY:
      return updateSubCategory(state, payload);
    case DELETE_SUBCATEGORY:
      return deleteSubCategory(state, payload);
    case CATEGORY_SUBCATEGORY:
      return getSubcategoryCategory(state, payload);
    default:
      return state;
  }
};
export default Subcategory;
