import axios from 'axios';

//imported
import store from '../store';
import { authLogout } from '../store/actions/auth';

const access_token = localStorage.getItem('access_token');
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = true;
   
// Create instance
const axiosInstance = axios.create();

// Axios wrapper to handle error
const axiosWrapper = apiCall => apiCall.then(res => res.data).catch(err => Promise.reject(err));

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response && error?.response?.status === 403) {
      store.dispatch(authLogout());
    }
    if (error?.response && error?.response?.data?.message === null) {
      store.dispatch(authLogout());
    }
    if (error?.response && error.response?.data?.message === 'Missing Authentication Token') {
      store.dispatch(authLogout());
    }
    if (error?.response && error.response?.data?.message === 'Invalid Authentication Token Format') {
      store.dispatch(authLogout());
    }
    if (error?.response && error.response?.data?.message === 'Not Authorized') {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  },
);

export default axios;
