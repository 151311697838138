import React, { useState } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';

//imported
import { login } from 'store/services/authService';
import * as Path from 'routes/paths';
import * as actions from 'store/actions';
import { FORGOT_PASSWORD, LOGIN } from "common/viewContent";
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'
import moment from "moment";

const Login = ({submitting, dispatch}) => {
    const history = useHistory();
    const [sending, setSending] = useState(false);
    //values
    const initialValues = {
        email: "",
        password: "",
    };

    const handleSubmit_ = async (values, {setSubmitting}) => {
        try {
          setSending(true);
          dispatch(actions.persist_store({ loader: true }));
          const callback = (response) => {
            const { success } = response;
            if(success == true){
                history.push(Path.practitioner)
            }
            
        }
        values = JSON.stringify(values)
        setSubmitting(true)
        await dispatch(login(values, callback))
        dispatch(actions.persist_store({ loader: false }));
        setSubmitting(false)
        } catch (err) {
            setSubmitting(false)
            console.log(err,  "==============================");
          dispatch(actions.persist_store({ loader: false }));
          setSending(false);
        }
    };

    return(
        <>
            <Helmet title={LOGIN.CURRENT_MODULE} />
            <div className="container mt-5" style={{overflow:'hidden'}}>
                <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="login-brand">
                    <p>{LOGIN.TOP_CONTENT}</p>
                    </div>
                    <div className="card card-primary">
                        <div className="card-header"><h4>{LOGIN.MAIN_CONTENT}</h4></div>

                            <div className="card-body">
                                <FormikForm  
                                    initialValues={initialValues} 
                                    validationSchema={Validation} 
                                    onSubmit={handleSubmit_}
                                    loading={sending}
                                    inputFields={InputFields}
                                    submitting={submitting}
                                    buttonText={LOGIN.BUTTON}
                                    is_block={true}
                                    col={12}
                                    is_passwordHide={false}
                                />
                                
                                <div className="float-right">
                                    <Link to={Path.forgot_password} class="text-small">
                                        {FORGOT_PASSWORD.BUTTON}?
                                    </Link>
                                </div>
                            </div>
                        </div>
                    <div className="simple-footer">
                        &copy; {moment().get("years")} {process.env.REACT_APP_APPNAME_FOOTER}, INC. All Rights Reserved.
                    </div>
                </div>
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const LoginForm = reduxForm({
	form: 'login'
})(Login);


export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);