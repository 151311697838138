//action types
export const GET_CLIENT_CATEGORY_FORM_FORM = 'GET_CLIENT_CATEGORY_FORM_FORM';
export const ADD_CLIENT_CATEGORY_FORM = 'ADD_CLIENT_CATEGORY_FORM';
export const VIEW_CLIENT_CATEGORY_FORM = 'VIEW_CLIENT_CATEGORY_FORM';
export const UPDATE_CLIENT_CATEGORY_FORM = 'UPDATE_CLIENT_CATEGORY_FORM';
export const DELETE_CLIENT_CATEGORY_FORM = 'DELETE_CLIENT_CATEGORY_FORM';
export const GET_CLIENT_FORM_LIST = 'GET_CLIENT_FORM_LIST';

//get 
export function getClientFormCategory(data, callback) {
  return {
    type: GET_CLIENT_CATEGORY_FORM_FORM,
    payload : {data, callback},
  };
}
//add 
export function addClientFormCategory(data, callback) {
  return {
    type: ADD_CLIENT_CATEGORY_FORM,
    payload : {data, callback},
  };
}

//view 
export function viewClientFormCategory(data, callback) {
  return {
    type: VIEW_CLIENT_CATEGORY_FORM,
    payload : {data, callback},
  };
}
//update 
export function updateClientFormCategory(data, callback) {
    return {
      type: UPDATE_CLIENT_CATEGORY_FORM,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteClientFormCategory(payload) {
  return {
    type: DELETE_CLIENT_CATEGORY_FORM,
    payload
  };
}
//form list 
export function getClientFormList(data, callback) {
  return {
    type: GET_CLIENT_FORM_LIST,
    payload : {data, callback},
  };
}
