export const STATUS_ENUM = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
]

export const COLOR_OPTIONS = {
  default: "#fff",
  primary: "#cfe2ff",
  secondary: "#e2e3e5",
  success: "#d1e7dd",
  info: "#bee5eb",
  danger: "#f8d7da",
  warning: "#fff3cd",
  magenta: "#FF00FF",
  lightGreen: "#6cb77d",
  green: "#28a745",
  red: "#db1e30",
  orange: "#ffa707",
}