import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

//imported
import { HELP_PAGES } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import validation from './Validation'
import { addHelpPageData, getAllHelpPages, updateHelpPageData, viewHelpPageData } from "store/services/helpPageService";
import { createSlug } from "helpers/common";
import { Formik } from "formik";
import { Form, Row } from "react-bootstrap";
import CKEditorField from "components/shared/CKEditorField";
import LoadingButton from "components/shared/LoadingButton";

const AddEdit = ({ submitting, dispatch, match, loader }) => {
	const history = useHistory();
	let editkey = match.params.id;
	const [url, setUrl] = useState()
	const [pageDetail, setPageDetail] = useState(null)
	const [pagesListing, setPagesListing] = useState(null);
	const [selectedParentPage, setSelectedParentPage] = useState(null);
	const [initialValues, setInitialValues] = useState({
		slug: "",
		title: "",
		content: "",
		status: "active",
	})

	let parentPageOptions;
	try {
		parentPageOptions = pagesListing
			? (
				[
					{ value: "", label: "None" },
					...pagesListing.map(page => (page?.id != editkey && { value: page.id, label: page.title }))
						.filter(option => !!option)
				]
			)
			: [];
	} catch (err) {
		console.log("Failed to fetch parent page options", err);
		parentPageOptions = [];
	}

	useEffect(() => {
		if (pageDetail) {
			setInitialValues({
				slug: pageDetail ? pageDetail.slug : initialValues.slug,
				title: pageDetail ? pageDetail.title : initialValues.title,
				content: pageDetail ? pageDetail.content : initialValues.content,
				status: pageDetail ? pageDetail.status : initialValues.status,
			});
			setUrl(pageDetail?.slug);
		}
	}, [pageDetail]);

	useEffect(() => {
		if (pageDetail?.is_parent === "no" && pageDetail.parent_id && pagesListing?.length) {
			const parentPage = pagesListing.find(page => page.id === pageDetail.parent_id);
			parentPage && setSelectedParentPage({
				value: parentPage.id,
				label: parentPage.title
			});
		}
	}, [pageDetail, pagesListing]);

	useEffect(() => {
		getPages();
	}, [])

	async function getPages() {
		try {
			dispatch(actions.persist_store({ loader: true }));
			let res = await getAllHelpPages();
			setPagesListing(res?.body);
			dispatch(actions.persist_store({ loader: false }));
		} catch (err) {
			dispatch(actions.persist_store({ loader: false }));
			console.log(err);
		}
	}

	//get detail
	async function getData() {
		try {
			dispatch(actions.persist_store({ loader: true }));
			let res = await viewHelpPageData(editkey);
			setPageDetail(res?.body)
			dispatch(actions.persist_store({ loader: false }));
		} catch (err) {
			dispatch(actions.persist_store({ loader: false }));
			console.log(err);
		}
	}
	useEffect(() => {
		if (editkey) {
			getData();
		}
	}, []);

	// add edit
	const savePageData = async (values) => {
		try {
			if (editkey) {
				values.id = editkey;
			}

			if (selectedParentPage?.value) {
				values.is_parent = "no";
				values.parent_id = selectedParentPage.value;
			}

			values.status = values.status ? values.status : 'active'
			values = JSON.stringify(values)
			dispatch(actions.persist_store({ loader: true }));
			let response;
			if (editkey) {
				response = await updateHelpPageData(values);
			} else {
				response = await addHelpPageData(values);
			}
			dispatch(actions.persist_store({ loader: false }));
			if (response.success == true) {
				history.push(Path.help_pages)
			}
		} catch (err) {
			console.log(err);
			dispatch(actions.persist_store({ loader: false }));
		}
	}

	return (
		<>
			<Helmet title={HELP_PAGES.CURRENT_MODULE} />
			<section className="section">
				<div className="section-header">
					<h1>{HELP_PAGES.CURRENT_MODULE}</h1>
					<div className="section-header-breadcrumb">

						<div className="breadcrumb-item"><Link to={Path.help_pages}>Content</Link></div>
						<div className="breadcrumb-item">Add Content</div>
					</div>
				</div>
				{/* {!props.loader && */}
				<div className="section-body">
					<h2 className="section-title">Add New Content</h2>
					<p className="section-lead">
						On this page you can create a new content and fill in all fields.
					</p>
					<div className="row mt-sm-4">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="card">
								<div className="card-body">

									<div className="row">
										<div className="form-group col-md-6 col-12">
											<label>URL Preview</label>
											<input className="form-control" disabled value={`${process.env.REACT_APP_PRACTITIONER_PANEL_URL}/help/${url || ""}`} />
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6 col-12">
											<label>Parent</label>
											<Select
												value={selectedParentPage}
												onChange={setSelectedParentPage}
												options={parentPageOptions}
												placeholder="Select parent page"
											/>
										</div>
									</div>

									{/* <FormikForm
												initialValues={initialValues}
												validationSchema={Validation}
												onSubmit={savePageData}
												loading={loader}
												inputFields={InputFields}
												submitting={submitting}
												buttonText="Save Changes"
												is_block={false}
												col={12}
												selectDisable={false}
												onFieldChange={handleFieldChange}
										/> */}

									<Formik
										initialValues={initialValues}
										enableReinitialize
										validationSchema={validation}
										onSubmit={savePageData}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											setFieldValue,
											isSubmitting,
										}) => {
											// console.log(values, errors);
											return (
												<Form onSubmit={handleSubmit} autoComplete="off">
													<Row>
														<div
															className={`form-group col-md-6 col-12`}
														>
															<label>Title</label>
															<input
																type="text"
																className={`form-control ${errors["title"]
																	? "is-invalid"
																	: ""
																	} `}
																name="title"
																onChange={(e) => {
																	let slug = createSlug(e.target.value);
																	setFieldValue("slug", slug);
																	setUrl(slug);
																	handleChange(e);
																}}
																placeholder={"Title"}
																onBlur={handleBlur}
																value={values.title}
															/>
															{errors["title"] && touched["title"] && (
																<p className="invalid-feedback">{errors["title"]}</p>
															)}
														</div>
														<div
															className={`form-group col-md-6 col-12`}
														>
															<label>Slug</label>
															<input
																type="text"
																className={`form-control ${errors["slug"]
																	? "is-invalid"
																	: ""
																	} `}
																name="slug"
																onChange={(e) => {
																	let slug = createSlug(e.target.value);
																	setFieldValue("slug", slug);
																	setUrl(slug);
																}}
																placeholder={"Slug"}
																onBlur={handleBlur}
																value={values.slug}
															/>
															{errors["slug"] && touched["slug"] && (
																<p className="invalid-feedback">{errors["slug"]}</p>
															)}
														</div>
														<div
															className={`form-group col-12`}
														>
															<label>Content</label>
															<CKEditorField
																field="content"
																setFieldValue={setFieldValue}
																value={values.content || ""}
																errors={errors}
															/>
															{errors["content"] && touched["content"] && (
																<p className="invalid-feedback">{errors["content"]}</p>
															)}
														</div>
														<div
															className={`form-group col-md-6 col-12`}
														>
															<label>Status</label>
															<div className="row">
																<div className="form-group col-md-6">
																	<div className="row">
																		{[
																			{
																				label: 'Active',
																				value: 'active'
																			},
																			{
																				label: 'Inactive',
																				value: 'inactive'
																			},
																		].map((option, index) => (
																			<div className="form-group col-md-6" key={index}>
																				<input
																					type="radio"
																					className="form-radio"
																					name="status"
																					onChange={() =>
																						setFieldValue("status", option.value)
																					}
																					value={option.value}
																					checked={
																						values.status === option.value ||
																						(option.value === "active" &&
																							!values.status)
																					}
																				/>
																				<label>&nbsp;{option.label}</label>
																			</div>
																		))}
																	</div>
																	{errors["status"] && touched["status"] && (
																		<p className="invalid-feedback">{errors["status"]}</p>
																	)}
																</div>
															</div>
														</div>
														<div className="form-group button-submit">
															<LoadingButton
																type="submit"
																className="btn btn-primary btn-lg"
																loading={isSubmitting}
																disabled={isSubmitting}
															>
																{isSubmitting ? "Please Wait..." : "Save"}
															</LoadingButton>
														</div>
													</Row>
												</Form>
											)
										}}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* } */}
			</section>
		</>
	)
}


const mapStateToProps = (state) => {
	return {
		pageDetail: state.ContentPage.page,
		loader: state.ContentPage.loader
	}
};

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);