import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useHistory, Link } from "react-router-dom";

//imported
import { UploadMediaFile } from "helpers/UploadFile";
import { IS_BASE64 } from "helpers/common";
import { NOTIFICATION } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import {
  addSubCategoryData,
  viewSubCategoryData,
  updateSubCategoryData,
} from "store/services/subcategoryService";
import { getCategoryList } from "store/services/categoryService";
import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";

const AddEdit = ({
  submitting,
  dispatch,
  match,
  subcategoryDetail,
  categoryList,
  loader,
}) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  //fields
  const inputFields = [
    {
      name: "name",
      type: "text",
      label: "Name*",
      placeholder: "Name",
    },
    {
      name: "notification_type",
      type: "dynamic_select",
      label: "Notification Type*",
      placeholder: " Type",
      options: [{ value: "email", label: "email" }],
    },
    {
      name: "subject",
      type: "text",
      label: "Subject*",
      placeholder: "Enter a subject",
    },
    {
      name: "audience_type",
      type: "dynamic_select",
      label: "Audience Type*",
      placeholder: "Audience Type",
      options: [
        { value: "Practitioner 1", label: "Practitioner 1" },
        { value: "Practitioner 2", label: "Practitioner 2" },
        { value: "Practitioner 3", label: "Practitioner 3" },
        { value: "Practitioner 4", label: "Practitioner 4" },
        { value: "Practitioner 5", label: "Practitioner 5" },
      ],
    },
    {
      name: "Body",
      type: "ckeditor",
      label: "Body*",
      placeholder: "Message",
    },
    // {
    //   name: "status",
    //   type: "select",
    //   label: "Status*",
    //   placeholder: "Select",
    //   options: [
    //     {
    //       label: "Active",
    //       value: "active",
    //     },
    //     {
    //       label: "Inactive",
    //       value: "inactive",
    //     },
    //   ],
    // },
    // {
    //   name: "image",
    //   type: "image",
    //   label: "Image*",
    //   placeholder: "",
    //   allowType: ["image/jpeg", "image/png", "image/jpg"],
    //   allowTypeError: "JPEG, PNG, JPG",
    // },
  ];

  const history = useHistory();
  let editkey = match.params.id;

  const initialValues = {
    name: subcategoryDetail ? subcategoryDetail.name : "",
    category_id: subcategoryDetail ? subcategoryDetail.category_id : "",
    image: subcategoryDetail.image ? subcategoryDetail.image : "",
    status: subcategoryDetail ? subcategoryDetail.status : "",
  };
  //fetch categories
  async function fetchCategory() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getCategoryList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //get detail
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewSubCategoryData(editkey));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    fetchCategory();
    if (editkey) {
      getData();
    }
    const options = categoryList.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setCategoryOptions(options);
  }, []);

  // add edit
  const handleSubmit_ = async (values) => {
    try {
      let imgRes;
      if (await IS_BASE64(values.image)) {
        imgRes = await UploadMediaFile(
          dispatch,
          values.image,
          values.extension,
          values.file_type,
          "subcategory"
        );
        imgRes = imgRes.uploadResult;
      }

      const callback = (response) => {
        const { data } = response;
        if (data.success == true) {
          history.push(Path.subcategory);
        }
      };
      let data = {
        image: imgRes ? imgRes : values.image ? values.image : null,
        category_id: values.category_id,
        name: values.name,
        status: values.status,
      };
      if (editkey) {
        data.id = editkey;
      }
      data = JSON.stringify(data);
      dispatch(actions.persist_store({ loader: true }));
      if (editkey) {
        await dispatch(updateSubCategoryData(data, callback));
      } else {
        await dispatch(addSubCategoryData(data, callback));
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  return (
    <>
      <Helmet title={NOTIFICATION.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{NOTIFICATION.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              <Link to={Path.notification}>{NOTIFICATION.CURRENT_MODULE}</Link>
            </div>
            <div className="breadcrumb-item">
              {editkey ? "Edit" : "Add"} Notification
            </div>
          </div>
        </div>
        {/* {!props.loader && */}
        <div className="section-body">
          <h2 className="section-title">
            {editkey ? "Edit" : "Add"} New Notification
          </h2>
          <p className="section-lead">
            On this page you can create a new notification and fill in all
            fields.
          </p>
          <div className="row mt-sm-4">
            <div className="col-8 col-md-8 col-lg-8">
              <div className="card">
                <div className="card-body">
                  <FormikForm
                    initialValues={initialValues}
                    validationSchema={Validation}
                    onSubmit={handleSubmit_}
                    loading={loader}
                    inputFields={inputFields}
                    submitting={submitting}
                    buttonText="Save Changes"
                    is_block={false}
                    col={12}
                    imageRemoveButton={true}
                    selectDisable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subcategoryDetail: state.Subcategory.subcategory,
    categoryList: state.Category.categories,
    loader: state.Subcategory.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);
