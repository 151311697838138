//action types
export const GET_CATEGORY = 'GET_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const VIEW_CATEGORY = 'VIEW_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

//get 
export function getCategory(data, callback) {
  return {
    type: GET_CATEGORY,
    payload : {data, callback},
  };
}
//add 
export function addCategory(data, callback) {
  return {
    type: ADD_CATEGORY,
    payload : {data, callback},
  };
}

//view 
export function viewCategory(data, callback) {
  return {
    type: VIEW_CATEGORY,
    payload : {data, callback},
  };
}
//update 
export function updateCategory(data, callback) {
    return {
      type: UPDATE_CATEGORY,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteCategory(payload) {
  return {
    type: DELETE_CATEGORY,
    payload
  };
}
