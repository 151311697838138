import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { ANNOUNCEMENTS } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { debounce, FORMAT_TEXT, formatText } from "helpers/common";
import { deleteAnnouncement, getAnnouncementList } from "store/services/announcementService";
import ReactPagination from "components/shared/ReactPagination";
import { CONFIRM_ALERT } from "common/constantMessage";
import { Button, Form } from "react-bootstrap";

const List = ({
  dispatch,
  listing,
  itemsCountPerPage,
  totalItemsCount
}) => {

  const limit = 10;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    field: "created_at",
    direction: "desc"
  })
  const [filter, setFilter] = useState({
    search: "",
    role: ""
  });

  useEffect(() => {
    resetAndFetch();
  }, [filter]);

  const resetAndFetch = () => {
    setPage(1);
    fetchData(1);
  };

  const fetchData = async (pageToFetch) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let payload = {
        limit,
        page: pageToFetch,
        sort,
        role: filter.role,
        search: filter.search,
      };
      await dispatch(getAnnouncementList(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching appointments:", err);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      let confirmed = await CONFIRM_ALERT();
      if(!confirmed) return;

      dispatch(actions.persist_store({ loader: true }));
      await deleteAnnouncement(itemId);
      resetAndFetch();
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching appointments:", err);
    }
  }

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    fetchData(nextPage);
  }

  const handleChangeFilter = (key) => (e) => {
    const value = e.target.value;
    setFilter((prevValues) => ({
      ...prevValues,
      [key]: value
    }))
  }

  const debounceHandleSearch = useCallback(debounce((e) => {
    handleChangeFilter("search")(e)
  }, 500), []);

  return (
    <>
      <Helmet title={ANNOUNCEMENTS.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{ANNOUNCEMENTS.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.announcement_add} className="btn btn-primary">
              Add Announcement
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            
            <div className="breadcrumb-item">Form management</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{ANNOUNCEMENTS.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can manage the announcements for Practitioner/Client panel here.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{ANNOUNCEMENTS.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText"></div>
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search...."
                          className="form-control"
                          name="search"
                          onChange={debounceHandleSearch}
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Panel:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sub_status"
                          className="form-control pe-5"
                          onChange={handleChangeFilter("role")}
                          value={filter.role}
                        >
                          <option value="">All</option>
                          <option value="3">Practitioner Panel</option>
                          <option value="4">Client Panel</option>
                          {/* <option value="trail">Trail </option> */}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {listing?.length > 0 ? (
                        listing.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {index + 1}
                              </td>
                              <td>
                                {item.title}
                              </td>
                              <td>
                                {FORMAT_TEXT(item.description, 40)}
                              </td>
                              <td>
                                {item.role === 3 ? (
                                  "Practitioner Panel"
                                ):item.role === 4 ? (
                                  "Client Panel"
                                ):(
                                  ""
                                )}
                              </td>
                              <td>
                                {formatText(item.status)}
                              </td>
                              <td>
                                <Link
                                  to={generatePath(Path.announcement_edit, {id: item.id})}
                                  title="Edit"
                                >
                                  {" "}
                                  <i className="fa fa-lg fa-edit"></i>
                                </Link>
                                <Link to="#" className="text-danger" onClick={() => handleDelete(item.id)}>
                                  <i className="fa fa-lg fa-trash" title="Delete"></i>
                                </Link>
                              </td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          <td className="text-center" colSpan={6}>No data found</td>
                        </tr>
                      )}
                      
                    </table>
                  </div>
                  {listing?.length > 0 && (
                    <ReactPagination
                      activePage={page}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    listing: state.Announcement.allData,
    itemsCountPerPage: state.Announcement.itemsCountPerPage,
    totalItemsCount: state.Announcement.totalItemsCount,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
