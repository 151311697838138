const inputFields = [
    {
      name: 'first_name',
      type: 'text',
      label: 'First Name*',
      placeholder: 'First Name',
    },
    {
      name: 'last_name',
      type: 'text',
      label: 'Last Name*',
      placeholder: 'Last Name',
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email*',
      placeholder: 'Email',
    },
    {
      name: 'password',
      type: 'password',
      label: 'Password*',
      placeholder: 'Password',
    },
    {
      name: 'status',
      type: 'radio',
      label: 'Status*',
      options : [
        {
          label: 'Active',
          value:'active'
        },
        {
          label: 'Inactive',
          value:'inactive'
        },
      ]
    },
];

export default inputFields;