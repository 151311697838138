import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, FAQ_LIST,FAQ_ADD, FAQ_UPDATE, FAQ_VIEW, FAQ_DELETE  } from './apiPath'
import { getFAQ, addFAQ, viewFAQ, updateFAQ, deleteFAQ} from 'store/actions/faq';

//get
export function getFAQList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${FAQ_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getFAQ(data, callback));
      return data;
    });
}

//add
export function addFAQData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${FAQ_ADD}`, value)
    .then((data) => {
      dispatch(addFAQ(data, callback));
      return data;
    });
}

//view
export function viewFAQData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${FAQ_VIEW}${value}`)
    .then((data) => {
      dispatch(viewFAQ(data, callback));
      return data;
    });
}

//edit
export function updateFAQData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${FAQ_UPDATE}`, value)
    .then((data) => {
      dispatch(updateFAQ(data, callback));
      return data;
    });
}

//delete
export function deleteFAQData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${FAQ_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteFAQ(data));
      return data;
    });
}

