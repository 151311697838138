import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { SYSTEM_ACCESS } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { viewSystemAccessData } from 'store/services/systemAccessService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const View = ({submitting, dispatch, match, systemAccessDetail, loader}) => {
    let editkey = match.params.id;
 
    const initialValues = {
        first_name: systemAccessDetail ? systemAccessDetail.first_name : "",
        last_name: systemAccessDetail ? systemAccessDetail.last_name : "",
        password: systemAccessDetail ? systemAccessDetail.password : "",
        email: systemAccessDetail ? systemAccessDetail.email : "",
        status: systemAccessDetail ? systemAccessDetail.status : "",
    };
    //get detail
    async function getData() {
        try{
           dispatch(actions.persist_store({ loader:true}));
           await dispatch(viewSystemAccessData(editkey))
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
   }
    useEffect(() => {
        getData();
    }, []);
 
    
    return(
        <>
            <Helmet title={SYSTEM_ACCESS.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{SYSTEM_ACCESS.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        
                        <div className="breadcrumb-item"><Link to={Path.system_access}>{SYSTEM_ACCESS.CURRENT_MODULE}</Link></div>
                        <div className="breadcrumb-item">View System Access</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                    <div className="section-body">
                    <h2 className="section-title">View System Access</h2>
                    <p className="section-lead">
                        On this page you see system access detail.
                    </p>
                        <div className="row mt-sm-4">
                            <div className="col-8 col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <FormikForm  
                                            initialValues={initialValues} 
                                            validationSchema={Validation} 
                                            onSubmit={''}
                                            loading={loader}
                                            inputFields={InputFields}
                                            submitting={submitting}
                                            buttonText={false}
                                            is_block={false}
                                            backPath={Path.system_access}
                                            ValidationError={false}
                                            col={12}
                                            imageRemoveButton={false}
                                            selectDisable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return{
        systemAccessDetail : state.SystemAccess.system_access,
        loader:state.SystemAccess.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);