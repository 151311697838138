const inputFields = [
  {
    name: 'first_name',
    type: 'text',
    label: 'First Name*',
    placeholder: 'First Name',
  },
  {
    name: 'last_name',
    type: 'text',
    label: 'Last Name*',
    placeholder: 'Last Name',
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email*',
    placeholder: 'Email',
  },
  {
    name: 'phone',
    type: 'phone', // Specify the type as 'phone'
    label: 'Phone Number',
    placeholder: 'Phone Number',
  },
  //   {
  //     name: 'profile_image',
  //     type: 'image',
  //     label: 'Image*',
  //     placeholder: '',
  //     allowType: ["image/jpeg", "image/png", "image/jpg",],
  //     allowTypeError: "JPEG, PNG, JPG"
  //   },
];

export default inputFields;