import { GET_CATEGORY, ADD_CATEGORY, VIEW_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from 'store/actions/category';

const initialState = {
    isAuthenticated: false,
    categories: [],
    category: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        categories:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      categories:data.body,
    };
    return stateObj;
  };

  //update 
const viewCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      category:data.body,
    };
    return stateObj;
};

  //update 
const updateCategory = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      categories:data.body,
    };
    return stateObj;
};

//delete 
const deleteCategory = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subscriptions:[],
  };
  return stateObj;
};

const Category = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_CATEGORY:
          return getCategory(state, payload);
        case ADD_CATEGORY:
            return addCategory(state, payload);
        case VIEW_CATEGORY:
          return viewCategory(state, payload);
        case UPDATE_CATEGORY:
          return updateCategory(state, payload);
        case DELETE_CATEGORY:
          return deleteCategory(state, payload);
        default:
        return state;
    };
}
export default Category;