import React, { useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { IS_BASE64 } from 'helpers/common';
import { PROFILE } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { profile, updateProfile_ } from 'store/services/profileService';
import { UploadMediaFile } from 'helpers/UploadFile';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const Profile = ({ submitting, dispatch, profileData, loader }) => {
    const initialValues = {
        first_name: profileData ? profileData.first_name : "",
        last_name: profileData ? profileData.last_name : "",
        email: profileData ? profileData.email : "",
        phone: profileData  && profileData?.phone!==null ? profileData?.country_code +''+ profileData?.phone : "",
        country_code: profileData ? profileData?.country_code : "",
        // profile_image: profileData ? profileData.profile_image : "",
    };

    //get data
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(profile());
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        getData();
    }, []);

    // update profile
    const updateProfileSubmit_ = async (values, {setSubmitting}) => {
        try {
            let imgRes;
            if (values.profile_image && await IS_BASE64(values.profile_image)) {
                imgRes = await UploadMediaFile(dispatch, values.profile_image, values.extension, values.file_type, 'users');
                imgRes = imgRes.uploadResult
            }
            const phoneNumberWithoutPlus = values.phone ? values.phone.substring(values?.country_code?.length) : "";
            const codeWithoutPlus = values?.country_code;
            let query = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                // profile_image: imgRes ? imgRes : values.profile_image ? values.profile_image : null,
                phone:  phoneNumberWithoutPlus,
                country_code: codeWithoutPlus ? (codeWithoutPlus?.startsWith('+') ? codeWithoutPlus : '+' + codeWithoutPlus) : "",
            }
            query = JSON.stringify(query)
            dispatch(actions.persist_store({ loader: true }));
            setSubmitting(true)
            await dispatch(updateProfile_(query));
            dispatch(actions.persist_store({ loader: false }));
            setSubmitting(false)
            getData();
        } catch (err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    }

    return (
        <>
            <Helmet title={PROFILE.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{PROFILE.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                       
                        <div className="breadcrumb-item">Profile</div>
                    </div>
                </div>
                <div className="section-body">
                    <h2 className="section-title">Hi, {profileData?.first_name}!</h2>
                    <p className="section-lead">
                        Change information about yourself on this page.
                    </p>

                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={updateProfileSubmit_}
                                        loading={loader}
                                        inputFields={InputFields}
                                        submitting={submitting}
                                        buttonText="Save Changes"
                                        is_block={false}
                                        col={4}
                                        imageRemoveButton={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        profileData: state.Profile.user,
        loader: state.Profile.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);