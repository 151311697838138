import * as Yup from 'yup';

const Validation = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Email Required").email("Invalid email address"),
    password: Yup.string().required("Required").min(6, "Password must be at most 6 characters long"),
    // image: Yup.string().required("Required"),
    // status: Yup.string().required("Required"),
});

export default Validation;
