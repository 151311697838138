import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

//imported
import { DELETE_ALERT } from "common/constantMessage";
import { deleteHelpPageData, getHelpPageList, saveHelpPagesOrder } from "store/services/helpPageService";
import { HELP_PAGES, PAGE_LIMIT } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { Button, Form } from "react-bootstrap";
import ListingTable from "./components/ListingTable/Index";
import PreviewPagePopup from "./components/Modals/PreviewPagePopup";

const List = ({
  dispatch,
  pageList,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  const [showPreviewPagePopup, setShowPreviewPagePopup] = useState(false);
  const [previewPageId, setPreviewPageId] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState({
    status: null,
    search: ""
  });
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    filterData();
  }, [filter, pageList]);

  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     setActivePage(1);
  //     getData(1);
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, [filter]);

  // useEffect(() => {
  //   getData(activePage);
  // }, [activePage])

  const handleChangeFilter = (e) => {
    let { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  //get category detail
  async function getData(page) {
    try {
      let query = {
        page: page || activePage,
        limit: PAGE_LIMIT,
        search: filter.search || "",
        status: filter.status || "",
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getHelpPageList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const filterData = () => {
    let filteredResults = pageList;
    if(filter.search) {
      filteredResults = pageList?.filter((page) => {
        return (
          page?.title.toLowerCase().includes(filter.search.toLowerCase()) ||
          page?.slug.toLowerCase().includes(filter.search.toLowerCase())
        )
      })
    }

    setFilteredList(filteredResults);
  }

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            let response = await deleteHelpPageData(id);
            dispatch(actions.persist_store({ loader: false }));
            getData();
          } catch (err) {
            console.log(err);
            dispatch(actions.persist_store({ loader: false }));
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const saveSortingChanges = async (sortingData) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await saveHelpPagesOrder(sortingData);
      if(response?.success === true) {
        getData();
      }
      dispatch(actions.persist_store({ loader: false }));
      getData();
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  const openPreviewPopup = (pageId) => {
    setPreviewPageId(pageId)
    setShowPreviewPagePopup(true);
  }

  const closePreviewPopup = () => {
    setPreviewPageId(null)
    setShowPreviewPagePopup(false);
  }

  return (
    <>
      <PreviewPagePopup
        showPopup={showPreviewPagePopup}
        closePopup={closePreviewPopup}
        previewPageId={previewPageId}
      />
      <Helmet title={HELP_PAGES.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{HELP_PAGES.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.help_pages_add} className="btn btn-primary">
              Add Help Page
            </Link>
          </div>
          <div className="section-header-breadcrumb">

            <div className="breadcrumb-item">Content Pages</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{HELP_PAGES.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can manage all content pages, such as editing, deleting and
            more.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{HELP_PAGES.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText"></div>
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search...."
                          className="form-control"
                          name="search"
                          onChange={handleChangeFilter}
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Status:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          className="form-control"
                          onChange={handleChangeFilter}
                        >
                          <option value="">All</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <ListingTable
                      data={filteredList}
                      handleDelete={deleteItem_}
                      saveSortingChanges={saveSortingChanges}
                      handlePreview={openPreviewPopup}
                    />
                  </div>
                  {/* {pageList?.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageList: state.HelpPage.pages,
    itemsCountPerPage: state.HelpPage.itemsCountPerPage,
    totalItemsCount: state.HelpPage.totalItemsCount,
    loader: state.HelpPage.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
