import * as Yup from 'yup';

const Validation = Yup.object({
    slug: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    // status: Yup.string().required("Required"),
});

export default Validation;
