import React from 'react';

export default ({ loading, className, children, isSubmit, ...props }) => {
  return (
    <button
      disabled={loading ? true : false}
      className={`${className}`}
      {...props}
    >
      {loading && <div className="btn-spinner loader-text text-primary" />}
      {children}
    </button>
  );
};
