import { Link } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import { useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Accordion, Button } from "react-bootstrap";

const TableRow = ({ item, moveCard, index, id, handleDelete, handlePreview, setPageList, level }) => {
  const [childExpanded, setChildExpanded] = useState(false);
  const [childPages, setChildPages] = useState(item?.childPages || []);

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: `item_level_${level}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(draggedItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex, setPageList);
      draggedItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: `item_level_${level}`,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const style = {
    cursor: 'move',
    opacity: isDragging ? 0.2 : 1,
    border: isDragging ? "1px dashed gray" : "none",
    borderBottom: "1px solid #ccc",
  };

  return (
    <>
      <tr ref={ref} style={style} data-handler-id={handlerId}>
        {item?.childPages?.length > 0 ? (
          <td style={{ width: "5%", cursor: "pointer" }} onClick={() => setChildExpanded(!childExpanded)}>
            <i className={`fas fa-${childExpanded ? "minus" : "plus"} p-0`}></i>
          </td>
        ) : (
          <td></td>
        )}
        <td style={{ width: "5%" }}><i className="fas fa-arrows-alt p-0"></i></td>
        <td style={{ width: "5%" }}>{index + 1}</td>
        <td style={{ width: "25%" }}>{FORMAT_TEXT(item.title)}</td>
        <td style={{ width: "15%" }}>{item.slug}</td>
        <td style={{ width: "15%" }}>{DATE_FORMAT(item.created_at)}</td>
        <td style={{ width: "15%" }}>
          <div className={`badge text-white badge-${item.status === "active" ? "primary" : "danger"}`}>
            {item.status}
          </div>
        </td>
        <td style={{ width: "100%" }}>
          <div className="d-flex align-items-center gap-10 tableBtn">
            <Link to={`${Path.help_pages_edit}/${item.id}`} title="Edit">
              <i className="fa fa-lg fa-edit"></i>
            </Link>
            <Link to="#" className="text-danger" onClick={() => handleDelete(item.id)}>
              <i className="fa fa-lg fa-trash" title="Delete"></i>
            </Link>
            <Link to="#" onClick={() => handlePreview(item.id)}>
              <i className="fa fa-lg fa-eye" title="Preview"></i>
            </Link>
          </div>
        </td>
      </tr>
      {childExpanded && (
        <tr>
          <td colSpan={8}>
            <table style={{ marginLeft: "50px" }}>
              {childPages.length > 0 && childPages.map((childItem, i) => {
                childItem.index = i;
                let newLevel = level + 1;
                return (
                  <TableRow
                    key={childItem.id}
                    id={childItem.id}
                    index={i}
                    item={childItem}
                    level={newLevel}
                    moveCard={moveCard}
                    handleDelete={handleDelete}
                    handlePreview={handlePreview}
                    setPageList={setChildPages}
                  />
                );
              })}
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
