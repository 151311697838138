import React, { useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import LoadingButton from "components/shared/LoadingButton";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Dropzone from "components/shared/Dropzone";
import CKEditorField from "./CKEditorField";

const FormikForm = ({
  loading,
  className,
  children,
  initialValues,
  validationSchema,
  onSubmit,
  inputFields,
  submitting,
  buttonText,
  is_block,
  backPath,
  ValidationError,
  col,
  imageRemoveButton,
  selectDisable,
  PathUrl,
  paramValue,
  is_disable,
  onFieldChange,
  editkeyValue,
  ...props
}) => {
  const history = useHistory();
  const todayDate = moment().format("YYYY-MM-DD");
  const navigateToAnotherPage = (selectedValue) => {
    history.push({
      pathname: PathUrl,
      state: { paramValue: selectedValue },
    });
  };

  const formikRef = React.useRef(null);

  // if form is a edit form then validate on mount
  useEffect(() => {
    if(formikRef.current && editkeyValue) {
      typeof formikRef.current.validateForm === "function" && formikRef.current.validateForm();
    }
  }, [formikRef.current, editkeyValue]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const onChange = (fieldName, e) => {
            handleChange(e);
            typeof onFieldChange === "function" && onFieldChange(fieldName, e);
          }
          return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              {inputFields.map((field) => (
                <div
                  className={`form-group col-md-${field.colSize || col} col-12`}
                  key={field.name}
                >
                  <label>{field.label}</label>
                  {field.type === "image" ? (
                    <>
                      <div className="">
                        <div className="uploadImg position-relative d-inline-block">
                          <Dropzone
                            // file={values[field.name]}
                            file={
                              values[field.name]
                                ? { url: values[field.name] }
                                : null
                            } // Provide the file as an object
                            onChange={(
                              file,
                              fileUrl,
                              extension,
                              file_type,
                              fileName
                            ) => {
                              console.log("fileName", fileName);
                              setFieldValue(field.name, file);
                              setFieldValue("file", fileUrl);
                              setFieldValue("extension", extension);
                              setFieldValue("file_type", file_type);
                              // setFieldValue("name", fileName);
                            }}
                            allowType={field.allowType}
                            allowTypeError={field.allowTypeError}
                            extension_={field.extension}
                            imageRemoveButton={imageRemoveButton}
                          />
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.11125 13.125H8.88875C10.8394 13.125 11.815 13.125 12.5156 12.6657C12.8179 12.4675 13.0783 12.2118 13.2819 11.9132C13.75 11.2257 13.75 10.2675 13.75 8.35253C13.75 6.4369 13.75 5.4794 13.2819 4.7919C13.0783 4.49322 12.8179 4.23753 12.5156 4.0394C12.0656 3.74378 11.5019 3.63815 10.6387 3.60065C10.2269 3.60065 9.8725 3.2944 9.79187 2.89753C9.73026 2.60682 9.57017 2.3463 9.33865 2.16C9.10714 1.9737 8.81841 1.87303 8.52125 1.87503H6.47875C5.86125 1.87503 5.32937 2.30315 5.20812 2.89753C5.1275 3.2944 4.77312 3.60065 4.36125 3.60065C3.49875 3.63815 2.935 3.7444 2.48437 4.0394C2.18226 4.23757 1.92211 4.49326 1.71875 4.7919C1.25 5.4794 1.25 6.4369 1.25 8.35253C1.25 10.2675 1.25 11.225 1.71813 11.9132C1.92062 12.2107 2.18062 12.4663 2.48437 12.6657C3.185 13.125 4.16062 13.125 6.11125 13.125ZM7.5 5.79565C6.06187 5.79565 4.89562 6.94003 4.89562 8.3519C4.89562 9.7644 6.06187 10.9088 7.5 10.9088C8.93812 10.9088 10.1044 9.7644 10.1044 8.35253C10.1044 6.94003 8.93812 5.79565 7.5 5.79565ZM7.5 6.81815C6.6375 6.81815 5.9375 7.50503 5.9375 8.35253C5.9375 9.1994 6.6375 9.88628 7.5 9.88628C8.3625 9.88628 9.0625 9.1994 9.0625 8.35253C9.0625 7.50503 8.3625 6.81815 7.5 6.81815ZM10.4512 6.3069C10.4512 6.0244 10.6844 5.79565 10.9725 5.79565H11.6662C11.9537 5.79565 12.1875 6.0244 12.1875 6.3069C12.1862 6.44371 12.1306 6.57441 12.033 6.67027C11.9354 6.76613 11.8037 6.81932 11.6669 6.81815H10.9725C10.9047 6.81881 10.8374 6.80611 10.7746 6.78078C10.7117 6.75544 10.6544 6.71797 10.606 6.67049C10.5576 6.62302 10.519 6.56647 10.4925 6.50409C10.4659 6.4417 10.4519 6.3747 10.4512 6.3069Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : field.type === "phone" ? (
                    <PhoneInput
                      enableAreaCodes={true}
                      inputProps={{
                        name: field.name,
                        onBlur: handleBlur,
                      }}
                      value={values[field.name]}
                      onChange={(phone, country) => {
                        setFieldValue(field.name, phone);
                        setFieldValue("country_code", country.dialCode);
                      }}
                      onBlur={handleBlur}
                      // className={`phone-input ${errors[field.name] && 'is-invalid'}`}
                    />
                  ) : field.type === "select" ? (
                    <Select
                      name={field.name}
                      options={field.options} // Replace with your options data
                      onChange={(selectedOption) => {
                        if (field.name == "slug") {
                          onFieldChange(field.name, selectedOption);
                        }
                        setFieldValue(field.name, selectedOption.value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Select an option"
                      // value={values[field.name]}
                      value={field.options.find(
                        (option) => option.value === values[field.name]
                      )} // Find the
                      className={`select-control ${
                        errors[field.name] && ValidationError !== false
                          ? "is-invalid"
                          : ""
                      } }`}
                      isDisabled={selectDisable}
                    />
                  ) : field.type === "dynamic_select" ? (
                    <Select
                      name={field.name}
                      options={field.options} // Replace with your options data
                      onChange={(selectedOption) => {
                        if (field.name == "sub_cat_id" || "cat_id") {
                          onFieldChange(field.name, selectedOption.value);
                        }
                        setFieldValue(field.name, selectedOption.value);
                        !field.disableNavigate &&
                          navigateToAnotherPage(selectedOption.value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Select an option"
                      // value={values[field.name]}
                      value={field.options.find(
                        (option) => option.value === values[field.name]
                      )} // Find the
                      className={`select-control ${
                        errors[field.name] && ValidationError !== false
                          ? "is-invalid"
                          : ""
                      } }`}
                      isDisabled={selectDisable}
                    />
                  ) : field.type === "ckeditor" ? (
                    <CKEditorField
                      field={field.name}
                      setFieldValue={setFieldValue}
                      value={values[field.name] || ""}
                      errors={errors}
                      disabled={selectDisable}
                    />
                  ) : field.type === "textarea" ? (
                    <>
                      <textarea
                        type={field.type}
                        className={`form-control ${
                          errors[field.name] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } }`}
                        name={field.name}
                        onChange={(e) => onChange(field.name, e)}
                        placeholder={field.placeholder}
                        onBlur={handleBlur}
                        value={values[field.name]}
                        style={{ height: "150px" }}
                        disabled={selectDisable}
                      />
                    </>
                  ) : field.type === "text" ? (
                    <>
                      <input
                        type={field.type}
                        className={`form-control ${
                          errors[field.name] && ValidationError !== false
                            ? "is-invalid"
                            : ""
                        } `}
                        name={field.name}
                        onChange={(e) => onChange(field.name, e)}
                        placeholder={field.placeholder}
                        onBlur={handleBlur}
                        value={values[field.name]}
                        disabled={selectDisable}
                      />
                    </>
                  ) : field.type === "date" ? (
                    <input
                      type={field.type}
                      className={`form-control ${
                        errors[field.name] && ValidationError !== false
                          ? "is-invalid"
                          : ""
                      } }`}
                      name={field.name}
                      onChange={(e) => onChange(field.name, e)}
                      placeholder={field.placeholder}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      disabled={selectDisable}
                      // max="03/11/2023"
                      min={todayDate}
                    />
                  ) : field.type === "radio" && field.name === "status" ? (
                    <div className="row">
                      <div className="form-group col-md-6">
                        <div className="row">
                          {field.options.map((option, index) => (
                            <div className="form-group col-md-6" key={index}>
                              <input
                                type="radio"
                                className="form-radio"
                                name={field.name}
                                onChange={() =>
                                  setFieldValue(field.name, option.value)
                                }
                                value={option.value}
                                checked={
                                  values[field.name] === option.value ||
                                  (option.value === "active" &&
                                    !values[field.name])
                                }
                              />
                              <label>&nbsp;{option.label}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <input
                      type={field.type}
                      className={`form-control ${
                        errors[field.name] && ValidationError !== false
                          ? "is-invalid"
                          : ""
                      } }`}
                      name={field.name}
                      onChange={(e) => onChange(field.name, e)}
                      placeholder={field.placeholder}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      disabled={selectDisable}
                      onWheel={(e) => e.target.blur()}
                    />
                  )}
                  {errors[field.name] && touched[field.name] && (
                    <p className="invalid-feedback">{errors[field.name]}</p>
                  )}
                </div>
              ))}
            </div>
            {buttonText !== false ? (
              <div className="form-group button-submit">
                <LoadingButton
                  type="submit"
                  className={`btn btn-primary btn-lg ${
                    is_block ? "btn-block" : ""
                  }`}
                  loading={loading}
                  disabled={isSubmitting}
                >
                  {!loading ? (
                    <>
                      {/* <i className="fa fa-sign-in fa-lg fa-fw"></i> */}
                      {isSubmitting ? "Wait..." : buttonText}
                    </>
                  ) : (
                    ""
                  )}
                </LoadingButton>
              </div>
            ) : (
              <Link to={backPath}>
                <div className="form-group button-submit">
                  <LoadingButton
                    type="submit"
                    className={`btn btn-primary btn-lg ${
                      is_block ? "btn-block" : ""
                    }`}
                    loading={loading}
                    disabled={submitting}
                  >
                    Back
                  </LoadingButton>
                </div>
              </Link>
            )}
          </form>
        )}}
      </Formik>
    </>
  );
};

export default FormikForm;