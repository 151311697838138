//action types
export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export const VIEW_SUBCATEGORY = 'VIEW_SUBCATEGORY';
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';
export const CATEGORY_SUBCATEGORY = 'CATEGORY_SUBCATEGORY';

//get 
export function getSubCategory(data, callback) {
  return {
    type: GET_SUBCATEGORY,
    payload : {data, callback},
  };
}
//add 
export function addSubCategory(data, callback) {
  return {
    type: ADD_SUBCATEGORY,
    payload : {data, callback},
  };
}

//view 
export function viewSubCategory(data, callback) {
  return {
    type: VIEW_SUBCATEGORY,
    payload : {data, callback},
  };
}
//update 
export function updateSubCategory(data, callback) {
    return {
      type: UPDATE_SUBCATEGORY,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteSubCategory(payload) {
  return {
    type: DELETE_SUBCATEGORY,
    payload
  };
}
//get 
export function getSubcategoryCategory(data, callback) {
  return {
    type: CATEGORY_SUBCATEGORY,
    payload : {data, callback},
  };
}
