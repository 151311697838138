import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
//imported
import {
  getSystemAccessList,
  deleteSystemAccessData,
} from "store/services/systemAccessService";
import { SYSTEM_ACCESS, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";

const List = ({
  dispatch,
  systemAccessList,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  //get category detail
  async function getData() {
    try {
      let query = {
        page: activePage,
        limit: PAGE_LIMIT,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getSystemAccessList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [activePage]);

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteSystemAccessData(id)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={SYSTEM_ACCESS.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{SYSTEM_ACCESS.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.system_access_add} className="btn btn-primary">
              Add New
            </Link>
          </div>
          <div className="section-header-breadcrumb">
           
            <div className="breadcrumb-item">
              {SYSTEM_ACCESS.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{SYSTEM_ACCESS.CURRENT_MODULE}</h2>
          <p className="section-lead">
            This section allows you to add team members, granting them access to the Admin Panel. You have the flexibility to edit their details as needed and delete users to revoke their access.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{SYSTEM_ACCESS.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {systemAccessList.length > 0 &&
                        systemAccessList.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{i+1}</td>
                              <td>
                                {item?.first_name
                                  ? FORMAT_TEXT(item?.first_name)
                                  : "N/A"}
                              </td>
                              <td>
                                {item?.last_name
                                  ? FORMAT_TEXT(item?.last_name)
                                  : "N/A"}
                              </td>
                              <td>
                                {item?.last_name
                                  ? FORMAT_TEXT(item?.email)
                                  : "N/A"}
                              </td>
                              <td>{DATE_FORMAT(item.created_at)}</td>
                              <td>
                                <div
                                  className={`badge text-white badge-${item.status == "active"
                                      ? "primary"
                                      : "danger"
                                    }`}
                                >
                                  {item.status}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-10 tableBtn">
                                  <Link
                                    to={`${Path.system_accessview}/${item.id}`}
                                    title="View"
                                  >
                                    <i className="fa fa-lg fa-eye"></i>
                                  </Link>
                                  <Link
                                    to={`${Path.system_accessedit}/${item.id}`}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link>
                                  <span
                                    className="text-danger"
                                    onClick={() => deleteItem_(item.id)}
                                  >
                                    <i
                                      className="fa fa-lg fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  {systemAccessList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    systemAccessList: state.SystemAccess.system_accesses,
    itemsCountPerPage: state.SystemAccess.itemsCountPerPage,
    totalItemsCount: state.SystemAccess.totalItemsCount,
    loader: state.SystemAccess.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
