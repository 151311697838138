//action types
export const ADD_CONFIGURATION = 'ADD_CONFIGURATION';
export const VIEW_CONFIGURATION = 'VIEW_CONFIGURATION';
export const INSTANCE_TYPE_LIST = 'INSTANCE_TYPE_LIST';

//add 
export function addConfiguration(data, callback) {
  return {
    type: ADD_CONFIGURATION,
    payload : {data, callback},
  };
}

//view 
export function viewConfiguration(data, callback) {
  return {
    type: VIEW_CONFIGURATION,
    payload : {data, callback},
  };
}

// view instance list 
export function instanceTypesList(data, callback) {
  return {
    type: INSTANCE_TYPE_LIST,
    payload : {data, callback},
  };
}

