import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
//imported
import {
  deletePractitionerData,
  getPractitionerList,
  importCSVFile_,
  importCSVFilePrac_,
  importUpdateCSVFilePrac_
} from "store/services/practitionerService";
import { PRACTITIONER, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT, ERROR_MESSAGE } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT, EXPORT_FORMAT_DATA_USER } from "helpers/common";
import { DUMMY_IMG } from "utils/constants/constant";
import ReactPagination from "components/shared/ReactPagination";
import { Button, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { getData } from "helpers/apiHelper";
import MemberShipPop from "./Modal/MemberShipPop";
import ImportModal from "./ImportModal";
import { deletePractitioner } from "store/actions/practitioner";
import { viewInstancesList } from "store/services/configurationService";
const List = ({
  dispatch,
  practitionerList,
  itemsCountPerPage,
  totalItemsCount,
  instancesList
}) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchFilter, setsearchFilter] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [sending, setSending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  //IMPORT
  const [csvField, setCsvField] = useState({})
  const [typeCsv_, setTypeCsv_] = useState(null)

  //import modal
  const [openModalImport, setOpenModalImport] = useState(false);

  const [query, setQuery] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: searchFilter?.search ? searchFilter.search : "",
    status: searchFilter?.status ? searchFilter.status : "",
    sub_status: searchFilter?.sub_status ? searchFilter.sub_status : "",
    instance_type: searchFilter?.instance_type ? searchFilter.instance_type : "",
  });

  const isFilterApplied = useRef(false);

  useEffect(() => {
  }, [isFilterApplied.current])

  useEffect(() => {
    getApiData(activePage);
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    getApiData(1);
  }, [searchFilter])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setsearchFilter({ ...searchFilter, search: searchTerm });
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  useEffect(() => {
    getInstancesList();
  }, []);

  //get category detail
  async function getApiData(page) {
    // console.log("query", query)
    try {

      let query = {
        page: page,
        limit: PAGE_LIMIT,
        search: searchFilter?.search ? searchFilter.search : "",
        status: searchFilter?.status ? searchFilter.status : "",
        sub_status: searchFilter?.sub_status ? searchFilter.sub_status : "",
        instance_type: searchFilter?.instance_type ? searchFilter.instance_type : "",
      }

      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPractitionerList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(deletePractitionerData(id)).then(() => {
              getApiData();
            });
            dispatch(actions.persist_store({ loader: false }));
          } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            if (err.message) {
              ERROR_MESSAGE(err.message)
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };
  //filter
  const handleSeacrchFilter = (e) => {
    let { name, value } = e.target;
    if (name === "status") {
      if (value == "All") {
        value = null;
      }
    }
    if (name === "sub_status") {
      if (value == "All") {
        value = null;
      }
    }
    if (name === "search") {
      if (value == "") {
        value = null;
      }
    }
    if (name === "instance_type") {
      if (value == "All") {
        value = null;
      }
    }
    setsearchFilter({ ...searchFilter, [name]: value });
  };

  //export csv
  const handleExportClick = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await dispatch(getPractitionerList());
      if (response?.body?.data) {
        let pracData = response?.body?.data;
        let res = await EXPORT_FORMAT_DATA_USER(pracData)
        if (!csvData) {
          setCsvData(res)
        }
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.error("An error occurred:", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  };
  //import csv
  const openModalImport_ = async () => {
    try {
      setOpenModalImport(!openModalImport)
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  //update import export funtion
  const handleSubmitData = async (evt, fields, callback) => {
    try {
      evt.preventDefault();
      let data = {
        preload_sample_data: fields.preloadData ? "yes" : "no",
      };
      setSending(true);
      switch (typeCsv_) {
        case 'student':
          data.file = csvField?.file;
          await dispatch(importCSVFile_(data));
          break;
        case 'practitioner':
          data.file = csvField?.file;
          await dispatch(importCSVFilePrac_(data));
          break;
        case 'practitioner_update':
          console.log("here", typeCsv_);
          data.file = csvField?.file;
          await dispatch(importUpdateCSVFilePrac_(data));
          break;
        default:
          break;
      }
      setSending(false);
      setOpenModalImport(false);
      getApiData();
      typeof callback === "function" && callback();
    } catch (err) {
      console.log(err, "Error occurred");
      setSending(false);
    }
  };

  /**
   * Get instances list
   */
  const getInstancesList = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewInstancesList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }


  return (
    <>
      <Helmet title={PRACTITIONER.CURRENT_MODULE} />
      <ImportModal
        dispatch={dispatch}
        openModalImport={openModalImport}
        setOpenModalImport={setOpenModalImport}
        handleSubmitData={handleSubmitData}
        setCsvField={setCsvField}
        csvField={csvField}
        typeCsv_={typeCsv_}
        setTypeCsv_={setTypeCsv_}
        sending={sending}
      />
      <section className="section">
        <div className="section-header">
          <h1>{PRACTITIONER.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.practitioner_add} className="btn btn-primary">
              Add New
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">{PRACTITIONER.CURRENT_MODULE}</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{PRACTITIONER.CURRENT_MODULE}</h2>
          <p className="section-lead">
            In this section, you can view a list of practitioners/users, allowing you to efficiently manage them by performing actions like adding, viewing, editing, and deleting as needed.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{PRACTITIONER.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText"></div>
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search...."
                          className="form-control"
                          name="search"
                          onChange={(e) => {
                            setSearchTerm(e.target.value)
                          }}
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Status:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          className="form-control"
                          onChange={handleSeacrchFilter}
                        >
                          <option>All</option>
                          <option value="unverified">Unverified</option>
                          <option value="current_member">
                            Current Member{" "}
                          </option>
                          <option value="cancelling_member">
                            Cancelling Member{" "}
                          </option>
                          <option value="non_member">Non Member </option>
                          <option value="student">Student </option>
                          {/* <option value="active">Active</option> */}
                        </Form.Select>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Sub Status:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sub_status"
                          className="form-control"
                          onChange={handleSeacrchFilter}
                        >
                          <option>All</option>
                          <option value="expiring_soon">Expiring Soon </option>
                          <option value="expired">Expired </option>
                          {/* <option value="trail">Trail </option> */}
                        </Form.Select>
                      </div>
                      {instancesList?.length > 0 && (
                        <div className="">
                          <label
                            htmlFor=""
                            className="form-label fw-sbold m-0 me-2 ws-nowrap"
                          >
                            Filter By Instance Type:
                          </label>
                          <Form.Select
                            aria-label="Default select example"
                            name="instance_type"
                            className="form-control"
                            onChange={handleSeacrchFilter}
                          >
                            <option>All</option>
                            {instancesList?.map((instance, i) => (
                              <option value={instance.id} key={i}>{instance.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                      )}
                    </div>
                    <div className="right d-flex align-items-center gap-10">
                      <Button className="d-flex align-items-center justify-content-center commonBtn" onClick={() => openModalImport_()}>
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M3 9.75V14.25C3 14.6478 3.15804 15.0294 3.43934 15.3107C3.72064 15.592 4.10218 15.75 4.5 15.75H13.5C13.8978 15.75 14.2794 15.592 14.5607 15.3107C14.842 15.0294 15 14.6478 15 14.25V9.75M9 2.25V11.25M9 11.25L6.375 8.625M9 11.25L11.625 8.625"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        Import
                      </Button>
                      {/* <Button
                        onClick={handleExportClick}
                        className="d-flex align-items-center justify-content-center commonBtn"
                      >
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 7.87495V14.625C15.1875 14.9233 15.069 15.2095 14.858 15.4204C14.647 15.6314 14.3609 15.75 14.0625 15.75H3.9375C3.63913 15.75 3.35298 15.6314 3.142 15.4204C2.93103 15.2095 2.8125 14.9233 2.8125 14.625V7.87495C2.8125 7.57659 2.93103 7.29044 3.142 7.07946C3.35298 6.86848 3.63913 6.74995 3.9375 6.74995H5.625C5.77418 6.74995 5.91726 6.80922 6.02275 6.91471C6.12824 7.0202 6.1875 7.16327 6.1875 7.31245C6.1875 7.46164 6.12824 7.60471 6.02275 7.7102C5.91726 7.81569 5.77418 7.87495 5.625 7.87495H3.9375V14.625H14.0625V7.87495H12.375C12.2258 7.87495 12.0827 7.81569 11.9773 7.7102C11.8718 7.60471 11.8125 7.46164 11.8125 7.31245C11.8125 7.16327 11.8718 7.0202 11.9773 6.91471C12.0827 6.80922 12.2258 6.74995 12.375 6.74995H14.0625C14.3609 6.74995 14.647 6.86848 14.858 7.07946C15.069 7.29044 15.1875 7.57659 15.1875 7.87495ZM6.58547 4.89792L8.4375 3.04519V9.56245C8.4375 9.71164 8.49676 9.85471 8.60225 9.9602C8.70774 10.0657 8.85082 10.125 9 10.125C9.14918 10.125 9.29226 10.0657 9.39775 9.9602C9.50324 9.85471 9.5625 9.71164 9.5625 9.56245V3.04519L11.4145 4.89792C11.5201 5.00347 11.6632 5.06277 11.8125 5.06277C11.9618 5.06277 12.1049 5.00347 12.2105 4.89792C12.316 4.79238 12.3753 4.64922 12.3753 4.49995C12.3753 4.35069 12.316 4.20753 12.2105 4.10199L9.39797 1.28949C9.34573 1.23719 9.28369 1.1957 9.2154 1.16739C9.14712 1.13908 9.07392 1.12451 9 1.12451C8.92608 1.12451 8.85288 1.13908 8.7846 1.16739C8.71631 1.1957 8.65427 1.23719 8.60203 1.28949L5.78953 4.10199C5.68398 4.20753 5.62469 4.35069 5.62469 4.49995C5.62469 4.64922 5.68398 4.79237 5.78953 4.89792C5.89508 5.00347 6.03823 5.06277 6.1875 5.06277C6.33677 5.06277 6.47992 5.00347 6.58547 4.89792Z"
                              fill="white"
                            />
                          </svg>
                        </span>{" "}
                        Export
                      </Button> */}
                      {csvData && (
                        <CSVLink
                          onClick={() => setCsvData(null)}
                          data={csvData}
                          filename="Practitioner.csv"
                          className="btn btn-primary"
                        >
                          Download
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Display Name</th>
                        <th>Email</th>
                        <th>Subscription Plan</th>
                        <th>Created At</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th>Account Id</th>
                        <th>Instance Type</th>
                        <th>Action</th>
                      </tr>
                      {practitionerList.length > 0 ? (
                        <>
                          {practitionerList.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  {item?.first_name
                                    ? FORMAT_TEXT(item?.first_name)
                                    : "N/A"}{" "}
                                  {item?.last_name
                                    ? FORMAT_TEXT(item?.last_name)
                                    : "N/A"}
                                </td>
                                <td>
                                  {" "}
                                  {item?.display_name
                                    ? FORMAT_TEXT(item?.display_name)
                                    : "N/A"}
                                </td>
                                <td>
                                  {item?.email ? FORMAT_TEXT(item?.email) : "N/A"}
                                </td>
                                <td>
                                  <div>
                                    {item?.package_name
                                      ? item.package_name
                                      : "N/A"}
                                  </div>
                                </td>
                                <td>{DATE_FORMAT(item.created_at)}</td>
                                <td>
                                  {item.updated_at
                                    ? DATE_FORMAT(item.updated_at)
                                    : "N/A"}
                                </td>
                                <td>
                                  <div
                                    className={`badge text-white badge-${item.nta_status === "pending"
                                      ? "danger px-3"
                                      : "primary px-3"
                                      }`}
                                  >

                                    {(item.nta_status === "approve" && item.nta_membership_type === 'renewable') ?
                                      "Current Member" : (item.nta_status === 'pending' ? 'Unverified' : item.nta_status === "reject" ? 'Non Member' : (item.nta_status === "approve" && item.nta_membership_type === 'non_renewable') ? 'Cancelling Member' : item.is_free_account === "yes" ? 'Student' : 'Non Member')
                                    }

                                    {/* 
                                  {item.nta_status &&
                                    item.nta_status === "approved"
                                    ? "Currenet Member"
                                    : item.nta_status &&
                                      item.nta_status === "pending"
                                      ? "Unverified"
                                      : item.nta_status &&
                                        item.nta_status === "reject"
                                        ? "Non Member"
                                        : "Non Member"} */}
                                  </div>
                                </td>
                                <td>
                                  {item?.uuid ? FORMAT_TEXT(item?.uuid) : "N/A"}
                                </td>
                                <td>
                                  {item?.instance_type_name}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-10 tableBtn">
                                    <Link
                                      to={`${Path.practitionerview}/${item.id}`}
                                      title="View"
                                    >
                                      {/* <i className="fa fa-lg fa-eye"></i> */}
                                      <i className="fa fa-lg fa-edit"></i>
                                    </Link>
                                    {/* <Link
                                    to={`${Path.practitioneredit}/${item.id}`}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link> */}
                                    <span className="text-danger" onClick={() => deleteItem_(item.id)}><i className="fa fa-lg fa-trash" title="Delete"></i></span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </>

                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">Data not found</td>
                        </tr>
                      )}

                    </table>
                  </div>
                  {practitionerList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    practitionerList: state.Practitioner.practitioners,
    itemsCountPerPage: state.Practitioner.itemsCountPerPage,
    totalItemsCount: state.Practitioner.totalItemsCount,
    instancesList: state.Configuration.instanceTypeList,
    loader: state.Practitioner.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
