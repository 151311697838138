import { GET_BASIC_SETTINGS } from "store/actions/app";

const initialState = {
  basicSettings: {},
};

// set basic settings state
const getBasicSettings = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    basicSettings: data?.body,
  };
  return stateObj;
}

const App = (state = initialState, { type, payload = null }) => {
    switch (type) {
        
        case GET_BASIC_SETTINGS:
          return getBasicSettings(state, payload);

        default:
        return state;
    };
}
export default App;