import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, useHistory} from 'react-router-dom';

//imported
import { FAQ } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { addFAQData, viewFAQData, updateFAQData } from 'store/services/faqService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const AddEdit = ({submitting, dispatch, match, loader}) => {
    const history = useHistory();
    let editkey = match.params.id;
    const [faqDetail, setFaqDetail] = useState({});
 
    const initialValues = {
        question: faqDetail ? faqDetail.question : "",
        answer: faqDetail ? faqDetail.answer :  "",
        status: faqDetail ? faqDetail.status : "active",
    };
   //get detail
    async function getData() {
        try{
            dispatch(actions.persist_store({ loader:true}));
            let res = await dispatch(viewFAQData(editkey))
            setFaqDetail(res.body)
            dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
            dispatch(actions.persist_store({ loader:false}));
            console.log(err);
        }
    }
    useEffect(() => {
        if(editkey){
            getData();
        }
    }, []);

    // add edit
    const updateProfile_ = async (values, {setSubmitting}) => {
        try{
            const callback = (response) => {
                const { data } = response;
                if(data.success == true){
                    history.push(Path.faq)
                }
            }

            if (editkey) {
                values.id = editkey;
            }
            values.status = values.status ? values.status : 'active'
            values = JSON.stringify(values)
            setSubmitting(true)
            dispatch(actions.persist_store({ loader: true }));
            if (editkey) {
                await dispatch(updateFAQData(values, callback));
              } else {
                await dispatch(addFAQData(values, callback));
            }
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
            
        }catch(err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    }
    
    return(
        <>
            <Helmet title={FAQ.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{FAQ.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                       
                        <div className="breadcrumb-item"><Link to={Path.faq}>FAQ</Link></div>
                        <div className="breadcrumb-item">Add FAQ</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                    <div className="section-body">
                    <h2 className="section-title">Add New FAQ</h2>
                    <p className="section-lead">
                        On this page you can create a new faq and fill in all fields.
                    </p>
                        <div className="row mt-sm-4">
                            <div className="col-8 col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <FormikForm  
                                            initialValues={initialValues} 
                                            validationSchema={Validation} 
                                            onSubmit={updateProfile_}
                                            loading={loader}
                                            inputFields={InputFields}
                                            submitting={submitting}
                                            buttonText="Save Changes"
                                            is_block={false}
                                            col={12}
                                            selectDisable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return{
        faqDetail : state.Faq.faq,
        loader:state.Faq.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);