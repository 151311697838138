const inputFields = [
  {
    name: 'slug',
    type: 'select',
    label: 'Slug*',
    placeholder: 'Select',
    options: [
      {
        label: 'Privacy Policy',
        value: 'privacy_policy',
        url: "https://nutritional-practitioner.suffescom.dev/privacy-policy"
      },
      {
        label: 'About Us',
        value: 'about_us',
        url: "",
      },
      {
        label: 'Terms & Conditions',
        value: 'terms_conditions',
        url: "https://nutritional-practitioner.suffescom.dev/terms-conditions"
      },
      {
        label: 'Onboarding',
        value: 'onboarding',
        url: "https://nutritional-practitioner.suffescom.dev/dashboard/terms-condition"
      },
    ]
  },
  {
    name: 'title',
    type: 'text',
    label: 'Title*',
    placeholder: 'Title',
    selectDisable: false
  },
  {
    name: 'description',
    type: 'ckeditor',
    label: 'Description*',
    placeholder: 'Description',

  },
  {
    name: 'status',
    type: 'radio',
    label: 'Status*',
    options: [
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Inactive',
        value: 'inactive'
      },
    ]
  },
];

export default inputFields;