const inputFields = [
    {
      name: 'question',
      type: 'textarea',
      label: 'Question*',
      placeholder: 'Question',
    },
    {
      name: 'answer',
      type: 'textarea',
      label: 'Answer*',
      placeholder: 'Answer',
    },
    {
      name: 'status',
      type: 'radio',
      label: 'Status*',
      // placeholder: 'Select',
      options : [
        {
          label: 'Active',
          value:'active'
        },
        {
          label: 'Inactive',
          value:'inactive'
        },
      ]
    },
];

export default inputFields;