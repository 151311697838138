import { GET_ANNOUNCEMENTS } from "store/actions/announcements";

const initialState = {
    allData: [],
    singleItem: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getData = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        allData: data.body.data,
        itemsCountPerPage: data.body.per_page,
        totalItemsCount: data.body.total_count
    };
  return stateObj;
};

const Announcement = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_ANNOUNCEMENTS:
          return getData(state, payload);
        default:
        return state;
    };
}
export default Announcement;