import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { putData } from "helpers/apiHelper";
import * as Path from "routes/paths";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";

const validationSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First Name is required'),
    last_name: Yup.string()
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    // status: Yup.string()
    //     .oneOf(['active', 'inactive'], 'Invalid status')
    //     .required('Status is required'),
});


const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};


export default function EditUser({ showEditUser, setShowEditUser, editkey, practitionerDetail }) {
    const history = useHistory();
    const todayDate = moment().format('YYYY-MM-DD')
    const handleEditUser = () => setShowEditUser(!showEditUser);
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [initialValues, setInitialValues] = useState();
    
    useEffect(() => {
        if (practitionerDetail) {
            setInitialValues(practitionerDetail);
        }

    }, [practitionerDetail])

    const handlechange = (event) => {
        const { name, value } = event.target
        setInitialValues({ ...initialValues, [name]: value });
        setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    const DataSave = async (event) => {
        event.preventDefault();
        const validation = await validateData(initialValues);
        if (validation.isValid) {
            let data = {
                id: editkey,
                first_name: initialValues.first_name,
                last_name: initialValues.last_name,
                email: initialValues.email,
                // gender: initialValues.gender,
                // phone: phoneNumberWithoutPlus,
                phone: initialValues.phone || "",
                country_code: initialValues.country_code || "",
                status: initialValues.status || "active",
                is_free_account: initialValues?.is_free_account || "no",
            }

            if (initialValues?.is_free_account === "yes") {
                data.free_account_expiry = initialValues?.free_account_expiry || "";
            }

            try {
                setSending(true)
                const url = "/admin/practitioner/edit/"
                await putData(url, data)
                handleEditUser()
                setSending(false)
                window.location.reload()
            } catch (error) {
                setSending(false)
                console.log(error)
            }
        } else {
            setValidationErrors(validation.errors);
        }
    }
    return (
        <>
            <Modal
                show={showEditUser}
                onHide={handleEditUser}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {initialValues ? <Row>
                            <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                    First Name*
                                </label>
                                <input type="text" className="form-control" onChange={handlechange} name="first_name" value={initialValues.first_name} />
                                {validationErrors.first_name && (
                                    <div className="text-danger">{validationErrors.first_name}</div>
                                )}
                            </Col>
                            <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                    Last Name*
                                </label>
                                <input type="text" className="form-control" onChange={handlechange} name="last_name" value={initialValues.last_name} />
                                {validationErrors.last_name && (
                                    <div className="text-danger">{validationErrors.last_name}</div>
                                )}
                            </Col>
                            <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                    Email*
                                </label>
                                <input type="text" className="form-control" onChange={handlechange} name="email" value={initialValues.email} />
                                {validationErrors.email && (
                                    <div className="text-danger">{validationErrors.email}</div>
                                )}
                            </Col>
                            {/* <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                Membership Id
                                </label>
                                <input type="text" className="form-control" onChange={handlechange} name="membership_id" value={initialValues.membership_id} />
                                {validationErrors.membership_id && (
                                    <div className="text-danger">{validationErrors.membership_id}</div>
                                )}
                            </Col> */}

                            {/* <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                Membership Expiration date
                                </label>
                                <input type="date" className="form-control" onChange={handlechange} name="membership_expiration_date" value={initialValues.membership_expiration_date} 
                                min={todayDate}
                                />
                                {validationErrors.membership_expiration_date && (
                                    <div className="text-danger">{validationErrors.membership_expiration_date}</div>
                                )}
                            </Col>
                            
                            <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                Membership Status
                                </label>
                                <select name="membership_status" id="" value={initialValues.membership_status} onChange={handlechange} className="form-select" >
                                    <option value="">Select status</option>
                                    <option value="unverified">Unverified</option>
                                    <option value="current_member">Current member</option>
                                    <option value="cancelling_member">Cancelling Member</option>
                                    <option value="non_member">Non Member</option>

                                </select>
                                {validationErrors.membership_status && (
                                    <div className="text-danger">{validationErrors.membership_status}</div>
                                )}
                            </Col> */}
                            <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                    Phone Number
                                </label>
                                <PhoneInput
                                    enableAreaCodes={true}
                                    inputProps={{
                                        name: "phone",
                                        // onBlur: handleBlur,
                                    }}
                                    // country={'us'} 
                                    // value={initialValues.country_code+''+initialValues.phone} // for the PhoneInput
                                    value={`${practitionerDetail?.country_code ? `${practitionerDetail.country_code}${practitionerDetail.phone}` : ""}`}
                                    onChange={(phone, country) => {
                                        console.log(phone, country)
                                        let countryCode = country.dialCode;
                                        phone = phone.replace(countryCode, "");
                                        setInitialValues(prevState => ({
                                            ...prevState,
                                            phone: phone,
                                            country_code: countryCode,
                                        }));
                                    }}
                                    maxLength={15}
                                />
                            </Col>

                            <Col lg="12" className="my-2">
                                <label htmlFor="" className="form-label m-0 fw-sbold">
                                    Status*
                                </label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            type="radio"
                                            className="form-radio"
                                            name="status"
                                            onChange={handlechange}
                                            value="active"
                                            checked={initialValues.status === 'active' ? true : false}
                                        />
                                        <label>&nbsp;Active</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="radio"
                                            className="form-radio"
                                            name="status"
                                            onChange={handlechange}
                                            value="inactive"
                                            checked={initialValues.status === 'inactive' ? true : false}
                                        />
                                        <label>&nbsp;Inactive</label>
                                    </div>
                                </div>
                                {validationErrors.status && (
                                    <div className="text-danger">{validationErrors.status}</div>
                                )}
                            </Col>
                            {practitionerDetail?.is_plan_active === "no" && (
                                <Col lg="12" className="my-2">
                                    <label htmlFor="" className="form-label m-0 fw-sbold">Account Type</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                name="is_free_account"
                                                onChange={handlechange}
                                                value="yes"
                                                checked={initialValues.is_free_account === 'yes' ? true : false}
                                            />
                                            <label>&nbsp;Student</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                name="is_free_account"
                                                onChange={handlechange}
                                                value="no"
                                                checked={initialValues.is_free_account === 'no' ? true : false}
                                            />
                                            <label>&nbsp;Practitioner</label>
                                        </div>
                                    </div>
                                </Col>
                            )}
                            {(initialValues?.is_free_account != 'no' || initialValues?.is_free_account === 'yes') &&
                                <Col lg="12" className="my-2">
                                    <label htmlFor="" className="form-label m-0 fw-sbold">Expired Free Account Date*</label>
                                    <input
                                        type="date"
                                        className="form-control w-50"
                                        name="free_account_expiry"
                                        onChange={handlechange}
                                        value={initialValues.free_account_expiry}
                                        min={todayDate}
                                        required={initialValues.is_free_account ? true : false}
                                        autoComplete="off"
                                    />
                                </Col>
                            }
                        </Row> : ""}

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={handleEditUser}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={DataSave} disabled={sending ? true : false}>
                        {sending ? 'Wait...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
