import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { EMAIL_LOGS } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { DATE_FORMAT, debounce, emailStatusColor, FORMAT_TEXT, formatText } from "helpers/common";
import { deleteAnnouncement } from "store/services/announcementService";
import ReactPagination from "components/shared/ReactPagination";
import { CONFIRM_ALERT } from "common/constantMessage";
import { Button, Form } from "react-bootstrap";
import { getEmailLogs } from "store/services/emaillogsService";
import SortableTable from "components/shared/SortableTable";
import moment from "moment";

const List = ({
  dispatch,
  listing,
  itemsCountPerPage,
  totalItemsCount
}) => {

  const limit = 10;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    field: "updated_at",
    direction: "desc"
  })
  const [filter, setFilter] = useState({
    status: "",
    search: "",
    role: ""
  });

  useEffect(() => {
    resetAndFetch();
  }, [filter, sort]);

  const resetAndFetch = () => {
    setPage(1);
    fetchData(1);
  };

  const handleSort = (sortBy, sortOrder) => {
    setSort({
      field: sortBy,
      direction: sortOrder
    })
  }

  const fetchData = async (pageToFetch) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let payload = {
        limit,
        page: pageToFetch,
        role: filter.role,
        search: filter.search,
        status: filter.status,
        sort_field: sort.field,
        sort_direction: sort.direction,
      };
      await dispatch(getEmailLogs(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching appointments:", err);
    }
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    fetchData(nextPage);
  }

  const handleChangeFilter = (key) => (e) => {
    const value = e.target.value;
    setFilter((prevValues) => ({
      ...prevValues,
      [key]: value
    }))
  }

  const debounceHandleSearch = useCallback(debounce((e) => {
    handleChangeFilter("search")(e)
  }, 500), []);

  const columns = useMemo(() => {
    return [
      {
        key: "first_name",
        label: "User First Name",
        sortable: false
      },
      {
        key: "last_name",
        label: "User Last Name",
        sortable: false
      },
      {
        key: "event",
        label: "Event",
        sortable: true,
      },
      {
        key: "role",
        label: "Panel",
        sortable: false,
        render: (value) => (
          value === 3 ? (
            "Practitioner Panel"
          ):value === 4 ? (
            "Client Panel"
          ):(
            ""
          )
        ),
      },
      {
        key: "updated_at",
        label: "Updated At",
        sortable: true,
        render: (value) => {
          return moment(value).format("MMM D, YYYY hh:mm A");
        },
      },
      {
        key: "status",
        label: "Status",
        sortable: true,
        render: (value) => {
          return (
            <span style={{ color: emailStatusColor(value) }} className="fw-bold">{formatText(value)}</span>
          )
        },
      }
    ]
  }, [])

  return (
    <>
      <Helmet title={EMAIL_LOGS.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{EMAIL_LOGS.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            
            <div className="breadcrumb-item">Email Logs</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{EMAIL_LOGS.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can view the email logs for Practitioner/Client panel here.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{EMAIL_LOGS.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText"></div>
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search...."
                          className="form-control"
                          name="search"
                          onChange={debounceHandleSearch}
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Status:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sub_status"
                          className="form-control pe-5"
                          onChange={handleChangeFilter("status")}
                          value={filter.status}
                        >
                          <option value="">All</option>
                          <option value="sent">Sent</option>
                          <option value="delivered">Delivered</option>
                          <option value="bounced">Bounced</option>
                          <option value="delivery_delayed">Delivery Delayed</option>
                          <option value="complaint">Complaint</option>
                          {/* <option value="trail">Trail </option> */}
                        </Form.Select>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Panel:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sub_status"
                          className="form-control pe-5"
                          onChange={handleChangeFilter("role")}
                          value={filter.role}
                        >
                          <option value="">All</option>
                          <option value="3">Practitioner Panel</option>
                          <option value="4">Client Panel</option>
                          {/* <option value="trail">Trail </option> */}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <SortableTable
                      columns={columns}
                      data={listing}
                      onSort={handleSort}
                      sortColumn={sort.field}
                      sortOrder={sort.direction}
                    />
                  </div>
                  {listing?.length > 0 && (
                    <ReactPagination
                      activePage={page}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    listing: state.EmailLog.data,
    itemsCountPerPage: state.EmailLog.itemsCountPerPage,
    totalItemsCount: state.EmailLog.totalItemsCount,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
