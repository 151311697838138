//action types
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const VIEW_SUBSCRIPTION = 'VIEW_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const GET_SUBSCRIPTION_HISTORY = 'GET_SUBSCRIPTION_HISTORY';

//get 
export function getSubscription(data, callback) {
  return {
    type: GET_SUBSCRIPTION,
    payload : {data, callback},
  };
}
//add 
export function addSubscription(data, callback) {
  return {
    type: ADD_SUBSCRIPTION,
    payload : {data, callback},
  };
}

//view 
export function viewSubscription(data, callback) {
  return {
    type: VIEW_SUBSCRIPTION,
    payload : {data, callback},
  };
}
//update 
export function updateSubscription(data, callback) {
    return {
      type: UPDATE_SUBSCRIPTION,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteSubscription(payload) {
  return {
    type: DELETE_SUBSCRIPTION,
    payload
  };
}

//get history
export function getSubscriptionHistory(data, callback) {
  return {
    type: GET_SUBSCRIPTION_HISTORY,
    payload : {data, callback},
  };
}