import { fileToBase64 } from "helpers/common";

class CustomUploadAdapter {
	constructor(loader, url, callback) {
		this.loader = loader;
		this.url = url;
		this.callback = callback;
	}

	upload() {
		return this.loader.file
			.then(file => new Promise((resolve, reject) => {
				this._initRequest();
				this._initListeners(resolve, reject, file);
				this._sendRequest(file);
			}));
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const accessToken = localStorage.getItem('access_token');
		console.log("accessToken", accessToken)
		if (!accessToken) {
			this.abort();
		}
		this.xhr = new XMLHttpRequest();
		this.xhr.open('POST', this.url, true);
		this.xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`)
		this.xhr.responseType = 'json';
	}

	_initListeners(resolve, reject, file) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = `Couldn't upload file: ${file.name}.`;
		xhr.addEventListener('error', () => reject(genericErrorText));
		xhr.addEventListener('abort', () => reject());
		xhr.addEventListener('load', () => {
			const response = xhr.response;

			if (!response || response.success !== true || !response?.body?.url) {
				return reject(response && response.message ? response.message : genericErrorText);
			}

			resolve({
				default: response?.body?.url
			});

			// Call the custom callback function after a successful upload
			typeof this.callback === "function" && this.callback(response);
		});

		if (xhr.upload) {
			xhr.upload.addEventListener('progress', evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	_sendRequest(file) {
		console.log(file);
		fileToBase64(file)
			.then((data) => {
				const payload = {
					file: data,
					extension: "." + file.name.split('.').pop(),
					file_type: file.type,
					folder: "editor_content",
				}
				this.xhr.send(JSON.stringify(payload));
			})
			.catch((err) => {
				console.log("Failed to upload file", err);
			});
	}
}

export default CustomUploadAdapter;