import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

//imported
import { DELETE_ALERT } from "common/constantMessage";
import { EARNING, PAGE_LIMIT } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { Button, Form } from "react-bootstrap";

const List = ({ dispatch }) => {
  return (
    <>
      <Helmet title={EARNING.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{EARNING.CURRENT_MODULE}</h1>
          {/* <div className="section-header-button">
                        <Link to={Path.subscription_history}  className="btn btn-primary">Subscription History</Link>
                    </div> */}
          <div className="section-header-breadcrumb">
           
            <div className="breadcrumb-item">Transaction Earning</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{EARNING.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can see Transaction earning listing here.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{EARNING.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-center gap-10">
                      <div className="d-flex align-items-center">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By:
                        </label>
                        <Form.Select aria-label="Default select example">
                          <option>All</option>
                          <option value="1">Approved </option>
                          <option value="2">Rejected </option>
                          <option value="3">Blocked</option>
                        </Form.Select>
                      </div>
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search...."
                          className="form-control"
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                    <div className="right d-flex align-items-center gap-10">
                      {/* <Button className="d-flex align-items-center justify-content-center commonBtn">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M3 9.75V14.25C3 14.6478 3.15804 15.0294 3.43934 15.3107C3.72064 15.592 4.10218 15.75 4.5 15.75H13.5C13.8978 15.75 14.2794 15.592 14.5607 15.3107C14.842 15.0294 15 14.6478 15 14.25V9.75M9 2.25V11.25M9 11.25L6.375 8.625M9 11.25L11.625 8.625"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        Import
                      </Button> */}
                      <Button className="d-flex align-items-center justify-content-center commonBtn">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 7.87495V14.625C15.1875 14.9233 15.069 15.2095 14.858 15.4204C14.647 15.6314 14.3609 15.75 14.0625 15.75H3.9375C3.63913 15.75 3.35298 15.6314 3.142 15.4204C2.93103 15.2095 2.8125 14.9233 2.8125 14.625V7.87495C2.8125 7.57659 2.93103 7.29044 3.142 7.07946C3.35298 6.86848 3.63913 6.74995 3.9375 6.74995H5.625C5.77418 6.74995 5.91726 6.80922 6.02275 6.91471C6.12824 7.0202 6.1875 7.16327 6.1875 7.31245C6.1875 7.46164 6.12824 7.60471 6.02275 7.7102C5.91726 7.81569 5.77418 7.87495 5.625 7.87495H3.9375V14.625H14.0625V7.87495H12.375C12.2258 7.87495 12.0827 7.81569 11.9773 7.7102C11.8718 7.60471 11.8125 7.46164 11.8125 7.31245C11.8125 7.16327 11.8718 7.0202 11.9773 6.91471C12.0827 6.80922 12.2258 6.74995 12.375 6.74995H14.0625C14.3609 6.74995 14.647 6.86848 14.858 7.07946C15.069 7.29044 15.1875 7.57659 15.1875 7.87495ZM6.58547 4.89792L8.4375 3.04519V9.56245C8.4375 9.71164 8.49676 9.85471 8.60225 9.9602C8.70774 10.0657 8.85082 10.125 9 10.125C9.14918 10.125 9.29226 10.0657 9.39775 9.9602C9.50324 9.85471 9.5625 9.71164 9.5625 9.56245V3.04519L11.4145 4.89792C11.5201 5.00347 11.6632 5.06277 11.8125 5.06277C11.9618 5.06277 12.1049 5.00347 12.2105 4.89792C12.316 4.79238 12.3753 4.64922 12.3753 4.49995C12.3753 4.35069 12.316 4.20753 12.2105 4.10199L9.39797 1.28949C9.34573 1.23719 9.28369 1.1957 9.2154 1.16739C9.14712 1.13908 9.07392 1.12451 9 1.12451C8.92608 1.12451 8.85288 1.13908 8.7846 1.16739C8.71631 1.1957 8.65427 1.23719 8.60203 1.28949L5.78953 4.10199C5.68398 4.20753 5.62469 4.35069 5.62469 4.49995C5.62469 4.64922 5.68398 4.79237 5.78953 4.89792C5.89508 5.00347 6.03823 5.06277 6.1875 5.06277C6.33677 5.06277 6.47992 5.00347 6.58547 4.89792Z"
                              fill="white"
                            />
                          </svg>
                        </span>{" "}
                        Export
                      </Button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>Practitioner</th>
                        <th>Package</th>
                        <th>Earning</th>
                        <th>Status</th>
                        <th>Created At</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Client</td>
                        <td>Practitioner</td>
                        <td>Standard Account</td>
                        <td>$ 20</td>
                        <td>
                          <div className={`badge text-white badge-primary`}>
                            active
                          </div>
                        </td>
                        <td>Oct 16, 2023</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>John</td>
                        <td>Mikke</td>
                        <td>Individual</td>
                        <td>$ 22</td>
                        <td>
                          <div className={`badge text-white badge-primary`}>
                            active
                          </div>
                        </td>
                        <td>Oct 18, 2023</td>
                      </tr>
                    </table>
                  </div>
                  {/* {faqList.length>0 &&
                                <ReactPagination 
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    handlePageChange={handlePageChange}
                                />
                            } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
