import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, ANNOUNCEMENT_LIST, ANNOUNCEMENT_ADD, ANNOUNCEMENT_VIEW, ANNOUNCEMENT_UPDATE, ANNOUNCEMENT_DELETE  } from './apiPath'
import { getAnnouncements } from "store/actions/announcements";

//get
export function getAnnouncementList(value, callback) {
  const { page, limit, search = "", status = "", role } = value || {};
  const queryString = new URLSearchParams({ page, limit, search, status, role }).toString();
  let url = `${MAIN_PATH}${ANNOUNCEMENT_LIST}?${queryString}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getAnnouncements(data, callback));
      return data;
    });
}

//add
export function addAnnouncement(value, callback) {
  let url = `${MAIN_PATH}${ANNOUNCEMENT_ADD}`;
  return postData(url, value);
}

//view
export function viewAnnouncement(id, callback) {
  let url = `${MAIN_PATH}${ANNOUNCEMENT_VIEW}/${id}`;
  return getData(url);
}

//edit
export function updateAnnouncement(id, value) {
  let url = `${MAIN_PATH}${ANNOUNCEMENT_UPDATE}/${id}`;
  return putData(url, value)
}

//delete
export function deleteAnnouncement(value) {
  let url = `${MAIN_PATH}${ANNOUNCEMENT_DELETE}/${value}`;
  return deleteData(url);
}