import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { SUBCATEGORIES } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { viewSubCategoryData } from 'store/services/subcategoryService';
import { getCategoryList } from 'store/services/categoryService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
const View = ({ submitting, dispatch, match, subcategoryDetail, categoryList, loader }) => {
    let editkey = match.params.id;
    const [categoryOptions, setCategoryOptions] = useState([])

    const initialValues = {
        category_id: subcategoryDetail ? subcategoryDetail.p_id : "",
        name: subcategoryDetail ? subcategoryDetail.name : "",
        // image: subcategoryDetail ? subcategoryDetail.image : "",
        status: subcategoryDetail ? subcategoryDetail.status : "",
    };
    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(viewSubCategoryData(editkey))
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    //fetch categories
    async function fetchCategory() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(getCategoryList())
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        fetchCategory();
        const options = categoryList.map(item => ({
            label: item.name,
            value: item.id,
        }));
        setCategoryOptions(options);
        getData();
    }, []);

    //fields
    const inputFields = [
        {
            name: 'category_id',
            type: 'dynamic_select',
            label: 'Catgeory*',
            placeholder: 'Catgeories',
            options: categoryOptions
        },
        {
            name: 'name',
            type: 'text',
            label: 'Name*',
            placeholder: 'Name',
        },
        {
            name: 'status',
            type: 'select',
            label: 'Status*',
            placeholder: 'Select',
            options: [
                {
                    label: 'Active',
                    value: 'active'
                },
                {
                    label: 'Inactive',
                    value: 'inactive'
                },
            ]
        },
        // {
        //   name: 'image',
        //   type: 'image',
        //   label: 'Image*',
        //   placeholder: '',
        //   allowType: ["image/jpeg", "image/png", "image/jpg",],
        //   allowTypeError: "JPEG, PNG, JPG"
        // },
    ];



    return (
        <>
            <Helmet title={SUBCATEGORIES.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{SUBCATEGORIES.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                       
                        <div className="breadcrumb-item"><Link to={Path.subcategory}>{SUBCATEGORIES.CURRENT_MODULE}</Link></div>
                        <div className="breadcrumb-item">View Subcategory</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">View Subcategory</h2>
                    <p className="section-lead">
                        On this page you see subcategory detail.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={''}
                                        loading={loader}
                                        inputFields={inputFields}
                                        submitting={submitting}
                                        buttonText={false}
                                        is_block={false}
                                        backPath={Path.subcategory}
                                        ValidationError={false}
                                        col={12}
                                        imageRemoveButton={false}
                                        selectDisable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        subcategoryDetail: state.Subcategory.subcategory,
        categoryList: state.Category.categories,
        loader: state.Subcategory.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);