import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
// import { CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
// import CKEditor from "react-ckeditor-component"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import LoadingButton from "components/shared/LoadingButton";
import * as Path from "routes/paths";
import { EMAIL_TEMPLETE } from "common/viewContent";
import { viewTemplete_, updateTemplete_ } from 'store/services/templateServicce';
import * as actions from 'store/actions';

const editorConfiguration = {
  toolbar: [
    'heading', '|', 'undo', 'redo',
    'bold', 'italic', 'link', '|',
    'bulletedList', 'numberedList', '|',
    'blockQuote', 'insertTable', '|',
    'mediaEmbed', 'imageInsert', 'fileBrowser', 'exportPdf', 'exportWord', 'exportXml', '|',
    'undo', 'redo', "sourceEditing", "htmlSupport"
  ],
  sourceEditing: {
    preview: true, // Enable the preview feature
    highlightActiveLine: true, // Enable highlighting the line the cursor is on
    highlightMatches: true // Enable highlighting matching tag pairs
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true
      }
    ]
  }
};

const EditEmailTemp = ({ dispatch, match, tempDetail }) => {
  const history = useHistory();
  let editKey = match?.params?.id;

  const [fieldValue, setFieldValue] = useState(null)
  // console.log(previewHtml, "previewHtml")
  const [fields, setfields] = useState({
    subject: "",
    body: fieldValue ? fieldValue : "",
    status: "active"
  })

  const handleChange = useCallback(
    name => evt => {
      setfields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    },
    []
  )

  //get detail
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewTemplete_(editKey))
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    if (editKey) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (editKey) {
      setfields(prevState => ({
        ...prevState,
        ...tempDetail,
      }))
      setFieldValue(tempDetail.body)
    }
  }, [tempDetail])

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      let final_obj = {
        subject: fields.subject,
        body: fieldValue ? fieldValue : fields.body,
        status: fields.status
      }
      let fieldsId = editKey
      final_obj = JSON.stringify(final_obj)
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(updateTemplete_(fieldsId, final_obj));
      dispatch(actions.persist_store({ loader: false }));
      history.push(Path.email_template)
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <Helmet title={EMAIL_TEMPLETE.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{EMAIL_TEMPLETE.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{EMAIL_TEMPLETE.CURRENT_MODULE}</div>
          </div>
        </div>
        <div className="row mt-sm-4">
          <div className="col-10 col-md-10 col-lg-10">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group col-md-12 col-12">
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      onChange={handleChange("subject")}
                      value={fields.subject}
                    />
                  </div>
                  <div className="form-group col-md-12 col-12">
                    <label>Body</label>
                    {/* <CKEditor
                    editor={ClassicEditor}
                    config={ editorConfiguration }
                    data={fields.body}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log( { event, editor, data } );
                      setFieldValue(editor.getData());
                    }}
                    className="ckeditor-control"
                  /> */}
                    {/* <CKEditor
                      content={fieldValue}
                      isScriptLoaded={true}
                      events={{
                        change: evt => {
                          const data = evt?.editor?.getData()
                          setFieldValue(data);

                        },
                      }}

                      scriptUrl="https://cdn.ckeditor.com/ckeditor5/41.2.0/classic/ckeditor.js"
                    /> */}
                    <CKEditor
                      editor={Editor}
                      data={fieldValue || ""}
                      config={editorConfiguration}
                      onChange={(event, editor) => {
                        const data = editor?.getData()
                        setFieldValue(data);
                      }}
                    />
                  </div>
                  <div className="form-group col-md-12 col-12">
                    <label>Status</label>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <input
                          type="radio"
                          className=""
                          name="status"
                          value="active"
                          defaultChecked={fields.status === 'active' ? true : false}
                        />
                        <label>&nbsp;Active</label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="radio"
                          className=""
                          name="status"
                          value="inactive"
                          defaultChecked={fields.status === 'inactive' ? true : false}
                        />
                        <label> &nbsp;Inactive</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group button-submit">
                        <LoadingButton
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group button-submit">
                        <Link
                          to={Path.email_template}
                          className="btn btn-warning btn-lg"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tempDetail: state.Templete.templete_detail
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailTemp);