import React, { useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { SUBSCRIPTION } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { viewSubscriptionData } from 'store/services/subscriptionService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const View = ({ submitting, dispatch, match, subscriptionDetail, loader }) => {
    let editkey = match.params.id;

    const initialValues = {
        package_name: subscriptionDetail ? subscriptionDetail.package_name : "",
        package_type: subscriptionDetail ? subscriptionDetail?.package_type : "",
        package_price: subscriptionDetail ? subscriptionDetail.package_price : "",
        package_desc: subscriptionDetail ? subscriptionDetail?.package_desc : "",
        package_included: subscriptionDetail ? subscriptionDetail?.package_included : "",
        is_nta_package: subscriptionDetail ? subscriptionDetail.is_nta_package : "",
        free_trail: subscriptionDetail ? subscriptionDetail.free_trail : "",
        free_trail_duration: subscriptionDetail ? subscriptionDetail.free_trail_duration : "",
        status: subscriptionDetail ? subscriptionDetail.status : "",
        is_multiple_login_allowed: subscriptionDetail ? subscriptionDetail.is_multiple_login_allowed : ""
    };
    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(viewSubscriptionData(editkey))
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <Helmet title={SUBSCRIPTION.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{SUBSCRIPTION.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item"><Link to={Path.subscriptions}>Subscription</Link></div>
                        <div className="breadcrumb-item">View Subscription</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">View Subscription</h2>
                    <p className="section-lead">
                        On this page you see subscription detail.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={''}
                                        loading={loader}
                                        inputFields={InputFields}
                                        submitting={submitting}
                                        buttonText={false}
                                        is_block={false}
                                        backPath={Path.subscriptions}
                                        ValidationError={false}
                                        col={12}
                                        selectDisable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        subscriptionDetail: state.Subscription.subscription,
        loader: state.Subscription.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);