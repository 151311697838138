import {getData, putData} from 'helpers/apiHelper'
import { MAIN_PATH, VIEW_BANNER, UPDATE_BANNER, BANNER_LISTING  } from './apiPath'

export function getBannerList() {
  let url = `${MAIN_PATH}${BANNER_LISTING}`;
  return getData(url);
}

export function viewBanner(bannerId, callback) {
  let url = `${MAIN_PATH}${VIEW_BANNER}${bannerId}`;
  return getData(url);
}

export function updateBanner(payload) {
  let { bannerId, data } = payload;
  let url = `${MAIN_PATH}${UPDATE_BANNER}${bannerId}`;
  return putData(url, data);
}
