import { postData, getData, putData, deleteData } from 'helpers/apiHelper'
import { MAIN_PATH, SUBCATEGORY_LIST, SUBCATEGORY_ADD, SUBCATEGORY_UPDATE, SUBCATEGORY_VIEW, SUBCATEGORY_DELETE, CATEGORY_SUBACTEGORY } from './apiPath'
import { getSubCategory, addSubCategory, viewSubCategory, updateSubCategory, deleteSubCategory, getSubcategoryCategory } from 'store/actions/subcategory';

//get
export function getSubCategoryList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${SUBCATEGORY_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getSubCategory(data, callback));
      return data;
    });
}

//add
export function addSubCategoryData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${SUBCATEGORY_ADD}`, value)
    .then((data) => {
      dispatch(addSubCategory(data, callback));
      return data;
    });
}

//view
export function viewSubCategoryData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${SUBCATEGORY_VIEW}${value}`)
    .then((data) => {
      dispatch(viewSubCategory(data, callback));
      return data;
    });
}


//edit
export function updateSubCategoryData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${SUBCATEGORY_UPDATE}`, value)
    .then((data) => {
      dispatch(updateSubCategory(data, callback));
      return data;
    });
}


//delete
export function deleteSubCategoryData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${SUBCATEGORY_DELETE}${value}`)
    .then((data) => {
      dispatch(deleteSubCategory(data));
      return data;
    });
}

//get
export function getSubcategoryCategoryList(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${CATEGORY_SUBACTEGORY}${value}`)
    .then((data) => {
      dispatch(getSubcategoryCategory(data, callback));
      return data;
    });
}

