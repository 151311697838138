import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import { useCallback, useEffect, useState } from "react";
import TableRow from "./components/TableRow";
import { Button } from "react-bootstrap";
import { Prompt } from "react-router-dom";

const ListingTable = ({ data, handleDelete, saveSortingChanges, handlePreview }) => {
  const [pageList, setPageList] = useState([]);
  const [unsavedCurrentSorting, setUnsavedCurrentSorting] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);

  useEffect(() => {
    if(data) {
      setPageList(data);
    }
  }, [data]);

  useEffect(() => {
    if (unsavedCurrentSorting) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [unsavedCurrentSorting]);

  // useEffect(() => {
  //   if(sortingChanged && pageList?.length) {
  //     let newSorting = pageList.map((page, i) => ({
  //       id: page.id, 
  //       sorting: page.index
  //     }))
  //     console.log("newSorting", newSorting);
  //     setUnsavedCurrentSorting(newSorting);
  //     setSortingChanged(false);
  //   }
  // }, [sortingChanged])
  
  const moveCard = useCallback((dragIndex, hoverIndex, setPages) => {
    setPages((prevPages) => {
      let sortedPages = update(prevPages, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevPages[dragIndex]],
        ],
      });

      let newSorting = sortedPages.map((page, i) => ({
        id: page.id, 
        sorting: i
      }))

      setUnsavedCurrentSorting((prevSorting) => ([
        ...(prevSorting ? prevSorting : []),
        ...newSorting
      ]));

      return sortedPages;
    });
  }, [])

  const saveChanges = async () => {
    try {
      setSavingChanges(true);
      await saveSortingChanges(unsavedCurrentSorting);
      setUnsavedCurrentSorting(null);
      setSavingChanges(false);
    } catch(err) {
      console.log("Error occurred while saving sorting changes", err);
      setSavingChanges(false);
    }
  }

  return (
    <>
      {unsavedCurrentSorting && (
        <div className="py-2">
          <Prompt
            when={() => !!unsavedCurrentSorting}
            message='You have unsaved changes, are you sure you want to leave?'
          />
          <div className="d-flex gap-10 align-items-center text-end justify-content-end">
            <p className="fw-normal m-0">Order of the pages has been changed, do you want to save the changes?</p>
            <Button
              className="btn btn-primary"
              onClick={saveChanges}
            >
              Yes
            </Button>
            <Button
              className="btn"
              variant="danger"
              onClick={() => {
                setUnsavedCurrentSorting(null);
                window.location.reload();
              }}
            >
              Discard changes
            </Button>
          </div>
        </div>
      )}
      <table className="table table-striped">
        <tr>
        <th style={{width: "5%"}}></th>
          <th style={{width: "5%"}}></th>
          <th style={{width: "5%"}}>#</th>
          <th style={{width: "25%"}}>Title</th>
          <th style={{width: "15%"}}>Slug</th>
          <th style={{width: "15%"}}>Created At</th>
          <th style={{width: "15%"}}>Status</th>
          <th style={{width: "15%"}}>Action</th>
        </tr>
        <DndProvider backend={HTML5Backend}>
          {pageList.length > 0 ? (
            pageList.map((item, i) => {
              item.index = i;
              let level = 1;
              return (
                <TableRow
                  itemType={`item_level_${level}`}
                  level={level}
                  key={item.id}
                  id={item.id}
                  index={i}
                  item={item}
                  moveCard={moveCard}
                  handleDelete={handleDelete}
                  handlePreview={handlePreview}
                  setPageList={setPageList}
                />
              );
            })):(
              <tr>
                <td colSpan={7} className="text-center">Data not found</td>
              </tr>
            )}
        </DndProvider>
      </table>
    </>
  )
}

export default ListingTable;