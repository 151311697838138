import { ADD_PRACTITIONER_NOTES, GET_PRACTITIONER_NOTES, GET_PRACTITIONER_MY_CLIENT, GET_PRACTITIONER_HISTORY, GET_PRACTITIONER, ADD_PRACTITIONER, VIEW_PRACTITIONER, UPDATE_PRACTITIONER, DELETE_PRACTITIONER, CLEAR_PRACTITIONER_DETAIL, IMPORT_CSV_FILE, IMPORT_CSV_FILE_PRAC, IMPORT_UPDATE_CSV_FILE_PRAC } from 'store/actions/practitioner';

const initialState = {
  isAuthenticated: false,
  practitioners: [],
  practitioner: null,
  practitionersHistory: [],
  loader: false,
  totalItemsCount: 0,
  itemsCountPerPage: 0,
  practitionersMyClint: [],
  itemsCountPerPageClint: 0,
  totalItemsCountClint: 0,
  practitionersNotes: [],
  itemsCountPerPageNotes: 0,
  totalItemsCountNotes: 0,
  csv_data:null,
  csv_data_prac:null,
  csv_data_prac_:null,
};

//get 
const getPractitioner = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioners: data.body.data,
    itemsCountPerPage: data.body.per_page,
    totalItemsCount: data.body.total_count
  };
  return stateObj;
};


//get 
const getPractitionerHistory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitionersHistory: data.body.data,
    itemsCountPerPage: data.body.per_page,
    totalItemsCount: data.body.total_count
  };
  return stateObj;
};


//get 
const getPractitionerClint = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitionersMyClint: data.body.data,
    itemsCountPerPageClint: data.body.per_page,
    totalItemsCountClint: data.body.total_count
  };
  return stateObj;
};



//get 
const getPractitionerNotes = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitionersNotes: data.body.data,
    itemsCountPerPageNotes: data.body.per_page,
    totalItemsCountNotes: data.body.total_count
  };
  return stateObj;
};

//add 
const addPractitionerNotes = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitionersNotes: data.body,
  };
  return stateObj;
};

//add 
const addPractitioner = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioner: null,
  };
  return stateObj;
};

//update 
const viewPractitioner = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioner: data.body,
  };
  return stateObj;
};

//update 
const updatePractitioner = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioners: data.body,
  };
  return stateObj;
};

//delete 
const deletePractitioner = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioners: [],
  };
  return stateObj;
};

//delete 
const clearPractitionerDetail = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    practitioner: data,
  };
  return stateObj;
};

//importCSVFile
const importCSVFile = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    csv_data: data.body,
  };
  return stateObj;
};

//importCSVFile
const importCSVFilePrac = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    csv_data_prac: data.body,
  };
  return stateObj;
};
//IMPORT UPDATE
const importUpdateCSVFilePrac = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    csv_data_prac_: data.body,
  };
  return stateObj;
};



const Practitioner = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_PRACTITIONER:
      return getPractitioner(state, payload);
    case ADD_PRACTITIONER:
      return addPractitioner(state, payload);
    case VIEW_PRACTITIONER:
      return viewPractitioner(state, payload);
    case UPDATE_PRACTITIONER:
      return updatePractitioner(state, payload);
    case DELETE_PRACTITIONER:
      return deletePractitioner(state, payload);
    case GET_PRACTITIONER_HISTORY:
      return getPractitionerHistory(state, payload);
    case GET_PRACTITIONER_MY_CLIENT:
      return getPractitionerClint(state, payload)
    case GET_PRACTITIONER_NOTES:
      return getPractitionerNotes(state, payload)
    case ADD_PRACTITIONER_NOTES:
      addPractitionerNotes(state, payload)
    case CLEAR_PRACTITIONER_DETAIL:
      clearPractitionerDetail(state, payload)
    case IMPORT_CSV_FILE:
      importCSVFile(state, payload);
    case IMPORT_CSV_FILE_PRAC:
      importCSVFilePrac(state, payload)
    case IMPORT_UPDATE_CSV_FILE_PRAC:
      importUpdateCSVFilePrac(state, payload)
    default:
      return state;
  };
}
export default Practitioner;