//action types
export const TEMPLETE = 'TEMPLETE';
export const UPDATE_TEMPLETE = 'UPDATE_TEMPLETE';
export const VIEW_TEMPLETE = 'VIEW_TEMPLETE';

//get TEMPLETE
export function getTemplete(payload) {
    return {
        type: TEMPLETE,
        payload,
    };
}
//view TEMPLETE
export function viewTemplete(payload) {
    return {
        type: VIEW_TEMPLETE,
        payload
    };
}
//update TEMPLETE
export function updateTemplete(payload) {
    return {
        type: UPDATE_TEMPLETE,
        payload
    };
}

