import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, HELP_PAGE_LIST, HELP_PAGE_ADD, HELP_PAGE_UPDATE, HELP_PAGE_VIEW, HELP_PAGE_DELETE, HELP_PAGE_UPDATE_ORDER, HELP_PAGE_LIST_ALL  } from './apiPath'
import { getHelpPages } from "store/actions/helpPage";

//get
export function getHelpPageList(value, callback) {
  const { page, limit, search, status } = value || {};
  const queryString = new URLSearchParams({ page, limit, search, status }).toString();
  let url = `${MAIN_PATH}${HELP_PAGE_LIST}?${queryString}`;
  // let url = `http://localhost:3000/dev/help-pages/list?${queryString}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getHelpPages(data, callback));
      return data;
    });
}

export function getAllHelpPages() {
  let url = `${MAIN_PATH}${HELP_PAGE_LIST_ALL}`;
  // let url = `http://localhost:3000/dev/help-pages/list-all`;
  return getData(url);
}

//add
export function addHelpPageData(value, callback) {
  let url = `${MAIN_PATH}${HELP_PAGE_ADD}`;
  // let url = `http://localhost:3000/dev/help-pages/add`;
  return postData(url, value);
}

//view
export function viewHelpPageData(id, callback) {
  let url = `${MAIN_PATH}${HELP_PAGE_VIEW}${id}`;
  // let url = `http://localhost:3000/dev/help-pages/detail/${id}`;
  return getData(url);
}

//edit
export function updateHelpPageData(value, callback) {
  let url = `${MAIN_PATH}${HELP_PAGE_UPDATE}`;
  // let url = `http://localhost:3000/dev/help-pages/edit`;
  return putData(url, value)
}

//delete
export function deleteHelpPageData(value) {
  let url = `${MAIN_PATH}${HELP_PAGE_DELETE}${value}`;
  // let url = `http://localhost:3000/dev/help-pages/delete/${value}`;
  return deleteData(url);
}

export function saveHelpPagesOrder(value) {
  let url = `${MAIN_PATH}${HELP_PAGE_UPDATE_ORDER}`;
  // let url = `http://localhost:3000/dev/help-pages/order`;
  return postData(url, value);
}

