import {getData} from 'helpers/apiHelper'
import { FETCH_BASIC_SETTINGS, MAIN_PATH  } from './apiPath'
import { basicSettings } from 'store/actions/app';

// get basic settings
export function getBasicSettings(callback) {
  let url = `${MAIN_PATH}${FETCH_BASIC_SETTINGS}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(basicSettings(data, callback));
      return data;
  });
}