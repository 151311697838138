import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//imported
import Layout from 'components/layout/Layout';
import * as Path from './paths';
import NotFound from 'components/NotFound';

const PrivateRoute = ({
  component: Component,
  screenProps: ScreenProps,
  authentication,
  user,
  ...routeProps
}) => {

  if(user?.role == 2 && routeProps.permission === true) {
    return <NotFound />;
  }

  return (
    <Route
      {...routeProps}
      render={(props) =>
        authentication ? (
          <Layout screenProps={ScreenProps}>
            <Component {...props} authRoute={true} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: Path.login, state: { from: props.location } }} />
        )
      }
    />
  )
};
const mapStateToPros = (state) => {
  return {
    authentication: state.Auth.isAuthenticated,
    user: state.Auth.user,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(PrivateRoute);

