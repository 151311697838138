import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
//imported
import PublicRoute from "./Public";
import PrivateRoute from "./Private";
import AuthRoute from "./Auth";
import routes from "./routes";
import * as Path from "./paths";
import NotFound from "components/NotFound";

//...............................................
function Routes({ authentication }) {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return authentication ? (
              <Redirect to={Path.practitioner} />
            ) : (
              <Redirect to={Path.login} />
            );
          }}
        />
        {routes.map((route) => {
          if (route.fallback) {
            return <AuthRoute key={route.path} {...route} />;
          }
          if (route.auth) {
            return <PrivateRoute key={route.path} {...route} />;
          }
          return <PublicRoute key={route.path} {...route} />;
        })}
      </Switch>
    </Router>
  );
}

const mapStateToPros = (state) => {
  return {
    authentication: state.Auth.isAuthenticated,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Routes);
