import * as Yup from 'yup';

const Validation = Yup.object({
    title: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
    role: Yup.string().oneOf(["3", "4"]).required(),
    status: Yup.string().oneOf(["active", "inactive"]).required(),
    // status: Yup.string().required("Required"),
});

export default Validation;
