import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

//imported
import { CLIENT } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import {
    resetUserPassword,
} from "store/services/practitionerService";
import { Button} from "react-bootstrap";
import { CONFIRM_ALERT } from "common/constantMessage";
import PasswordUpdatedPopup from "components/shared/Modals/PasswordUpdatedPopup";
import { resendPasswordResetLink, viewClientData } from "store/services/clientService";
import InputFields from "./inputFields";
import { clearClientDetails } from "store/actions/client";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import EditUser from "./Modal/EditUser";

const View = ({
    dispatch,
    match,
    clientDetail,
}) => {

    // password updated popup
    const [showPwdUpdatedPopup, setShowPwdUpdatedPopup] = useState(false);
    const [updatedPassword, setUpdatedPassword] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [showEditUserPopup, setShowEditUserPopup] = useState(false);

    let editkey = match.params.id;

    useEffect(() => {
        getApiData();
        return () => {
            dispatch(clearClientDetails());
        }
    }, []);

    //get detail
    async function getApiData() {
        try {
            setLoading(true);
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(viewClientData(editkey));
            dispatch(actions.persist_store({ loader: false }));
            setLoading(false);
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            setLoading(false);
            console.log(err);
        }
    }

    const resendPwdResetLink = async () => {
        try {
            dispatch(actions.persist_store({ loader: true }));
            let reponse = await resendPasswordResetLink(editkey);
            // if (reponse?.success === true) {
            //   SUCCESS_MESSAGE("Password reset link sent successfully")
            // }
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
        }
    }

    const resetPassword = async () => {
        try {
            await CONFIRM_ALERT("Are you sure you want to reset user password?").then(async (confirmed) => {
                if (confirmed) {
                    try {
                        dispatch(actions.persist_store({ loader: true }));
                        let response = await resetUserPassword(editkey);
                        if (response?.success === true) {
                            setUpdatedPassword(response?.body?.updated_password);
                            setShowPwdUpdatedPopup(true);
                            getApiData();
                        }
                        dispatch(actions.persist_store({ loader: false }));
                    } catch (err) {
                        dispatch(actions.persist_store({ loader: false }));
                    }
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <PasswordUpdatedPopup
                showPopup={showPwdUpdatedPopup}
                setShowPopup={setShowPwdUpdatedPopup}
                updatedPassword={updatedPassword}
            />
            <EditUser
                showEditUser={showEditUserPopup}
                setShowEditUser={setShowEditUserPopup}
                editkey={editkey}
                clientDetail={clientDetail}
            />

            <Helmet title={CLIENT.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{CLIENT.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">

                        <div className="breadcrumb-item">
                            <Link to={Path.practitioner}>{CLIENT.CURRENT_MODULE}</Link>
                        </div>
                        <div className="breadcrumb-item">View Client</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">View Client</h2>
                    <p className="section-lead">
                        On this page you can see client detail.
                    </p>
                    <div className="row mt-sm-4 align-items-start">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="py-2">
                                <div className="bg-white px-4 py-3 card-box rounded">
                                    <div className="top d-flex flex-wrap align-items-start  my-2 py-2 justify-content-between">
                                        <h4 className="dashboard-head m-0">Personal Information</h4>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-start gap-10">
                                        <Button
                                            onClick={() => {
                                                setShowEditUserPopup(true);
                                            }}
                                            className="d-flex align-items-center justify-content-center commonBtn"
                                        >
                                            Edit
                                        </Button>
                                        {clientDetail?.is_direct_account === "no" && (
                                            <>
                                                <Button
                                                    onClick={resendPwdResetLink}
                                                    className="d-flex align-items-center justify-content-center commonBtn"
                                                >
                                                    Resend Link
                                                </Button>
                                                <Button
                                                    onClick={resetPassword}
                                                    className="d-flex align-items-center justify-content-center commonBtn"
                                                >
                                                    Reset Password
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                    {isLoading ? (
                                        <LoadingSkeleton className="mt-5" itemCount={4} />
                                    ):(
                                    <div className="table-responsive">
                                        <table class="table common-table">
                                            <tbody>
                                                {InputFields.map((field, idx) => {
                                                    return (
                                                        <>
                                                            {field.view ? (
                                                                <tr className={``} key={idx}>
                                                                    <td>
                                                                    <p className="m-0 fw-sbold text-dark">
                                                                        {field.label}
                                                                    </p>
                                                                    </td>
                                                                    <td>
                                                                    <p className="m-0">
                                                                        {clientDetail[field.name]
                                                                            ? typeof field.formatter === "function"
                                                                                ? field.formatter(clientDetail)
                                                                                : clientDetail[field.name]
                                                                            : "--"}
                                                                    </p>
                                                                    </td>
                                                                </tr>
                                                                ) : (
                                                                ""
                                                                )}
                                                            
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        clientDetail: state.Client.client,
        loader: state.Practitioner.loader,
    };
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
