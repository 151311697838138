import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import persistStore from './persistStore';
//imported
import Auth from '../reducers/Auth';
import Profile from '../reducers/Profile';
import File from '../reducers/File';
import Subscription from '../reducers/Subscription';
import Category from '../reducers/Category';
import ClientCategoryForm from '../reducers/ClientCategoryForm';
import Subcategory from '../reducers/Subcategory';
import Resource from '../reducers/Resource';
import Faq from '../reducers/Faq';
import ContentPage from '../reducers/ContentPage';
import Practitioner from '../reducers/Practitioner';
import SystemAccess from '../reducers/SystemAccess';
import Configuration from '../reducers/Configuration';
import Client from '../reducers/Client';
import Templete from '../reducers/Temlete'
import HelpPage from "./HelpPage";
import DefaultForms from "./DefaultForms";
import App from "./App";
import Announcement from "./Announcements";
import EmailLog from "./EmailLog";

const RootReducer = combineReducers({
	Auth,
	Profile,
	File,
	Subscription,
	Category,
	ClientCategoryForm,
	Subcategory,
	Resource,
	Faq,
	ContentPage,
	Practitioner,
	SystemAccess,
	Configuration,
	persistStore,
	Client,
	Templete,
	HelpPage,
	DefaultForms,
	Announcement,
	App,
	EmailLog,
	form: formReducer,
});
export default RootReducer;
