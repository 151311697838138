import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { postData } from "helpers/apiHelper";
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
  nta_membership_expiry: Yup.string()
    .required('Expiration Date is required')
    .test('expirationDate', 'Expiration date must be greater than today', function (value) {
      const expirationDate = new Date(value);
      const today = new Date();
      return expirationDate > today;
    }),
});
const validateData = async (data) => {
  try {
    await validationSchema.validate(data, { abortEarly: false });
    return { isValid: true, errors: {} };
  } catch (error) {
    const errors = {};
    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    return { isValid: false, errors };
  }
};

const MemberShipPop = ({ showMembership, setShowMembership, editkey, data }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const handleMembership = () => setShowMembership(!showMembership);
  const [renewData, setRenewData] = useState()
  const [sending, setSending] = useState(false)
  const [dataToSave, setDataToSave] = useState()
  const handleRenewed = (e) => {
    const { name, value } = e.target
    setRenewData({ ...renewData, [name]: value })
    setDataToSave({ ...dataToSave, [name]: value })
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  }

  useEffect(() => {
    setRenewData(data)
  }, [data])
  const RenewingNTAMembership = async () => {
    let data_new = {
      nta_membership_expiry: renewData.nta_membership_expiry
    }
    if (renewData.nta_membership_id) {
      data_new.nta_membership_id = renewData.nta_membership_id
    }
    const validation = await validateData(renewData);
    if (validation.isValid) {
      try {
        setSending(true)
        const url = `admin/practitioner/membership/renew/${editkey}`;
        const data = await postData(url, data_new);
        setSending(false)
        handleMembership()
        window.location.reload()
      } catch (error) {
        setSending(false)
        console.log(error);
      }
    } else {
      setValidationErrors(validation.errors);
    }
  }
  function convertIsoToYMD(isoDate) {
    const dateObject = new Date(isoDate);
    const formattedDate = dateObject?.toISOString()?.split('T')[0];
    return formattedDate;
  }
  return (
    <>
      <Modal
        show={showMembership}
        onHide={handleMembership}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Renewed NTA Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {
              data ? <Row>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Membership Id
                  </label>
                  <input type="text" className="form-control" name="nta_membership_id" value={renewData?.nta_membership_id} onChange={handleRenewed} />
                </Col>
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 fw-sbold">
                    Expiration Date
                  </label>
                  <input type="date" name="nta_membership_expiry" value={renewData?.nta_membership_expiry ? convertIsoToYMD(renewData.nta_membership_expiry) : ""} className="form-control" onChange={handleRenewed} />
                  {validationErrors.nta_membership_expiry && (
                    <div className="text-danger">{validationErrors.nta_membership_expiry}</div>
                  )}
                </Col>
              </Row> : "false"
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleMembership}>
            Close
          </Button>
          <Button onClick={RenewingNTAMembership} variant="primary">
            {sending ? 'Wait...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MemberShipPop;
