import React from 'react';
import PropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';

const SortableTable = ({
  indexColumn = true,
  columns,
  data,
  onSort,
  sortColumn,
  sortOrder,
  onDelete,
  generateEditPath,
  noDataMessage = 'No data found',
}) => {
  const handleSort = (column) => {
    if (column.sortable) {
      onSort(column.key, (sortColumn === column.key && sortOrder === "asc") ? "desc" : "asc");
    }
  };

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          {indexColumn && (
            <th>#</th>
          )}
          {columns.map((column) => (
            <th
              key={column.key}
              onClick={() => handleSort(column)}
              style={{ cursor: column.sortable ? 'pointer' : 'default' }}
              className="fw-bold"
            >
              {column.label}
              {column.sortable && (
                <i
                  className={`fas fa-${sortColumn === column.key ? sortOrder === 'asc' ? 'sort-up' : 'sort-down' : "sort"}`}
                  style={{ marginLeft: '2px' }}
                ></i>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 ? (
          data.map((item, index) => (
            <tr key={index}>
              {indexColumn && (
                <td>{index + 1}</td>
              )}

              {columns.map((column) => (
                <td key={column.key}>
                  {column.render ? column.render(item[column.key], item) : item[column.key]}
                </td>
              ))}
              {/* To-Do Implement action items */}
              {/* <td>
                <Link
                  to={generateEditPath(item.id)}
                  title="Edit"
                >
                  <i className="fa fa-lg fa-edit"></i>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => onDelete(item.id)}
                >
                  <i className="fa fa-lg fa-trash" title="Delete"></i>
                </Link>
              </td> */}
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan={columns.length + 1}>{noDataMessage}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onDelete: PropTypes.func.isRequired,
  generateEditPath: PropTypes.func.isRequired,
  noDataMessage: PropTypes.string,
};

export default SortableTable;
