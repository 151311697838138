import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import parse from 'html-react-parser';
import { viewHelpPageData } from "store/services/helpPageService";

const PreviewPagePopup = ({ showPopup, closePopup, previewPageId }) => {
  const [pageDetail, setPageDetail] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(previewPageId) {
      getData();
    }
  }, [previewPageId]);

  const handleClose = () => {
    setPageDetail(null);
    closePopup();
  }

  //get detail
	async function getData() {
		try {
			setLoading(true);
			let res = await viewHelpPageData(previewPageId);
			setPageDetail(res?.body)
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="help-page-preview"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="help-page-preview">
          <Row>
            <Col lg="12" className="py-2 p-5">
              {isLoading ? (
                <>
                  <LoadingSkeleton
                    itemCount={5}
                  />
                </>
              ): pageDetail && (
                <div className="py-2 editor-content">
                  <h2>{pageDetail?.title}</h2>
                  <div>
                    {parse(pageDetail?.content)}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PreviewPagePopup;
