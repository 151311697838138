import * as Yup from "yup";

const Validation = Yup.object({
  cat_id: Yup.string().required("Required"),
  resource_name: Yup.string().required("Required"),
  // sub_cat_id: Yup.string().required("Required"),
  // media: Yup.string().required("Required"),
  // status: Yup.string().required("Required"),
});
export default Validation;
