import {postData, getData} from 'helpers/apiHelper'
import { DASHBOARD_INFO, MAIN_PATH, PROFILE, UPDATE_PROFILE } from './apiPath'
import { getDashboardInfo, getProfile, updateProfile,} from 'store/actions/profile';
//get profile
export function profile(value) {
  return (dispatch) => getData(`${MAIN_PATH}${PROFILE}`)
    .then((data) => {
      dispatch(getProfile(data));
      return data;
    });
}

//update profile
export function updateProfile_(value) {
  return (dispatch) => postData(`${MAIN_PATH}${UPDATE_PROFILE}`, value)
    .then((data) => {
      dispatch(updateProfile(data));
      return data;
    });
}

export function dashboardInfo(value) {
  return (dispatch) => getData(`${MAIN_PATH}${DASHBOARD_INFO}`, value)
    .then((data) => {
      dispatch(getDashboardInfo(data));
      return data;
    });
}