import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
//imported
import * as Path from "routes/paths";
import { logout } from "store/services/authService";
import * as actions from "store/actions";
import { useCustomHistory } from "common/getHistory";
import { profile } from 'store/services/profileService';

const Header = ({ user, dispatch, profileDetail }) => {
  //history
  const history = useHistory();
  const path = useCustomHistory();
  //sidebar
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("sidebar-mini", isOpen);
    if (document.body.classList.contains("sidebar-gone")) {
      document.body.classList.remove("sidebar-gone");
      document.body.classList.remove("sidebar-mini");
      document.body.classList.add("sidebar-show", isOpen);
    }
  }, [isOpen]);
  //logout function
  const handleLogout = async () => {
    try {
      const authToken = user && user.user ? user.user.token : "";

      dispatch(actions.persist_store({ loader: true }));
      await dispatch(logout(authToken)).then((res) => {
        toast.success(res.message);
        dispatch(actions.persist_store({ loader: false }));
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
      if (err && err.data && err.data.message) {
        toast.error(err.data.message);
      }
    }
  };
  useEffect(() => {
    // if(profileDetail === null || profileDetail?.status === "inactive") {
    //   localStorage.removeItem('access_token');
    //   localStorage.removeItem('user');
    //   history.push(Path.login)
    // }
  }, []);

  return (
    <>
      <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar">
        {/* <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <Link
                to="#"
                data-toggle="sidebar"
                onClick={() => setIsOpen(!isOpen)}
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars"></i>
              </Link>
            </li>
          </ul>
        </form> */}
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <Link
              to="#"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              {profileDetail?.profile_image ? (
                <img
                  alt="image"
                  src={profileDetail?.profile_image}
                  class="rounded-circle mr-1 img_list_round-proifle"
                />
              ) : (
                <figure
                  className="avatar mr-2 avatar-sm bg-success text-white userprofile_img"
                  data-initial={profileDetail?.role === 1 ? "A" : "S"}
                ></figure>
              )}
              <div className="d-sm-none d-lg-inline-block">
                {" "}
                {profileDetail?.first_name}
              </div>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item has-icon" to={Path.profile}>
                <i className="far fa-user"></i> Profile
              </Link>
              <Link
                className="dropdown-item has-icon"
                to={Path.change_password}
              >
                <i className="fa fa-lock"></i> Change Password
              </Link>
              <div className="dropdown-divider"></div>
              <span
                className="dropdown-item has-icon text-danger logout-span"
                onClick={handleLogout}
              >
                <i className="fas fa-sign-out-alt"></i>{" "}
                <span className="logout-span">Logout</span>
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    loader: state.persistStore.loader,
    user: state.Auth,
    profileDetail: state.Profile.user,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Header);
