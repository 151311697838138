import { GET_CLIENT_CATEGORIES, GET_FORM_LISTING } from "store/actions/defaultForms";

const initialState = {
    isAuthenticated: false,
    forms: [],
    clientCategories: [],
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

const getFormListing = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        forms: data.body,
        // itemsCountPerPage: data.body.per_page,
        // totalItemsCount: data.body.total_count
    };
  return stateObj;
};

const getClientCategories = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        clientCategories: data.body
    };
  return stateObj;
};

const DefaultForms = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_FORM_LISTING:
          return getFormListing(state, payload);
        case GET_CLIENT_CATEGORIES:
          return getClientCategories(state, payload);
        default:
        return state;
    };
}
export default DefaultForms;