import React, { useState, useEffect, useMemo } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

//imported
import { ANNOUNCEMENTS } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import Validation from './Validation'
import LoadingButton from "components/shared/LoadingButton";
import { STATUS_ENUM } from "common/constants";
import { Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CKEditorField from "components/shared/CKEditorField";
import { addAnnouncement, updateAnnouncement, viewAnnouncement } from "store/services/announcementService";

const AddEdit = ({ dispatch }) => {
	const history = useHistory();
	const { id: editkey } = useParams();

	const [data, setData] = useState();

	const initialValues = useMemo(() => {
		return {
			title: data?.title || "",
			description: data?.description || "",
			role: data?.role || "3",
			status: data?.status || "active",
		};
	}, [data]);

	useEffect(() => {
		if (editkey) {
			getData();
		}
	}, []);

	//get detail
	async function getData() {
		try {
			dispatch(actions.persist_store({ loader: true }));
			let res = await viewAnnouncement(editkey);
			setData(res?.body)
			dispatch(actions.persist_store({ loader: false }));
		} catch (err) {
			dispatch(actions.persist_store({ loader: false }));
			console.log(err);
		}
	}

	// add edit
	const handleSubmit = async (values) => {
		try {
			dispatch(actions.persist_store({ loader: true }));
			if(editkey) {
				await updateAnnouncement(editkey, values);
			} else {
				await addAnnouncement(values);
			}
			history.push(Path.announcements_management)
			dispatch(actions.persist_store({ loader: false }));

		} catch (err) {
			console.log(err);
			dispatch(actions.persist_store({ loader: false }));
		}
	}

	return (
		<>
			<Helmet title={ANNOUNCEMENTS.CURRENT_MODULE} />
			<section className="section">
				<div className="section-header">
					<h1>{ANNOUNCEMENTS.CURRENT_MODULE}</h1>
					<div className="section-header-breadcrumb">

						<div className="breadcrumb-item"><Link to={Path.announcements_management}>Announcements</Link></div>
						<div className="breadcrumb-item">Edit Announcement</div>
					</div>
				</div>
				{/* {!props.loader && */}
				<div className="section-body">
					<h2 className="section-title">Edit Announcement</h2>
					<p className="section-lead">
						On this page you can edit the Announcement.
					</p>
					<Formik
						initialValues={initialValues}
						validationSchema={Validation}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{({
							values,
							errors,
							touched,
							isSubmitting,
							handleSubmit,
							handleChange,
							handleBlur,
							setFieldValue
						}) => (
							<div className="row mt-sm-4">
								{console.log(values, errors)}
								<div className="col-8 col-md-8 col-lg-8 col-sm-12">
									<div className="card">
										<div className="card-body">
											<Form onSubmit={handleSubmit}>
												<Row>
													<div
														className={`form-group col-12`}
													>
														<label>Title</label>
														<input
															type="text"
															className={`form-control ${errors["title"]
																? "is-invalid"
																: ""
																} `}
															name="title"
															placeholder={"Title"}
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.title}
														/>
														{errors["title"] && touched["title"] && (
															<p className="invalid-feedback">{errors["title"]}</p>
														)}
													</div>
													<div
														className={`form-group col-12`}
													>
														<label>Description</label>
														<CKEditorField
															field="description"
															setFieldValue={setFieldValue}
															value={values.description || ""}
															errors={errors}
														/>
														{errors["description"] && touched["description"] && (
															<p className="invalid-feedback">{errors["description"]}</p>
														)}
													</div>
													<div
														className={`form-group col-12`}
													>
														<label>Location</label>
														<div className="row">
															<div className="form-group col-md-6">
																<input
																	id="role-practitioner"
																	type="radio"
																	className="form-radio"
																	name="role"
																	onChange={handleChange}
																	value={"3"}
																	checked={values.role == "3"}
																/>
																<label for="role-practitioner">&nbsp;Practitioner Panel</label>
															</div>
															<div className="form-group col-md-6">
																<input
																	id="role-client"
																	type="radio"
																	className="form-radio"
																	name="role"
																	onChange={handleChange}
																	value={"4"}
																	checked={values.role == "4"}
																/>
																<label for="role-client">&nbsp;Client Panel</label>
															</div>
														</div>
														{errors["status"] && touched["status"] && (
															<p className="invalid-feedback">{errors["status"]}</p>
														)}
													</div>
													<div
														className={`form-group col-12`}
													>
														<label>Status</label>
														<div className="row">
															{STATUS_ENUM.map((option, index) => (
																<div className="form-group col-md-6" key={index}>
																	<input
																		id={`status-${option.value}`}
																		type="radio"
																		className="form-radio"
																		name="status"
																		onChange={handleChange}
																		value={option.value}
																		checked={values.status === option.value}
																	/>
																	<label for={`status-${option.value}`}>&nbsp;{option.label}</label>
																</div>
															))}
														</div>
														{errors["status"] && touched["status"] && (
															<p className="invalid-feedback">{errors["status"]}</p>
														)}
													</div>
													<div className="form-group col-12 button-submit">
														<LoadingButton
															type="submit"
															className="btn btn-primary btn-lg"
															loading={isSubmitting}
															disabled={isSubmitting}
														>
															{isSubmitting ? "Please Wait..." : "Save"}
														</LoadingButton>
													</div>
												</Row>
											</Form>
										</div>
									</div>
								</div>
							</div>
						)}
					</Formik>
				</div>
				{/* } */}
			</section>
		</>
	)
}


const mapStateToProps = (state) => {
	return {
	}
};

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);