import { GET_SYSTEM_ACCESS, ADD_SYSTEM_ACCESS, VIEW_SYSTEM_ACCESS, UPDATE_SYSTEM_ACCESS, DELETE_SYSTEM_ACCESS } from 'store/actions/systemAccess';

const initialState = {
    isAuthenticated: false,
    system_accesses: [],
    system_access: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getSystemAccess = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        system_accesses:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addSystemAccess = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      system_accesses:data.body,
    };
    return stateObj;
  };

  //update 
const viewSystemAccess = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      system_access:data.body,
    };
    return stateObj;
};

  //update 
const updateSystemAccess = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      system_accesses:data.body,
    };
    return stateObj;
};

//delete 
const deleteSystemAccess = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    system_accesses:[],
  };
  return stateObj;
};

const SystemAccess = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_SYSTEM_ACCESS:
          return getSystemAccess(state, payload);
        case ADD_SYSTEM_ACCESS:
            return addSystemAccess(state, payload);
        case VIEW_SYSTEM_ACCESS:
          return viewSystemAccess(state, payload);
        case UPDATE_SYSTEM_ACCESS:
          return updateSystemAccess(state, payload);
        case DELETE_SYSTEM_ACCESS:
          return deleteSystemAccess(state, payload);
        default:
        return state;
    };
}
export default SystemAccess;