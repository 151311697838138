import moment from "moment";
import React from "react";
import { connect } from "react-redux";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-left">
        &copy; {moment().get("years")} {process.env.REACT_APP_APPNAME_FOOTER}, INC. All Rights Reserved.
      </div>
    </footer>
  );
};
const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    loader: state.persistStore.loader,
    user: state.Auth,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Footer);
