import {getData} from 'helpers/apiHelper'
import { EMAIL_LOGS_LIST, MAIN_PATH  } from './apiPath'
import { emailLogs } from "store/actions/emailLogs";

// get basic settings
export function getEmailLogs(value, callback) {
  const { page, limit, search = "", status = "", role, sort_field, sort_direction } = value || {};
  const queryString = new URLSearchParams({ page, limit, search, status, role, sort_field, sort_direction }).toString();

  let url = `${MAIN_PATH}${EMAIL_LOGS_LIST}?${queryString}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(emailLogs(data, callback));
      return data;
  });
}