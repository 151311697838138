import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
//imported
import {
  viewClientFormCategoryData,
  deleteClientFormCategoryData,
} from "store/services/clientCategoryFormService";
import { CLIENT_CATEGORY_FORM, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import { DUMMY_IMG } from "utils/constants/constant";
import ReactPagination from "components/shared/ReactPagination";
import AddEdit from "./AddEdit";

const List = ({
  dispatch,
  categoryList,
  itemsCountPerPage,
  totalItemsCount,
  categoryDetail,
}) => {
  const [clientCategory, setClientCatgeory] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [editViewButton, setEditViewButton] = useState(false);
  const [isView, setIsView] = useState(false);

  //view
  async function viewDetail_(item) {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(viewClientFormCategoryData(item));
      setEditKey(item);
      setIsView(true);
      setSelectedCheckboxes(res?.body?.form_id);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //edit
  async function editDetail_(item) {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(viewClientFormCategoryData(item));
      setSelectedCheckboxes(res?.body?.form_id);
      setEditKey(item);
      setIsView(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //delete
  const deleteItem_ = async (id) => {
    try {
      setClientCatgeory(false);
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteClientFormCategoryData(id)).then(() => {
              setClientCatgeory(true);
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };


  const addButton_ = () => {
    setEditKey(null)
    setSelectedCheckboxes([])
    setEditViewButton(true)
    setIsView(false)
  }


  return (
    <>
      <Helmet title={CLIENT_CATEGORY_FORM.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CLIENT_CATEGORY_FORM.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            {/* <div className="breadcrumb-item active">
              <Link to={Path.dashboard}>Dashboard</Link>
            </div> */}
            <div className="breadcrumb-item">
              {CLIENT_CATEGORY_FORM.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">
            {CLIENT_CATEGORY_FORM.CURRENT_MODULE}
          </h2>
          <p className="section-lead">
            In this section, you can create client categories for the Practitioner Panel and associate default forms with them. This allows practitioners to easily assign these forms as to-do items when selecting a category for their clients.
          </p>

          <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h4>{CLIENT_CATEGORY_FORM.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                      {categoryList?.length > 0 &&
                        categoryList?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.category_name}</td>
                              <td>{DATE_FORMAT(item.created_at)}</td>
                              <td>
                                <div className="d-flex align-items-center gap-10 tableBtn">
                                  <Link
                                    to="#"
                                    onClick={() => viewDetail_(item.uid)}
                                    title="View"
                                  >
                                    <i className="fa fa-lg fa-eye"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() => editDetail_(item.uid)}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link>
                                  <span
                                    className="text-danger"
                                    onClick={() => deleteItem_(item.uid)}
                                  >
                                    <i
                                      className="fa fa-lg fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <div className="col-md-9">
                    <h4>
                      {CLIENT_CATEGORY_FORM.ADD}/{CLIENT_CATEGORY_FORM.EDIT}
                    </h4>
                  </div>
                  {editKey &&
                    <div className="col-md-3">
                      <div className="section-header-button">
                        <span className="btn btn-primary" onClick={() => addButton_()}>
                          + Add
                        </span>
                      </div>
                    </div>
                  }
                </div>
                <div className="card-body">
                  <AddEdit
                    clientCategory={clientCategory}
                    categoryDetail={categoryDetail}
                    editKey={editKey}
                    setEditKey={setEditKey}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    setEditViewButton={setEditViewButton}
                    editViewButton={editViewButton}
                    isView={isView}
                    setIsView={setIsView}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.ClientCategoryForm?.categories?.data_set,
    categoryDetail: state.ClientCategoryForm?.clientform,
    itemsCountPerPage: state.ClientCategoryForm.itemsCountPerPage,
    totalItemsCount: state.ClientCategoryForm.totalItemsCount,
    loader: state.ClientCategoryForm.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
