import {
  getData,
  postData,
  postData_,
  putData,
  // postData,
  // putData,
  // deleteData
} from "helpers/apiHelper";
import {
  MAIN_PATH,
  CLIENT_LIST,
  CLIENT_VIEW,
  CLIENT_RESEND_PWD_RESET_LINK,
  CLIENT_UPDATE,
} from "./apiPath";
import {
  getClient,
  // addClient,
  viewClient,
  // updateClient,
  // deleteClient,
} from "store/actions/client";

//get
export function getClientList(value, callback) {
  const { page, limit, search } = value || {};
  let url = `${MAIN_PATH}${CLIENT_LIST}`;
  // let url = `http://localhost:3000/dev/practitioner/clients/list`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}&search=${search}`;
  }
  return (dispatch) =>
    getData(url, value).then((data) => {
      dispatch(getClient(data, callback));
      return data;
    });
}

//add
// export function addClientData(value, callback) {
//   return (dispatch) =>
//     postData(`${MAIN_PATH}${CLIENT_ADD}`, value).then((data) => {
//       dispatch(addClient(data, callback));
//       return data;
//     });
// }

//view
export function viewClientData(value, callback) {
  return (dispatch) =>
    getData(`${MAIN_PATH}${CLIENT_VIEW}${value}`).then((data) => {
      dispatch(viewClient(data, callback));
      return data;
    });
}

//edit
export function updateClientData(data, callback) {
  let { clientId, values } = data;
  return putData(`${MAIN_PATH}${CLIENT_UPDATE}${clientId}`, values);
}

//delete
// export function deleteClientData(value) {
//   return (dispatch) =>
//     deleteData(`${MAIN_PATH}${CLIENT_DELETE}${value}`).then((data) => {
//       dispatch(deleteClient(data));
//       return data;
//     });
// }


// Resend password reset link
export function resendPasswordResetLink(clientId, callback) {
  return postData(`${MAIN_PATH}${CLIENT_RESEND_PWD_RESET_LINK}${clientId}`)
} 