import React from "react";
import ReactApexChart from "react-apexcharts";

const PractitionerSignupChart = ({ data }) => {
  
  const series = [{
    name: "Practitioner Signup",
    data: data ? data.map(row => row.count) : [],
    color: "#F18D06",
  }];

  const categories = data ? data.map(row => row.date) : [];
  
  const chartData = {
    series: series,
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: categories,
      },
      tooltip: {
        x: {
          format: "MM/dd/yy",
        },
      },
    },
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default PractitionerSignupChart;
