import Maintenance from "components/Maintenance";
import moment from "moment-timezone";
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBasicSettings } from "store/services/appService";

const WrappedApp = (WrappedComponent) => {
  return function WrappedComponentWithMaintenance(props) {
    const dispatch = useDispatch();
    const maintenanceConfig = useSelector((state) => state.App.basicSettings?.maintenance_schedule?.admin);

    useEffect(() => {
      dispatch(getBasicSettings());
    }, [dispatch]);

    if (
      maintenanceConfig?.enabled === "yes" &&
      maintenanceConfig?.start_date &&
      maintenanceConfig?.end_date &&
      moment().isBefore(moment(maintenanceConfig?.end_date)) &&
      moment().isAfter(moment(maintenanceConfig?.start_date))
    ) {
      return <Maintenance config={maintenanceConfig}/>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default WrappedApp;
