import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import { BANNER_MANAGEMENT } from "common/viewContent";
import * as actions from "store/actions";
import { getBannerList } from "store/services/bannersService";
import * as Path from "routes/paths";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { FORMAT_TEXT, formatText } from "helpers/common";

const List = ({
  dispatch,
}) => {
  const [bannerListing, setBannerListing] = useState(null);

  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getBannerList();
      setBannerListing(response?.body);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet title={BANNER_MANAGEMENT.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{BANNER_MANAGEMENT.CURRENT_MODULE}</h1>
          {/* <div className="section-header-button">
            <Link to={Path.content_add} className="btn btn-primary">
              Add Content
            </Link>
          </div> */}
          <div className="section-header-breadcrumb">
            
            <div className="breadcrumb-item">Form management</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{BANNER_MANAGEMENT.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can manage the banners for Practitioner/Client panel here.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{BANNER_MANAGEMENT.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText"></div>
                      <div className="">
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {bannerListing?.length > 0 ? (
                        bannerListing.map((banner, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {index + 1}
                              </td>
                              <td>
                                {banner.title}
                              </td>
                              <td>
                                {FORMAT_TEXT(banner.message, 40)}
                              </td>
                              <td>
                                {banner.role === 3 ? (
                                  "Practitioner Panel"
                                ):banner.role === 4 ? (
                                  "Client Panel"
                                ):(
                                  ""
                                )}
                              </td>
                              <td>
                                {formatText(banner.status)}
                              </td>
                              <td>
                                <Link
                                  to={generatePath(Path.banner_edit, {id: banner.id})}
                                  title="Edit"
                                >
                                  {" "}
                                  <i className="fa fa-lg fa-edit"></i>
                                </Link>
                              </td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          <td className="text-center" colSpan={6}>No data found</td>
                        </tr>
                      )}
                      
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
