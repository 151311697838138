//action types
export const GET_CLIENT = "GET_CLIENT";
export const ADD_CLIENT = "ADD_CLIENT";
export const VIEW_CLIENT = "VIEW_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const CLEAR_CLIENT_DETAILS = "CLEAR_CLIENT_DETAILS";

//get
export function getClient(data, callback) {
  return {
    type: GET_CLIENT,
    payload: { data, callback },
  };
}
//add
// export function addClient(data, callback) {
//   return {
//     type: ADD_CLIENT,
//     payload: { data, callback },
//   };
// }

// //view
export function viewClient(data, callback) {
  return {
    type: VIEW_CLIENT,
    payload: { data, callback },
  };
}

// //update
// export function updateClient(data, callback) {
//   return {
//     type: UPDATE_CLIENT,
//     payload: { data, callback },
//   };
// }

// //delete
// export function deleteClient(payload) {
//   return {
//     type: DELETE_CLIENT,
//     payload,
//   };
// }


// clear client details state
export function clearClientDetails() {
  return {
    type: CLEAR_CLIENT_DETAILS,
  };
}
