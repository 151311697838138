import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { reduxForm } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';

//imported
import { SUBSCRIPTION } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { addSubscriptionData, viewSubscriptionData, updateSubscriptionData } from 'store/services/subscriptionService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
import InputFields from './inputFields'

const AddEdit = ({ submitting, dispatch, match, loader }) => {
    const history = useHistory();
    let editkey = match.params.id;
    const [subscriptionDetail, setSubscriptionDetail] = useState(null);
    const [initialValues, setInitialValues] = useState({
        package_name: "",
        package_type: "",
        package_price: "",
        package_desc: "",
        package_included: "",
        is_nta_package: "",
        free_trial: "",
        free_trial_duration: "",
        stripe_product_detail: "",
        status: "active",
        gender: "",
        is_multiple_login_allowed: "no"
    });

    useEffect(() => {
        if(subscriptionDetail) {
            setInitialValues({
                package_name: subscriptionDetail ? subscriptionDetail.package_name : "",
                package_type: subscriptionDetail ? subscriptionDetail?.package_type : "",
                package_price: subscriptionDetail ? subscriptionDetail.package_price : "",
                package_desc: subscriptionDetail ? subscriptionDetail?.package_desc : "",
                package_included: subscriptionDetail ? subscriptionDetail?.package_included : "",
                is_nta_package: subscriptionDetail ? subscriptionDetail.is_nta_package : "",
                free_trial: subscriptionDetail ? subscriptionDetail.free_trial : "",
                free_trial_duration: subscriptionDetail ? subscriptionDetail.free_trial_duration : "",
                stripe_product_detail: subscriptionDetail ? subscriptionDetail.stripe_product_detail : "",
                status: subscriptionDetail ? subscriptionDetail.status : "active",
                gender: subscriptionDetail ? subscriptionDetail.gender : "",
                is_multiple_login_allowed: subscriptionDetail ? subscriptionDetail.is_multiple_login_allowed : "no"
            });
        }
    }, [subscriptionDetail])

    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(viewSubscriptionData(editkey))
            setSubscriptionDetail(res?.body)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        if (editkey) {
            getData();
        }
    }, []);

    // add edit
    const handleSubmit_ = async (values, {setSubmitting}) => {

        try {
            const callback = (response) => {
                const { data } = response;
                if (data.success == true) {
                    history.push(Path.subscriptions)
                }
            }
            if (editkey) {
                values.id = editkey;
            }
            values.status = values.status ? values.status : 'active'
            values = JSON.stringify(values)

            dispatch(actions.persist_store({ loader: true }));
            setSubmitting(true)
            if (editkey) {
                await dispatch(updateSubscriptionData(values, callback));
            } else {
                await dispatch(addSubscriptionData(values, callback));
            }
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    }


    return (
        <>
            <Helmet title={SUBSCRIPTION.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{SUBSCRIPTION.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item"><Link to={Path.subscriptions}>Subscription</Link></div>
                        <div className="breadcrumb-item">{editkey ? 'Edit' : 'Add'} Subscription</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">{editkey ? 'Edit' : 'Add'} New Subscription</h2>
                    <p className="section-lead">
                        On this page you can create a new subscription and fill in all fields.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={handleSubmit_}
                                        loading={loader}
                                        inputFields={InputFields}
                                        submitting={submitting}
                                        buttonText="Save Changes"
                                        is_block={false}
                                        col={12}
                                        selectDisable={false}
                                        editkeyValue={subscriptionDetail?.id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        subscriptionDetail: state.Subscription.subscription,
        loader: state.Subscription.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);