import { GET_SUBSCRIPTION, ADD_SUBSCRIPTION, VIEW_SUBSCRIPTION, UPDATE_SUBSCRIPTION, DELETE_SUBSCRIPTION, GET_SUBSCRIPTION_HISTORY } from 'store/actions/subscription';

const initialState = {
    isAuthenticated: false,
    subscriptions: [],
    subscriptions_history: [],
    subscription: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
    itemsCountPerPage_:0,
    totalItemsCount_:0,
};

//get 
const getSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        subscriptions:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//get history
const getSubscriptionHistory = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
      ...state,
      isAuthenticated: true,
      subscriptions_history:data.body.data,
      itemsCountPerPage_:data.body.per_page,
      totalItemsCount_:data.body.total_count
  };
return stateObj;
};

//add 
const addSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      subscriptions:data.body,
    };
    return stateObj;
  };

  //update 
const viewSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      subscription:data.body,
    };
    return stateObj;
};

  //update 
const updateSubscription = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      subscriptions:data.body,
    };
    return stateObj;
};

//delete 
const deleteSubscription = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    subscriptions:[],
  };
  return stateObj;
};

const Subscription = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_SUBSCRIPTION:
          return getSubscription(state, payload);
        case GET_SUBSCRIPTION_HISTORY:
          return getSubscriptionHistory(state, payload);
        case ADD_SUBSCRIPTION:
            return addSubscription(state, payload);
        case VIEW_SUBSCRIPTION:
          return viewSubscription(state, payload);
        case UPDATE_SUBSCRIPTION:
          return updateSubscription(state, payload);
        case DELETE_SUBSCRIPTION:
          return deleteSubscription(state, payload);
        default:
        return state;
    };
}
export default Subscription;