const inputFields = [
  {
    name: "first_name",
    type: "text",
    label: "First Name*",
    placeholder: "First Name",
    view: false,
    edit: true
  },
  {
    name: "last_name",
    type: "text",
    label: "Last Name*",
    placeholder: "Last Name",
    view: false,
    edit: true
  },
  {
    name: "email",
    type: "email",
    label: "Email*",
    placeholder: "Email",
    view: true,
    edit: true
  },
  // {
  //   name: "password",
  //   type: "password",
  //   label: "Password*",
  //   placeholder: "Password",
  //   view :true
  // },
  // {
  //   name: "birth_date",
  //   type: "date",
  //   label: "Date of Birth",
  //   placeholder: "Date of Birth",
  //   view: false,
  //   edit: true
  // },
  {
    name: "phone",
    type: "phone", // Specify the type as 'phone'
    label: "Phone Number",
    placeholder: "Phone Number",
    view: true,
    edit: true
  },
  {
    name: "is_free_account",
    type: "select",
    label: "Is Free Account",
    placeholder: "Select",
    view: true,
    options: [
      {
        label: "Free",
        value: "yes",
      },
      {
        label: "Paid",
        value: "no",
      },
    ],
  },
  {
    name: "free_account_expiry",
    type: "date",
    label: "Expired Free Account Date*",
    placeholder: "Expired Free Account Date",
    view: true,
    edit: true
  },
  {
    name: "status",
    type: "select",
    label: "Status*",
    placeholder: "Select",
    view: true,
    options: [
      {
        label: "Active",
        value: "active",
      },
      {
        label: "Inactive",
        value: "inactive",
      },
    ],
  },
  // {
  //   name: "profile_image",
  //   type: "image",
  //   label: "Image",
  //   placeholder: "",
  //   allowType: ["image/jpeg", "image/png", "image/jpg"],
  //   allowTypeError: "JPEG, PNG, JPG",
  //   view: false
  // },
];

export default inputFields;
