import React from "react";
import ReactApexChart from "react-apexcharts";

const ClientSignupChart = ({ data }) => {
  
  const series = [
    {
      name: "Client Signup",
      data: data ? data.map(row => row.count) : [],
      color: "#F18D06",
    }
  ]

  const categories = data ? data.map(row => row.date) : [];

  const chartData = {
    series: series,
    
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: categories,
      },
      legend: {
        show: false, // Set to false to hide legend
      },
      fill: {
        opacity: 1,
      },
    },
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ClientSignupChart;
