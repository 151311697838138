import {  ADD_CONFIGURATION, INSTANCE_TYPE_LIST, VIEW_CONFIGURATION } from 'store/actions/configuration';

const initialState = {
    isAuthenticated: false,
    configuration: {},
    instanceTypeList: null,
    loader:false,
};

//add 
const addConfiguration = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      configuration:data.body,
    };
    return stateObj;
};

  //view 
const viewConfiguration = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      configuration:data.body,
    };
    return stateObj;
};

/**
 * View instance type list
 */
const viewInstanceTypeList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    instanceTypeList: 
      data.body?.length > 0
      ? data.body
      : initialState.instanceTypeList,
  };
  return stateObj;
};

const Configuration = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ADD_CONFIGURATION:
            return addConfiguration(state, payload);
        case VIEW_CONFIGURATION:
          return viewConfiguration(state, payload);
        case INSTANCE_TYPE_LIST:
          return viewInstanceTypeList(state, payload);
        default:
        return state;
    };
}
export default Configuration;