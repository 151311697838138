import { TEMPLETE, UPDATE_TEMPLETE, VIEW_TEMPLETE } from 'store/actions/templete';

const initialState = {
    isAuthenticated: false,
    templete: [],
    templete_detail: {},
    loader: false,
};

//get profile
const getTemplete = (state, payload) => {

    const { body } = payload;
    const stateObj = {
        ...state,
        isAuthenticated: true,
        templete: body,
    };
    return stateObj;
};

//view
const viewTemplete = (state, payload) => {

    const { body } = payload;
    const stateObj = {
        ...state,
        isAuthenticated: true,
        templete_detail: body,
    };
    return stateObj;
};

//update profile
const updateTemplete = (state, payload) => {
    const { body } = payload;
    const stateObj = {
        ...state,
        isAuthenticated: true,
        templete: body,
    };
    return stateObj;
};

const Templete = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case TEMPLETE:
            return getTemplete(state, payload);
        case UPDATE_TEMPLETE:
            return updateTemplete(state, payload);
        case VIEW_TEMPLETE:
            return viewTemplete(state, payload);
        default:
            return state;
    };
}
export default Templete;