import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD_FORM, RESET_PASSWORD, ACCOUNT_ACTIVATION, UPDATE_PASSWORD } from 'store/actions/auth';
import Http from 'helpers/Http';

const initialState = {
  isAuthenticated: false,
  user: null,
  loader: false,
};
const setTokenAndUser = (state, payload) => {
  if (payload && payload.user && payload.user.body && payload.user.body.token) {
    const { user } = payload;
    localStorage.setItem('access_token', user.body.token);
    localStorage.setItem('user', JSON.stringify(user.body));
    Http.defaults.headers.common.Authorization = `Bearer ${user.body.token}`;
    return {
      ...state,
      isAuthenticated: true,
      user: user.body,
      loader: true
    };
  } else {
    return state;
  }
};

//login 
const authLogin = (state, payload) => {
  const { user, callback } = payload;
  if (typeof callback === 'function') {
    callback(user);
  }
  return setTokenAndUser(state, payload);
};

//check auth
const authCheck = (state) => {
  const storedToken = localStorage.getItem('access_token');
  const storedUser = localStorage.getItem('user');

  const newState = {
    ...state,
    isAuthenticated: !!storedToken,
    user: storedUser ? JSON.parse(storedUser) : null,
  };

  if (newState.isAuthenticated) {
    Http.defaults.headers.common.Authorization = `Bearer ${storedToken}`;
  }

  return newState;
};

//logout
const authLogout = (state) => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
  const newState = {
    ...state,
    isAuthenticated: false,
    user: null,
    loader: false
  };

  return newState;
};

//forgotPassword
const forgotPassword = (state, payload) => {
  return setTokenAndUser(state, payload);
};

//resetPasswordForm
const resetPasswordForm = (state, payload) => {
  return setTokenAndUser(state, payload);
};

//resetPassword
const resetPassword = (state, payload) => {
  return setTokenAndUser(state, payload);
};

//change password
const changePassword = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  return setTokenAndUser(state, payload);
};

const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case AUTH_LOGIN:
      return authLogin(state, payload);
    case AUTH_CHECK:
      return authCheck(state, payload);
    case AUTH_LOGOUT:
      return authLogout(state);
    case FORGOT_PASSWORD:
      return forgotPassword(state);
    case RESET_PASSWORD_FORM:
      return resetPasswordForm(state);
    case RESET_PASSWORD:
      return resetPassword(state);
    case UPDATE_PASSWORD:
      return changePassword(state, payload);
    default:
      return state;
  };
}
export default Auth;