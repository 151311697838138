import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { css } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/******** imported ***********/
import Routes from "./routes";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </>
  );
}

export default App;
