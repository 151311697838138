//action types
export const GET_PRACTITIONER = 'GET_PRACTITIONER';
export const ADD_PRACTITIONER = 'ADD_PRACTITIONER';
export const VIEW_PRACTITIONER = 'VIEW_PRACTITIONER';
export const UPDATE_PRACTITIONER = 'UPDATE_PRACTITIONER';
export const DELETE_PRACTITIONER = 'DELETE_PRACTITIONER';
export const GET_PRACTITIONER_HISTORY = 'GET_PRACTITIONER_HISTORY';
export const GET_PRACTITIONER_MY_CLIENT = 'GET_PRACTITIONER_MY_CLIENT';
export const GET_PRACTITIONER_NOTES = 'GET_PRACTITIONER_NOTES';
export const ADD_PRACTITIONER_NOTES = 'ADD_PRACTITIONER_NOTES';
export const CLEAR_PRACTITIONER_DETAIL = 'CLEAR_PRACTITIONER_DETAIL';
export const IMPORT_CSV_FILE = 'IMPORT_CSV_FILE';
export const IMPORT_CSV_FILE_PRAC = 'IMPORT_CSV_FILE_PRAC';
export const IMPORT_UPDATE_CSV_FILE_PRAC = 'IMPORT_UPDATE_CSV_FILE_PRAC';

//get 
export function getPractitioner(data, callback) {
  return {
    type: GET_PRACTITIONER,
    payload: { data, callback },
  };
}

//get  history
export function getPractitionerHistory(data, callback) {
  return {
    type: GET_PRACTITIONER_HISTORY,
    payload: { data, callback },
  };
}

//get  history
export function getPractitionerMyClint(data, callback) {
  return {
    type: GET_PRACTITIONER_MY_CLIENT,
    payload: { data, callback },
  };
}

//get  history
export function getPractitionerNotes(data, callback) {
  return {
    type: GET_PRACTITIONER_NOTES,
    payload: { data, callback },
  };
}

export function addPractitionerNotes(data, callback) {
  return {
    type: ADD_PRACTITIONER_NOTES,
    payload: { data, callback },
  };
}


//add 
export function addPractitioner(data, callback) {
  return {
    type: ADD_PRACTITIONER,
    payload: { data, callback },
  };
}

//view 
export function viewPractitioner(data, callback) {
  return {
    type: VIEW_PRACTITIONER,
    payload: { data, callback },
  };
}
//update 
export function updatePractitioner(data, callback) {
  return {
    type: UPDATE_PRACTITIONER,
    payload: { data, callback },
  };
}

//delete 
export function deletePractitioner(payload) {
  return {
    type: DELETE_PRACTITIONER,
    payload
  };
}

export const clearPractitionerDetail = (data, callback) => ({
  type: CLEAR_PRACTITIONER_DETAIL,
  payload: { data, callback },
});

export function importCSVFile(data, callback) {
  return {
    type: IMPORT_CSV_FILE,
    payload: { data, callback },
  };
}

export function importCSVFilePrac(data, callback) {
  return {
    type: IMPORT_CSV_FILE_PRAC,
    payload: { data, callback },
  };
}
export function importUpdateCSVFilePrac(data, callback) {
  return {
    type: IMPORT_UPDATE_CSV_FILE_PRAC,
    payload: { data, callback },
  };
}