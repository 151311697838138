import * as Yup from 'yup';

const Validation = Yup.object({
    stripe_mode: Yup.string().required("Required"),
    full_script_mode: Yup.string().required("Required"),
    mailgun_api_key: Yup.string().required("Required"),
    mailgun_domain: Yup.string().required("Required"),
    mailgun_from: Yup.string().required("Required"),
    mailgun_auth: Yup.string().required("Required"),
    package_commission: Yup.string().required("Required"),
    appointment_commission: Yup.string().required("Required"),

});

export default Validation;
