const inputFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name*',
    placeholder: 'Name',
  },
  {
    name: 'status',
    type: 'radio',
    label: 'Status*',
    options: [
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Inactive',
        value: 'inactive'
      },
    ]
  },
  // {
  //   name: 'image',
  //   type: 'image',
  //   label: 'Image',
  //   placeholder: '',
  //   allowType: ["image/jpeg", "image/png", "image/jpg",],
  //   allowTypeError: "JPEG, PNG, JPG"
  // },
];

export default inputFields;