import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
//imported
import { INSTANCE_PERMISSIONS } from "common/viewContent";
import { DELETE_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT, UC_FIRST } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { toggleInstancesPermission, viewInstancesList, viewPermissionsList } from "store/services/configurationService";
import { Col, Form, Row } from "react-bootstrap";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";

const List = ({
  dispatch,
  instancesList
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [permissionsList, setPermissionsList] = useState(null);

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        await Promise.all([
          getInstancesList(),
          getPermissionsList()
        ])
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Something went wrong, please try again");
        console.log("Error loading permissions data", err);
      }
    }

    init();
  }, []);

  /**
   * Get instances list
   */
  async function getInstancesList() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewInstancesList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  /**
   * Get permissions list
   */
  const getPermissionsList = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await viewPermissionsList();
      if (response?.success === true && response?.body?.length > 0) {
        setPermissionsList(response?.body);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  /**
   * Toggle permission
   */
  const togglePermision = async (id, isAllowed) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let data = {
        permissionId: id,
        values: {
          is_allowed: isAllowed
        }
      }
      await toggleInstancesPermission(data);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  return (
    <>
      <Helmet title={INSTANCE_PERMISSIONS.CURRENT_MODULE} />
      <section className="section instance-permissions">
        <div className="section-header">
          <h1>{INSTANCE_PERMISSIONS.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            {/* <Link to={Path.system_access_add} className="btn btn-primary">
              Add New
            </Link> */}
          </div>
          <div className="section-header-breadcrumb">

            <div className="breadcrumb-item">
              {INSTANCE_PERMISSIONS.CURRENT_MODULE}
            </div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{INSTANCE_PERMISSIONS.CURRENT_MODULE}</h2>
          <p className="section-lead">
            This section allows you to Manage permissions for the instances.
          </p>

          {isLoading ? (
            <div className="row">
              <div className="col-12">
                <LoadingSkeleton itemCount={4} />
              </div>
            </div>
          ) : (
            <>
              {
                instancesList &&
                instancesList?.length > 0 &&
                instancesList.map((instance, i) => {
                  return (
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h4>{instance.name}</h4>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <tr>
                                  <th width={"30%"}>Permission Name</th>
                                  <th width={"70%"}>Is Allowed</th>
                                </tr>
                                {
                                  permissionsList &&
                                  permissionsList?.length > 0 &&
                                  permissionsList.map((permission, i) => {
                                    if (permission.instance_type_id !== instance.id) {
                                      return null;
                                    }
                                    return (
                                      <tr>
                                        <td>{UC_FIRST(permission.constant.replaceAll("_", " "))}</td>
                                        <td style={{ paddingLeft: "35px" }}>
                                          <Form.Check
                                            style={{ margin: 0, padding: 0 }}
                                            type="switch"
                                            id="custom-switch"
                                            defaultChecked={permission?.is_allowed === "yes"}
                                            onChange={(e) => {
                                              togglePermision(permission?.id, e.target.checked ? "yes" : "no")
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </>
          )}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    instancesList: state.Configuration.instanceTypeList,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
