import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, SUBSCRIPTION_LIST,SUBSCRIPTION_ADD, SUBSCRIPTION_UPDATE, SUBSCRIPTION_VIEW, SUBSCRIPTION_DELETE, SUBSCRIPTION_LIST_HISTORY } from './apiPath'
import { getSubscription, addSubscription, viewSubscription, updateSubscription, deleteSubscription,getSubscriptionHistory} from 'store/actions/subscription';

//get
export function getSubscriptionList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${SUBSCRIPTION_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getSubscription(data, callback));
      return data;
    });
}

//add
export function addSubscriptionData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${SUBSCRIPTION_ADD}`, value)
    .then((data) => {
      dispatch(addSubscription(data, callback));
      return data;
    });
}

//view
export function viewSubscriptionData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${SUBSCRIPTION_VIEW}${value}`)
    .then((data) => {
      dispatch(viewSubscription(data, callback));
      return data;
    });
}


//edit
export function updateSubscriptionData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${SUBSCRIPTION_UPDATE}`, value)
    .then((data) => {
      dispatch(updateSubscription(data, callback));
      return data;
    });
}


//delete
export function deleteSubscriptionData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${SUBSCRIPTION_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteSubscription(data));
      return data;
    });
}

//get
export function getSubscriptionHistory_(value, callback) {
  let url = `${MAIN_PATH}${SUBSCRIPTION_LIST_HISTORY}`;
  // let url = `http://localhost:3000/dev/subscriptions/sales/list`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(getSubscriptionHistory(data, callback));
      return data;
    });
}