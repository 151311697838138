import React, { useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useHistory, Link } from "react-router-dom";

//imported
import { UploadMediaFile } from "helpers/UploadFile";
import { IS_BASE64 } from "helpers/common";
import { CATEGORIES } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import {
  addCategoryData,
  viewCategoryData,
  updateCategoryData,
} from "store/services/categoryService";
import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";
import InputFields from "./inputFields";

const AddEdit = ({ submitting, dispatch, categoryDetail, match, loader }) => {
  const history = useHistory();
  let editkey = match.params.id;

  const initialValues = {
    name: categoryDetail ? categoryDetail.name : "",
    status: categoryDetail ? categoryDetail.status : "active",
    // image: categoryDetail ? categoryDetail.image : "",
  };
  //get detail
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewCategoryData(editkey));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    if (editkey) {
      getData();
    }
  }, []);

  // add edit
  const handleSubmit_ = async (values, {setSubmitting}) => {
    try {
      let imgRes;
      if (await IS_BASE64(values.image)) {
        imgRes = await UploadMediaFile(
          dispatch,
          values.image,
          values.extension,
          values.file_type,
          "category"
        );
        imgRes = imgRes.uploadResult;
      }
      const callback = (response) => {
        const { data } = response;
        if (data.success == true) {
          history.push(Path.category);
        }
      };

      let data = {
        name: values.name,
        status: values.status ? values.status : 'active'
      };
      if (imgRes) {
        data.image = imgRes ? imgRes : values.image ? values.image : null;
      }

      if (editkey) {
        data.id = editkey;
      }
      data = JSON.stringify(data);
      setSubmitting(true);
      dispatch(actions.persist_store({ loader: true }));
      if (editkey) {
        await dispatch(updateCategoryData(data, callback));
      } else {
        await dispatch(addCategoryData(data, callback));
      }
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  return (
    <>
      <Helmet title={CATEGORIES.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CATEGORIES.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">
              <Link to={Path.category}>{CATEGORIES.CURRENT_MODULE}</Link>
            </div>
            <div className="breadcrumb-item">
              {editkey ? "Edit" : "Add"} Category
            </div>
          </div>
        </div>
        {/* {!props.loader && */}
        <div className="section-body">
          <h2 className="section-title">
            {editkey ? "Edit" : "Add"} New Category
          </h2>
          <p className="section-lead">
            On this page you can create a new category and fill in all fields.
          </p>
          <div className="row mt-sm-4">
            <div className="col-8 col-md-8 col-lg-8">
              <div className="card">
                <div className="card-body">
                  <FormikForm
                    initialValues={initialValues}
                    validationSchema={Validation}
                    onSubmit={handleSubmit_}
                    loading={loader}
                    inputFields={InputFields}
                    submitting={submitting}
                    buttonText="Save Changes"
                    is_block={false}
                    col={12}
                    imageRemoveButton={true}
                    selectDisable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryDetail: state.Category.category,
    loader: state.Category.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);
