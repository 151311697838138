import * as Yup from 'yup';

const Validation = Yup.object({
    package_name: Yup.string().required("Required"),
    package_type: Yup.string().required("Required"),
    package_price: Yup.string().required("Required"),
    package_desc: Yup.string().required("Required"),
    package_included: Yup.string().required("Required"),
    is_nta_package: Yup.string().required("Required"),
    free_trial: Yup.string().required("Required"),
    stripe_product_detail: Yup.string().required("Required"),
    free_trial_duration: Yup.string().required("Required"),
    // status: Yup.string().required("Required"),
    is_multiple_login_allowed: Yup.string().required("Required"),

});

export default Validation;
