import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

//imported
import { DELETE_ALERT } from "common/constantMessage";
import { getFAQList, deleteFAQData } from "store/services/faqService";
import { FAQ, PAGE_LIMIT } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";

const List = ({ dispatch, itemsCountPerPage, totalItemsCount }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);

  const [faqList, setFaqList] = useState([]);
  //get category detail
  async function getData() {
    try {
      let query = {
        page: activePage,
        limit: PAGE_LIMIT,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getFAQList(query));
      setFaqList(res?.body?.data)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [activePage]);

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(deleteFAQData(id)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={FAQ.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{FAQ.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.faq_add} className="btn btn-primary">
              Add FAQ
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            
            <div className="breadcrumb-item">FAQ</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{FAQ.CURRENT_MODULE}</h2>
          <p className="section-lead">
            In this section, you can add and oversee all FAQs related to help guide on the Practitioner Panel.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{FAQ.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      {faqList.length > 0 &&
                        faqList.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{i+1}</td>
                              <td>{FORMAT_TEXT(item.question)}</td>
                              <td>{FORMAT_TEXT(item.answer)}</td>
                              <td>{DATE_FORMAT(item.created_at)}</td>
                              <td>
                                <div
                                  className={`badge text-white badge-${item.status == "active"
                                      ? "primary"
                                      : "danger"
                                    }`}
                                >
                                  {item.status}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-10 tableBtn">
                                  <Link
                                    to={`${Path.faqview}/${item.id}`}
                                    title="View"
                                  >
                                    <i className="fa fa-lg fa-eye"></i>
                                  </Link>
                                  <Link
                                    to={`${Path.faqedit}/${item.id}`}
                                    title="Edit"
                                  >
                                    {" "}
                                    <i className="fa fa-lg fa-edit"></i>
                                  </Link>
                                  <span
                                    className="text-danger"
                                    onClick={() => deleteItem_(item.id)}
                                  >
                                    <i
                                      className="fa fa-lg fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  {faqList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    faqList: state.Faq.faqs,
    itemsCountPerPage: state.Faq.itemsCountPerPage,
    totalItemsCount: state.Faq.totalItemsCount,
    loader: state.Faq.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
