const inputFields = [
    {
      name: 'old_password',
      type: 'password',
      label: 'Current Password*',
      placeholder: '',
    },
    {
      name: 'new_password',
      type: 'password',
      label: 'New Password*',
      placeholder: '',
    },
    {
        name: 'confirm_password',
        type: 'password',
        label: 'Confirm Password*',
        placeholder: '',
    },
];

export default inputFields;