import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, CLIENT_CATEGOTY_LIST,CLIENT_CATEGOTY_ADD, CLIENT_CATEGOTY_EDIT, CLIENT_FORM_VIEW, CLIENT_CATEGOTY_DELETE, DEFAULT_FORM } from './apiPath'
import { getClientFormCategory, addClientFormCategory, viewClientFormCategory, updateClientFormCategory, deleteClientFormCategory, getClientFormList} from 'store/actions/clientCategoryForm';

//get
export function getClientFormCategoryList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${CLIENT_CATEGOTY_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getClientFormCategory(data, callback));
      return data;
    });
}

//add
export function addClientFormCategoryData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CLIENT_CATEGOTY_ADD}`, value)
    .then((data) => {
      dispatch(addClientFormCategory(data, callback));
      return data;
    });
}

//view
export function viewClientFormCategoryData(value, callback) {
  return (dispatch) => getData(`${MAIN_PATH}${CLIENT_FORM_VIEW}${value}`)
    .then((data) => {
      dispatch(viewClientFormCategory(data, callback));
      return data;
    });
}


//edit
export function updateClientFormCategoryData(value, callback) {
  value = JSON.parse(value)
  let editVal = value.edit_value
  let final_val = {
    category_name : value.category_name,
    form_id : value.form_id
  }
  final_val = JSON.stringify(final_val)
  return (dispatch) => putData(`${MAIN_PATH}${CLIENT_CATEGOTY_EDIT}${editVal}`, final_val)
    .then((data) => {
      dispatch(updateClientFormCategory(data, callback));
      return data;
    });
}


//delete
export function deleteClientFormCategoryData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${CLIENT_CATEGOTY_DELETE}${value}`)
    .then((data) => {
       dispatch(deleteClientFormCategory(data));
      return data;
    });
}
//form listing
export function getClientFormListing(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${DEFAULT_FORM}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getClientFormList(data, callback));
      return data;
    });
}