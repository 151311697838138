import React, { useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { CONFIGURATION } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { addConfigurationData, viewConfigurationData } from 'store/services/configurationService';
import Validation from './Validation'
import Form from './Form'

const List = ({ submitting, dispatch, configurationDetail, loader }) => {
    let editkey = 'configuration';
    let responseData = configurationDetail.data_key ? JSON.parse(configurationDetail.data_set) : ''

    const initialValues = {
        // stripe_mode: responseData?.stripe?.stripe_mode ? responseData.stripe.stripe_mode : "",
        // full_script_mode: responseData?.full_script?.full_script_mode ? responseData.full_script.full_script_mode : "",
        // mailgun_api_key: responseData?.mailgun?.mailgun_api_key ? responseData.mailgun.mailgun_api_key : "",
        // mailgun_domain: responseData?.mailgun?.mailgun_domain ? responseData.mailgun.mailgun_domain : "",
        // mailgun_from: responseData?.mailgun?.mailgun_from ? responseData.mailgun.mailgun_from : "",
        // mailgun_auth: responseData?.mailgun?.mailgun_auth ? responseData.mailgun.mailgun_auth : "",
        package_commission: responseData?.commission_setting?.package_commission ? responseData.commission_setting.package_commission : "",
        appointment_commission: responseData?.commission_setting?.appointment_commission ? responseData.commission_setting.appointment_commission : ""
    };

    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(viewConfigurationData(editkey))
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    useEffect(() => {
        getData();
    }, []);

    // add edit
    const submitData_ = async (values, {setSubmitting}) => {
        try {
            let finalObj = {
                // stripe: { stripe_mode: values.stripe_mode },
                // full_script: { full_script_mode: values.full_script_mode },
                commission_setting: {
                    package_commission: values.package_commission,
                    appointment_commission: values.appointment_commission
                },
                // mailgun: {
                //     mailgun_api_key: values.mailgun_api_key,
                //     mailgun_domain: values.mailgun_domain,
                //     mailgun_from: values.mailgun_from,
                //     mailgun_auth: values.mailgun_auth,
                // }
            }
            let final_value = {
                data_key: 'configuration',
                data_set: finalObj,
            }
            final_value = JSON.stringify(final_value)
            setSubmitting(true)
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(addConfigurationData(final_value));
            dispatch(actions.persist_store({ loader: false }));
            setSubmitting(false)
            getData();
        } catch (err) {
            console.log(err);
            setSubmitting(false)
            dispatch(actions.persist_store({ loader: false }));
        }
    }

    return (
        <>
            <Helmet title={CONFIGURATION.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{CONFIGURATION.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                      
                        <div className="breadcrumb-item">Configuration</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">Configuration Add/Update</h2>
                    <p className="section-lead">
                        On this page you can add update configuration and fill in all fields.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-12">
                            <Form
                                initialValues={initialValues}
                                sending={loader}
                                submitting={submitting}
                                updateData_={submitData_}
                                editkey={editkey}
                            />
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        configurationDetail: state.Configuration.configuration,
        loader: state.Configuration.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);