import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

//imported
import { RESOURCES } from "common/viewContent";
import * as actions from 'store/actions';
import * as Path from 'routes/paths';
import { viewResourceData } from 'store/services/resourceService';
import { getCategoryList } from 'store/services/categoryService';
import { getSubcategoryCategoryList, getSubCategoryList } from 'store/services/subcategoryService';
import FormikForm from 'components/shared/FormikForm';
import Validation from './Validation'
const View = ({ submitting, dispatch, match, resourceDetail, categoryList, loader, subCategoryData }) => {


    let editkey = match.params.id;
    const [categoryOptions, setCategoryOptions] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const initialValues = {
        category_id: resourceDetail ? resourceDetail.cat_id : "",
        subcategory_id: resourceDetail ? resourceDetail.sub_cat_id : "",
        media: resourceDetail ? resourceDetail.media : "",
        status: resourceDetail ? resourceDetail.status : "",
    };
    //get detail
    async function getData() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(viewResourceData(editkey))
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    //fetch categories
    async function fetchCategory() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(getCategoryList())
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    //fetch subcategories
    async function fetchSubCategory() {
        try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(getSubCategoryList())
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchSubCategory()
        const options = categoryList.map(item => ({
            label: item.name,
            value: item.id,
        }));
        setCategoryOptions(options);
        const subOptions = subCategoryData.map(item => ({
            label: item.name,
            value: item.id,
        }));
        setSubCategoryOptions(subOptions);
        if (editkey) {
            getData();
        }
    }, [resourceDetail.cat_id, resourceDetail.sub_cat_id]);

    //fields
    const inputFields = [
        {
            name: 'category_id',
            type: 'dynamic_select',
            label: 'Catgeory*',
            placeholder: 'Catgeories',
            options: categoryOptions
        },
        {
            name: 'subcategory_id',
            type: 'dynamic_select',
            label: 'Subcatgeory*',
            placeholder: 'Subcatgeories',
            options: subCategoryOptions
        },
        {
            name: 'status',
            type: 'select',
            label: 'Status*',
            placeholder: 'Select',
            options: [
                {
                    label: 'Active',
                    value: 'active'
                },
                {
                    label: 'Inactive',
                    value: 'inactive'
                },
            ]
        },
        {
            name: 'media',
            type: 'image',
            label: 'Media (PDF)*',
            placeholder: '',
            allowType: ["application/pdf"],
            allowTypeError: "PDF",
            extension: 'pdf'
        },
    ];






    // console.log(categoryList, '===categoryList')

    // console.log(initialValues, '====initialValuesz')
    // console.log(resourceDetail, '===resourceDetazil')
    console.log(subCategoryData, '====subCategoryData')

    return (
        <>
            <Helmet title={RESOURCES.CURRENT_MODULE} />
            <section className="section">
                <div className="section-header">
                    <h1>{RESOURCES.CURRENT_MODULE}</h1>
                    <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item"><Link to={Path.resource}>{RESOURCES.CURRENT_MODULE}</Link></div>
                        <div className="breadcrumb-item">View Subcategory</div>
                    </div>
                </div>
                {/* {!props.loader && */}
                <div className="section-body">
                    <h2 className="section-title">View Resource</h2>
                    <p className="section-lead">
                        On this page you see resource detail.
                    </p>
                    <div className="row mt-sm-4">
                        <div className="col-8 col-md-8 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <FormikForm
                                        initialValues={initialValues}
                                        validationSchema={Validation}
                                        onSubmit={''}
                                        loading={loader}
                                        inputFields={inputFields}
                                        submitting={submitting}
                                        buttonText={false}
                                        is_block={false}
                                        backPath={Path.resource}
                                        ValidationError={false}
                                        col={12}
                                        imageRemoveButton={false}
                                        selectDisable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    console.log(state.Subcategory.subcategories)
    return {
        resourceDetail: state.Resource.resource,
        categoryList: state.Category.categories,
        loader: state.Subcategory.loader,
        subCategoryData: state.Subcategory.subcategories,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);