import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";

//imported
import { DELETE_ALERT } from "common/constantMessage";
import { SUBSCRIPTION_SALES, PAGE_LIMIT } from "common/viewContent";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT, isFalsy } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { Button, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  getSubscriptionHistory_,
} from "store/services/subscriptionService";
import { postData } from "helpers/apiHelper";
import DateRangePicker from "components/shared/DateRangePicker";
import { getName } from "country-list";



  const List = ({ dispatch, subscriptionHistory, itemsCountPerPage, totalItemsCount, }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [csvData, setCsvData] = useState(null);
  const [resData, setResData] = useState([]);
  const [dateRange, setDateRange] = useState(null)
  const [filters, setFilters] = useState({});

  //pagination
  const [activePage, setActivePage] = useState(1);
  //get
  let query = {
    page: activePage,
    limit: PAGE_LIMIT,
    searchTerm: "",
    filters: {}
  };
  async function getSubscriptionData(query) {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getSubscriptionHistory_(query));
      setResData(res?.body?.data)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    query.filters = filters;
    getSubscriptionData(query);
  }, [activePage, filters]);

  useEffect(() => {
    const formattedSearchValue = searchTerm.toLowerCase().replace(/\s/g, '');
    setFilters((prevState) => ({
      ...prevState,
      searchTerm: formattedSearchValue,
      dateRange
    }));
    setActivePage(1);
  }, [searchTerm, dateRange]);

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    if(startDate && endDate) {
      setDateRange({
        startDate,
        endDate
      })
    }
  }

  const handleExportClick_ = async () => {
    try {
      query.filters = filters;
      query.limit = 0;
      const url = "admin/subscriptions/sales/list";
      // const url = "http://localhost:3000/dev/subscriptions/sales/list"
      const response = await postData(url, query);
      // const data = resData
      // console.log(data, "--")
      const originalData = response.body.data;
      if (originalData.length === 0) {
        throw new Error("No data found in the response.");
      }
      const historyToExport = [];
      originalData.forEach((row) => {
        let customerLink = `${process.env.REACT_APP_STRIPE_DASHBOARD_LINK}/customers/${row.stripe_cust_id}`;
        let invoiceLink = `${process.env.REACT_APP_STRIPE_DASHBOARD_LINK}/invoices/${row.invoice_id}`;
        let item = {
          "Stripe Customer": customerLink,
          "Invoice": invoiceLink,
          "Date Paid": moment(row.created_at).format("MM/DD/YYYY"),
          "Name": `${row.first_name} ${row.last_name}`,
          "Email": row.email,
          "Billing Country": !isFalsy(row.customer_country) ? getName(row.customer_country) : "",
          "Billing State": !isFalsy(row.customer_state) ? row.customer_state : "",
          "Product Price": !isFalsy(row.package_price) ? row.package_price : "",
          "Product Name": !isFalsy(row.package_name) ? row.package_name : "",
          "Amount Received": !isFalsy(row.amount_paid) ? (row.amount_paid / 100) : 0,
        }

        historyToExport.push(item);
      })
      if (!csvData) {
        console.log("historyToExport", historyToExport);
        setCsvData(historyToExport);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };


  return (
    <>
      <Helmet title={SUBSCRIPTION_SALES.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{SUBSCRIPTION_SALES.CURRENT_MODULE}</h1>
          {/* <div className="section-header-button">
                        <Link to={Path.subscription_history} className="btn btn-primary">Subscription History</Link>
                    </div> */}
          <div className="section-header-breadcrumb">
           
            <div className="breadcrumb-item">Subscription Sales</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">
            {SUBSCRIPTION_SALES.CURRENT_MODULE}
          </h2>
          <p className="section-lead">
            You can see subscription sales listing here.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{SUBSCRIPTION_SALES.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-center gap-10">
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search...."
                          className="form-control"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                      <div>
                        <DateRangePicker
                          start={filters?.dateRange?.startDate}
                          end={filters?.dateRange?.endDate}
                          handleSelect={handleDateRangeChange}
                        />
                      </div>
                      <div>
                        <Button
                          className="d-flex align-items-center justify-content-center commonBtn"
                          onClick={() => {
                            setFilters({})
                          }}>
                          Reset
                        </Button>
                      </div>
                    </div>
                    <div className="right d-flex align-items-center gap-10">
                      <Button className="d-flex align-items-center justify-content-center commonBtn" onClick={handleExportClick_}>
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 7.87495V14.625C15.1875 14.9233 15.069 15.2095 14.858 15.4204C14.647 15.6314 14.3609 15.75 14.0625 15.75H3.9375C3.63913 15.75 3.35298 15.6314 3.142 15.4204C2.93103 15.2095 2.8125 14.9233 2.8125 14.625V7.87495C2.8125 7.57659 2.93103 7.29044 3.142 7.07946C3.35298 6.86848 3.63913 6.74995 3.9375 6.74995H5.625C5.77418 6.74995 5.91726 6.80922 6.02275 6.91471C6.12824 7.0202 6.1875 7.16327 6.1875 7.31245C6.1875 7.46164 6.12824 7.60471 6.02275 7.7102C5.91726 7.81569 5.77418 7.87495 5.625 7.87495H3.9375V14.625H14.0625V7.87495H12.375C12.2258 7.87495 12.0827 7.81569 11.9773 7.7102C11.8718 7.60471 11.8125 7.46164 11.8125 7.31245C11.8125 7.16327 11.8718 7.0202 11.9773 6.91471C12.0827 6.80922 12.2258 6.74995 12.375 6.74995H14.0625C14.3609 6.74995 14.647 6.86848 14.858 7.07946C15.069 7.29044 15.1875 7.57659 15.1875 7.87495ZM6.58547 4.89792L8.4375 3.04519V9.56245C8.4375 9.71164 8.49676 9.85471 8.60225 9.9602C8.70774 10.0657 8.85082 10.125 9 10.125C9.14918 10.125 9.29226 10.0657 9.39775 9.9602C9.50324 9.85471 9.5625 9.71164 9.5625 9.56245V3.04519L11.4145 4.89792C11.5201 5.00347 11.6632 5.06277 11.8125 5.06277C11.9618 5.06277 12.1049 5.00347 12.2105 4.89792C12.316 4.79238 12.3753 4.64922 12.3753 4.49995C12.3753 4.35069 12.316 4.20753 12.2105 4.10199L9.39797 1.28949C9.34573 1.23719 9.28369 1.1957 9.2154 1.16739C9.14712 1.13908 9.07392 1.12451 9 1.12451C8.92608 1.12451 8.85288 1.13908 8.7846 1.16739C8.71631 1.1957 8.65427 1.23719 8.60203 1.28949L5.78953 4.10199C5.68398 4.20753 5.62469 4.35069 5.62469 4.49995C5.62469 4.64922 5.68398 4.79237 5.78953 4.89792C5.89508 5.00347 6.03823 5.06277 6.1875 5.06277C6.33677 5.06277 6.47992 5.00347 6.58547 4.89792Z"
                              fill="white"
                            />
                          </svg>
                        </span>{" "}
                        Export
                      </Button>
                      {console.log("csvData", csvData)}
                      {csvData && (
                        <CSVLink
                          onClick={() => setCsvData(null)}
                          data={csvData}
                          filename="Subscription_Sales.csv"
                          className="btn btn-primary"
                        >
                          Download
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>
                        <th>Practitioner</th>
                        <th>Package</th>
                        <th>Amount</th>
                        <th>Created at</th>
                      </tr>
                      {resData.length > 0 &&
                        resData.map((item, i) => {
                          return (
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.first_name}</td>
                            <td>{item.package_name}</td>
                            <td>${item.package_price}</td>
                            <td>{DATE_FORMAT(item.created_at)}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  {resData.length>0 &&
                    <ReactPagination 
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionHistory : state.Subscription.subscriptions_history,
    itemsCountPerPage: state.Subscription.itemsCountPerPage_,
    totalItemsCount: state.Subscription.totalItemsCount_,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
