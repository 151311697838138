import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const PasswordUpdatedPopup = ({ showPopup, setShowPopup, updatedPassword }) => {
  const [pwdCopied, setPwdCopied] = useState(false);
  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
      setPwdCopied(null);
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
    setPwdCopied(false);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Password updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>Here is the updated password for the user.</p>
            <div className="d-flex gap-10 align-items-center">
              <span>{updatedPassword}</span>
              <Button
                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                onClick={() => {
                  try {
                    navigator.clipboard.writeText(updatedPassword)
                    setPwdCopied(true);
                    timeoutId = setTimeout(() => {
                      setPwdCopied(false);
                    }, 3000)
                  } catch (err) {
                    console.log("Failed to copy to the clipboard");
                  }
                }}
              >
                {pwdCopied ? "Copied!" : "Copy"}
              </Button>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PasswordUpdatedPopup;
