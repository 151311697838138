import {getData, putData} from 'helpers/apiHelper'
import { MAIN_PATH, FORM_LISTING, FORM_UPDATE, FORM_CLIENT_CATEGORIES  } from './apiPath'
import { getClientCat, getForms } from "store/actions/defaultForms";

//get
export function getFormsList(filter, callback) {
  const { client_category } = filter || {};
  const queryString = new URLSearchParams({ client_category }).toString();
  let url = `${MAIN_PATH}${FORM_LISTING}?${queryString}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getForms(data, callback));
      return data;
    });
}

export function updateFormDetails(payload) {
  let { formId, data } = payload;
  let url = `${MAIN_PATH}${FORM_UPDATE}/${formId}`;
  return putData(url, data);
}

export function getClientCategories(callback) {
  let url = `${MAIN_PATH}${FORM_CLIENT_CATEGORIES}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getClientCat(data, callback));
      return data;
    });
}